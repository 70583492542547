import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColorModeScript } from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import theme from "./common/theme";
import Root from "./config/root";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsxs(_Fragment, { children: [_jsx(ColorModeScript, { initialColorMode: theme.config.initialColorMode }), _jsx(Root, {})] }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
