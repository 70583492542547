import { useQuery } from "@apollo/client";
import { CurrentUserDocument } from "@packages/apollo-hooks";
const useIsAdmin = () => {
    const { data } = useQuery(CurrentUserDocument, {
        fetchPolicy: "cache-first",
    });
    const isAdmin = (data === null || data === void 0 ? void 0 : data.currentUser.role) === "ADMIN";
    return isAdmin;
};
export default useIsAdmin;
