import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Image, Td, Tr } from "@chakra-ui/react";
import React from "react";
import campaignsImage from "./images/campaigns.png";
import contactsImage from "./images/contacts.png";
import messagesImage from "./images/messages.png";
import reportsImage from "./images/reports.png";
import templatesImage from "./images/templates.png";
import usersImage from "./images/users.png";
import responsesImage from "./images/responses.png";
import theme from "../../../../../common/theme";
const EmptyState = ({ type, columnCount, isTable = true, hasError = false, fullHeight = false, emptyStateText = null, }) => {
    const renderImageSrc = () => {
        let image = "";
        switch (type) {
            case "google-users":
            case "users":
                image = usersImage;
                break;
            case "reports":
                image = reportsImage;
                break;
            case "campaigns":
                image = campaignsImage;
                break;
            case "messages":
                image = messagesImage;
                break;
            case "templates":
                image = templatesImage;
                break;
            case "responses":
                image = responsesImage;
                break;
            case "contacts":
            default:
                image = contactsImage;
                break;
        }
        return image;
    };
    return (_jsx(_Fragment, { children: isTable ? (_jsx(Tr, { children: _jsx(Td, Object.assign({ bg: emptyStateText ? "transparent" : "gray.100", height: emptyStateText ? "unset" : "400px", colSpan: columnCount ? columnCount : 3 }, { children: emptyStateText ? (_jsx(Flex, Object.assign({ padding: "2", justifyContent: "center", color: "gray.400" }, { children: emptyStateText }))) : (_jsx(Image, { height: "200px", mx: "auto", src: renderImageSrc() })) })) })) : (_jsx(Box, Object.assign({ display: "flex", alignItems: "center", bg: "gray.100", width: "100%", height: fullHeight ? "100%" : "unset", py: "3", borderRadius: "lg", border: hasError ? `1px solid ${theme.colors.red[500]}` : "none" }, { children: _jsx(Image, { height: "126px", mx: "auto", src: renderImageSrc() }) }))) }));
};
export default EmptyState;
