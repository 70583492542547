var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { addYears, eachYearOfInterval, endOfDecade, endOfYear, format, getYear, isAfter, isBefore, isSameYear, startOfDecade, startOfYear, subYears, } from "date-fns";
import React, { useCallback, useMemo, } from "react";
import { Year } from "./years.component";
const Years = ({ todayDate, displayDate, dispatch, }) => {
    const currentCalendarMonthsInfo = useMemo(() => {
        return eachYearOfInterval({
            start: subYears(startOfDecade(displayDate), 1),
            end: addYears(endOfDecade(displayDate), 1),
        }).map(yearStartDate => ({
            value: yearStartDate,
            label: format(yearStartDate, "y"),
            isPastYear: isBefore(yearStartDate, startOfYear(todayDate)),
            isCurrentYear: isSameYear(yearStartDate, todayDate),
            isFutureYear: isAfter(yearStartDate, endOfYear(todayDate)),
        }));
    }, [displayDate, todayDate]);
    const onYearClick = useCallback((value) => () => {
        dispatch({ type: "setDisplayYear", year: getYear(value) });
    }, [dispatch]);
    const years = useMemo(() => {
        return currentCalendarMonthsInfo.map((_a) => {
            var { value, label } = _a, yearInfo = __rest(_a, ["value", "label"]);
            return (_jsx(Year, Object.assign({ onClick: onYearClick(value), yearInfo: yearInfo, "data-cy-value": format(value, "y") }, { children: label }), value.toISOString()));
        });
    }, [currentCalendarMonthsInfo, onYearClick]);
    return _jsx(_Fragment, { children: years });
};
export default Years;
