import { IntegrationProviders } from "@packages/apollo-hooks";
const getProviderName = (integrationProvider) => {
    switch (integrationProvider) {
        case IntegrationProviders.Google:
            return "Gmail";
        case IntegrationProviders.Microsoft:
            return "Outlook";
        case IntegrationProviders.Ses:
            return "Amazon Web Services";
        default:
            return "";
    }
};
export default getProviderName;
