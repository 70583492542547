import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import authLink from "./auth-flow-link";
// const wsLink = new WebSocketLink({
//   uri: `${process.env.REACT_APP_WS_URL}`,
//   options: {
//     reconnect: true,
//     connectionParams: () => {
//       const userToken: UserToken | null = getUserToken();
//       return {
//         authorization: `Bearer ${userToken?.token}`,
//       };
//     },
//   },
// });
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
});
const link = authLink.concat(httpLink);
// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   // wsLink,
//   link,
// );
const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
});
export default client;
