import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider, DrawerBody, DrawerFooter, Stack, } from "@chakra-ui/react";
import { Form } from "formik";
import InputField from "../../../shared/components/form/input-field";
import SwitchField from "../../../shared/components/form/switch-field";
const EditUserFormContent = ({ isSubmitting, isValid, submitCount, setFieldValue, values, isAdmin, onClose, }) => {
    return (_jsxs(Form, { children: [_jsxs(DrawerBody, Object.assign({ maxH: "calc(100vh - 167px)" }, { children: [_jsxs(Stack, Object.assign({ direction: "row", width: "full", spacing: "4" }, { children: [_jsx(InputField, { label: "First Name", type: "text", name: "firstName", placeholder: "Please enter first name", autoFocus: true }), _jsx(InputField, { label: "Last Name", type: "text", name: "lastName", placeholder: "Please enter last name" })] })), !isAdmin && (_jsx(SwitchField, { name: "hasDirectPayment", label: "Professional Plan (Non-Stripe)", onChange: () => {
                            if (!values.hasDirectPayment) {
                                setFieldValue("hasDirectPayment", new Date());
                            }
                            setFieldValue("hasDirectPayment", !values.hasDirectPayment);
                        } }))] })), _jsxs(Box, Object.assign({ position: "absolute", bottom: "0", width: "100%" }, { children: [_jsx(Divider, {}), _jsxs(DrawerFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", mr: 3, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "submit", isLoading: isSubmitting, disabled: submitCount > 0 && !isValid, colorScheme: "green" }, { children: "Save" }))] })] }))] }));
};
export default EditUserFormContent;
