import * as Yup from "yup";
import { passwordRegex } from "../../../../common/helpers/yup";
export const passwordInitialValues = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
};
export const passwordValidationSchema = Yup.object().shape({
    currentPassword: Yup.string(),
    password: passwordRegex
        .min(8)
        .max(128)
        .matches(/^(?=.*[A-Z]).*$/, {
        message: "Should include at least one uppercase letter.",
    })
        .matches(/^(?=.*[\d]).*$/, {
        message: "Should include at least one number.",
    })
        .matches(/^(?=.*[^\p{L}\d\s]).*$/u, {
        message: "Should include at least one symbol.",
    })
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Passwords must match"),
});
