import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from "@chakra-ui/react";
import { IoPaperPlane, IoPeople } from "react-icons/io5";
import PageContent from "../shared/components/page/page-content";
import PageHeader from "../shared/components/page/page-header";
import PageTitle from "../shared/components/page/page-header/page-title";
import CustomerMessagesTab from "./customer-messages-tab";
import UsersTab from "./users-tab";
const AdminPanel = () => {
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { children: _jsx(PageTitle, { title: "Admin Panel" }) }), _jsx(PageContent, { children: _jsxs(Tabs, Object.assign({ variant: "line", mt: "8", colorScheme: "cyan" }, { children: [_jsxs(TabList, { children: [_jsx(Tab, Object.assign({ w: "50%" }, { children: _jsxs(HStack, Object.assign({ spacing: "2" }, { children: [_jsx(Icon, { as: IoPeople, fontSize: "2xl" }), " ", _jsx(Text, Object.assign({ as: "span" }, { children: " Users" }))] })) })), _jsx(Tab, Object.assign({ w: "50%" }, { children: _jsxs(HStack, Object.assign({ spacing: "2" }, { children: [_jsx(Icon, { as: IoPaperPlane, fontSize: "2xl" }), " ", _jsx(Text, Object.assign({ as: "span" }, { children: " Customer Messages" }))] })) }))] }), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(UsersTab, {}) }), _jsx(TabPanel, { children: _jsx(CustomerMessagesTab, {}) })] })] })) })] }));
};
export default AdminPanel;
