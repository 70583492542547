var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/toast";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { ResetPasswordDocument } from "@packages/apollo-hooks";
import { resetPasswordInitialValues, resetPasswordValidationSchema, } from "./form";
import ResetPasswordFormContent from "./reset-password-form-content";
const ResetPasswordForm = () => {
    const toast = useToast();
    const [requestResetPassword] = useMutation(ResetPasswordDocument);
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield requestResetPassword({
                variables: {
                    input: { email: values.email },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.resetPassword.__typename) {
                case "ResetPasswordResponse":
                    toast({
                        title: "Check your inbox!",
                        description: "We have sent you a link to change your password.",
                        status: "success",
                        isClosable: false,
                    });
                    return;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            console.error(e);
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [requestResetPassword, toast]);
    return (_jsx(Formik, Object.assign({ initialValues: resetPasswordInitialValues, validationSchema: resetPasswordValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(ResetPasswordFormContent, Object.assign({}, formProps)) })));
};
export default ResetPasswordForm;
