import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Stack, } from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import React, { useState } from "react";
import { IoMailOutline, IoPencilOutline } from "react-icons/io5";
import EmailCardDetail from "../../../campaigns/edit-campaign/edit-campaign-messages-new/email-details/email-card-detail";
import CustomDrawerCloseButton from "../../../shared/components/custom-drawer-close-button";
import UseTemplateInNewCampaign from "../../use-template-in-new-campaign";
import DeleteTemplate from "../delete-template";
import TemplateEditor from "../template-editor";
const TemplatePreview = ({ template, isOpen, onClose, }) => {
    const [editMode, setEditMode] = useState(false);
    const handleTurnOffEditMode = () => {
        setEditMode(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ cursor: "pointer", borderWidth: "1px", borderColor: "gray.200", borderRadius: "base", h: "82px", w: "82px", overflow: "hidden", className: "data-cy-open-preview" }, { children: _jsx(Box, { w: "200%", h: "200%", transform: "scale(0.5) translateX(-50%) translateY(-50%)", dangerouslySetInnerHTML: {
                        __html: template.body,
                    } }) })), _jsx(Drawer, Object.assign({ isOpen: isOpen, onClose: () => {
                    handleTurnOffEditMode();
                    onClose();
                }, size: "own" }, { children: _jsx(DrawerOverlay, { children: _jsxs(DrawerContent, Object.assign({ className: "data-cy-template-preview", maxWidth: "calc(100vw - 320px)" }, { children: [_jsx(CustomDrawerCloseButton, {}), _jsx(DrawerHeader, { children: template.name }), _jsx(Divider, {}), !editMode ? (_jsxs(_Fragment, { children: [_jsxs(Stack, Object.assign({ spacing: "14", direction: "row", p: "6", justifyContent: "space-between", borderBottomWidth: "1px", borderColor: "gray.200" }, { children: [_jsx(EmailCardDetail, Object.assign({ icon: _jsx(IoMailOutline, {}), title: "Subject" }, { children: template.subject })), _jsxs(Stack, Object.assign({ direction: "row", spacing: "2" }, { children: [_jsx(DeleteTemplate, { templateId: template.id, templateName: template.name }), _jsx(Button, Object.assign({ variant: "outline", leftIcon: _jsx(IoPencilOutline, {}), onClick: () => {
                                                            setEditMode(true);
                                                        } }, { children: "Edit Template" }))] }))] })), _jsx(DrawerBody, { children: _jsx(Editor, { apiKey: process.env.REACT_APP_TINY_KEY, value: template.body, disabled: true, init: {
                                                height: "calc(100vh - 172px)",
                                                plugins: "",
                                                menubar: "",
                                                toolbar: "",
                                            } }) }), _jsxs(DrawerFooter, Object.assign({ borderTopWidth: "1px", borderColor: "gray.200", justifyContent: "space-between" }, { children: [_jsx(Button, Object.assign({ variant: "outline", onClick: onClose }, { children: "Cancel" })), _jsx(UseTemplateInNewCampaign, { templateId: template.id })] }))] })) : (_jsx(TemplateEditor, { onClose: handleTurnOffEditMode, template: template }))] })) }) }))] }));
};
export default TemplatePreview;
