var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import SaveTemplateModal from "../save-template-modal";
import { templateCreatorFirstStepInitialValues, templateCreatorFirstStepValidationSchema, } from "./form";
import TemplateCreatorFormContent from "./template-creator-form-content";
const TemplateCreatorForm = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("<p></p>");
    const toast = useToast();
    const cancelRef = useRef();
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setSubject(values.subject);
            setContent(values.content);
            setIsOpen(true);
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [toast]);
    return (_jsxs(_Fragment, { children: [_jsx(Formik, Object.assign({ initialValues: templateCreatorFirstStepInitialValues, validationSchema: templateCreatorFirstStepValidationSchema, onSubmit: handleSubmit }, { children: formProps => (_jsx(TemplateCreatorFormContent, Object.assign({ onClose: onClose }, formProps))) })), _jsx(SaveTemplateModal, { isOpen: isOpen, cancelRef: cancelRef, onClose: () => setIsOpen(false), onDrawerClose: onClose, subject: subject, content: content })] }));
};
export default TemplateCreatorForm;
