import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import { GetCampaignDetailsDocument, } from "@packages/apollo-hooks";
import { DATE_TIME_FORMAT } from "@packages/helpers";
import { useParams } from "react-router-dom";
import { DateTimePickerField } from "../../../shared/components/form/date-time-picker-new/date-time-picker";
import InputField from "../../../shared/components/form/input-field";
import SelectField from "../../../shared/components/form/select-field";
import useIntegrationsOptions from "../../helpers/use-integrations-options";
const EditCampaignDetails = () => {
    var _a;
    const { integrationsOptions, loading } = useIntegrationsOptions();
    const { id } = useParams();
    const { data } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId: id,
        },
    });
    const hasSentEmail = (_a = data === null || data === void 0 ? void 0 : data.getCampaignDetails) === null || _a === void 0 ? void 0 : _a.campaignEmails.some(email => email.isSent);
    return (_jsxs(Box, Object.assign({ rounded: { lg: "lg" }, bg: "white", borderWidth: "1px" }, { children: [_jsx(Box, Object.assign({ px: "6", py: "2" }, { children: _jsx(Text, Object.assign({ as: "h3", fontWeight: "bold", fontSize: "sm" }, { children: "Campaign details" })) })), _jsx(Divider, {}), _jsx(Flex, Object.assign({ justifyContent: "space-between", flexWrap: "wrap" }, { children: _jsxs(Stack, Object.assign({ spacing: "2", direction: "row", py: "5", px: "8", width: "100%", justifyContent: "space-between" }, { children: [_jsx(InputField, { label: "Campaign Name", hasPadding: false, placeholder: "Enter campaign name", name: "name", type: "text" }), !loading && (_jsx(SelectField, { label: "Provider", hasPadding: false, name: "provider", options: integrationsOptions, isDisabled: hasSentEmail })), _jsx(DateTimePickerField, { label: "End date", hasPadding: false, width: "240px", formatString: DATE_TIME_FORMAT, placeholder: "Select date", name: "endsAt", minDate: new Date(), timePickerDirection: "left" })] })) }))] })));
};
export default EditCampaignDetails;
