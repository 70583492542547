import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { VStack } from "@chakra-ui/react";
import { GetTemplatesDocument, } from "@packages/apollo-hooks";
import React from "react";
import Loader from "../../../shared/components/loader";
import NoTemplates from "../../no-templates";
import TemplateCard from "../template-card";
import { FOLDER_NAMES } from "../templates-browser";
const TemplatesList = ({ queryVariables, appFolder, }) => {
    const { data: templatesData, loading: templatesLoading, error: templatesError, } = useQuery(GetTemplatesDocument, {
        variables: queryVariables,
        fetchPolicy: "network-only",
        // to set loading to true while refetch
        notifyOnNetworkStatusChange: true,
    });
    if (templatesLoading)
        return _jsx(Loader, {});
    if (templatesError)
        return _jsxs("div", { children: ["Error: ", templatesError.message] });
    return (_jsx(VStack, Object.assign({ spacing: "2", height: "90%", overflowY: "scroll" }, { children: templatesData && templatesData.getTemplates.items.length > 0 ? (templatesData.getTemplates.items.map(template => {
            return (_jsx(TemplateCard, { template: template, showFolderBadge: FOLDER_NAMES.includes(appFolder) }, template.id));
        })) : (_jsx(NoTemplates, {})) })));
};
export default TemplatesList;
