import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import AppEventTarget from "../../common/events/app-event-target";
import LogoutRequestEvent from "../../common/events/logout-request-event";
const Logout = () => {
    useEffect(() => {
        AppEventTarget.dispatchEvent(new LogoutRequestEvent({
            showToast: true,
        }));
    }, []);
    return _jsx(_Fragment, { children: "Logging out..." });
};
export default Logout;
