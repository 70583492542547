import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import EmptyState from "../../shared/components/table/empty-state";
import ShowMessage from "./message-preview";
const CustomerMessagesTable = ({ tableData }) => {
    var _a;
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Created",
            accessor: original => format(new Date(original.createdAt), DATE_FORMAT),
        },
        {
            Header: "Message",
            accessor: "message",
        },
    ], []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: (_a = tableData.getLandingMessages.items) !== null && _a !== void 0 ? _a : [] }, useSortBy);
    return (_jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }),
                    headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex }), column.render("Header")))),
                    _jsx(Th, {})))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }),
                            row.cells.slice(0, 3).map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }), cell.render("Cell")))),
                            _createElement(Td, Object.assign({}, row.cells[3].getCellProps(), { key: 3, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "300px" }), row.cells[3].render("Cell")),
                            _jsx(Td, Object.assign({ textAlign: "right" }, { children: _jsx(ShowMessage, { name: row.values["name"], createdAt: row.values["Created"], email: row.values["email"], message: row.values["message"] }) }))));
                    }), rows.length === 0 && _jsx(EmptyState, { type: "messages", columnCount: 5 })] }))] })));
};
export default CustomerMessagesTable;
