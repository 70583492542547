var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, Icon, Text, Box } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
const NavLink = (props) => {
    const { icon, isActive, label } = props, rest = __rest(props, ["icon", "isActive", "label"]);
    return (_jsx(_Fragment, { children: props.href ? (_jsx(Link, Object.assign({ to: props.href ? props.href : "" }, { children: _jsx(Box, Object.assign({ className: "outside-click", display: "block", py: "2", px: "6", transition: "all 0.3s", fontWeight: "normal", fontSize: "md", userSelect: "none", "aria-current": isActive ? "page" : undefined, color: "gray.500", _hover: {
                    bg: "cyan.50",
                }, _activeLink: {
                    color: "gray.800",
                    fontWeight: "bold",
                    borderRightWidth: "4px",
                    borderRightColor: "cyan.400",
                } }, { children: _jsxs(HStack, Object.assign({ spacing: "4", className: "outside-click" }, { children: [_jsx(Icon, { as: icon, fontSize: "2xl", color: isActive ? "cyan.400" : "gray.400" }), _jsx(Text, Object.assign({ as: "span" }, { children: label }))] })) })) }))) : (_jsx(Box, Object.assign({ className: "outside-click", display: "block", py: "2", px: "6", transition: "all 0.3s", fontWeight: "normal", fontSize: "md", userSelect: "none", "aria-current": isActive ? "page" : undefined, color: "gray.500", _hover: {
                bg: "cyan.50",
            }, _activeLink: {
                color: "gray.800",
                fontWeight: "bold",
                borderRightWidth: "4px",
                borderRightColor: "cyan.400",
            }, onClick: rest.onClick ? rest.onClick : console.log }, { children: _jsxs(HStack, Object.assign({ spacing: "4", className: "outside-click" }, { children: [_jsx(Icon, { as: icon, fontSize: "2xl", color: isActive ? "cyan.400" : "gray.400" }), _jsx(Text, Object.assign({ as: "span" }, { children: label }))] })) }))) }));
};
export default NavLink;
