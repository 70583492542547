const checkbox = {
    parts: ["container", "control", "label", "icon"],
    baseStyle: {
        control: {
            border: "1.5px solid",
            borderColor: "gray.300",
            _focus: {
                boxShadow: "none !important",
            },
        },
    },
    variants: {
        dark: {
            control: {
                borderColor: "gray.500",
            },
        },
    },
    defaultProps: {
        colorScheme: "green",
    },
};
export default checkbox;
