import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from "react";
export const RsiContext = createContext({});
export const rootId = "chakra-modal-rsi";
export const Providers = ({ children, rsiValues, }) => {
    if (!rsiValues.fields) {
        throw new Error("Fields must be provided to react-spreadsheet-import");
    }
    return (_jsx(RsiContext.Provider, Object.assign({ value: rsiValues }, { children: children })));
};
