import * as Yup from "yup";
import { passwordRegex } from "../../../common/helpers/yup";
export const registerInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
};
export const registerValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string().email().required("Email is required."),
    password: passwordRegex
        .min(8)
        .max(128)
        .matches(/^(?=.*[A-Z]).*$/, {
        message: "Should include at least one uppercase letter.",
    })
        .matches(/^(?=.*[\d]).*$/, {
        message: "Should include at least one number.",
    })
        .matches(/^(?=.*[^\p{L}\d\s]).*$/u, {
        message: "Should include at least one symbol.",
    })
        .required("Password is required"),
});
