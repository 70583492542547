var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Box, Button, Divider, Flex, Stack, Text, useToast, } from "@chakra-ui/react";
import { DATE_FORMAT, TIME_FORMAT } from "@packages/helpers";
import format from "date-fns/format";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { IoCalendarOutline, IoDownloadOutline, IoMailOutline, IoTimeOutline, } from "react-icons/io5";
import { getUserToken } from "../../../../../../common/helpers/user-token";
import DetailWithIcon from "../../../../../shared/components/detail-with-icon";
import EmailPreview from "../../../../../shared/components/email-preview";
import EmailSentIcon from "../../../../campaign-badge-new/icons/email-sent-icon";
import EmailStatistics from "./email-statistics";
const EmailDetailsView = ({ email }) => {
    const toast = useToast();
    const token = getUserToken();
    const triggerDownload = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const params = new URLSearchParams();
            params.append("offset", new Date().getTimezoneOffset().toString());
            const response = yield fetch(`${process.env.REACT_APP_API_URL}/campaign/email-report/${email.id}?${params.toString()}`, {
                method: "GET",
                headers: new Headers({
                    Authorization: `Bearer ${token === null || token === void 0 ? void 0 : token.token}`,
                }),
            });
            if (response.status === 200) {
                FileSaver.saveAs(yield response.blob(), `${email.subject}_${format(new Date(email.sendsAt), DATE_FORMAT)}.xlsx`);
            }
        }
        catch (err) {
            console.error(err);
            toast({
                title: "Something went wrong",
                description: err.message,
                status: "error",
                isClosable: false,
            });
        }
    }), [email, toast, token === null || token === void 0 ? void 0 : token.token]);
    return (_jsxs(Box, Object.assign({ rounded: { lg: "lg" }, bg: "white", borderWidth: "1px" }, { children: [_jsxs(Flex, Object.assign({ px: "6", py: "2", justifyContent: "space-between" }, { children: [_jsx(Text, Object.assign({ as: "h3", fontWeight: "bold", fontSize: "sm" }, { children: "E-mail details" })), email.isSent && (_jsxs(Badge, Object.assign({ backgroundColor: "green.100", color: "green.900", fontWeight: "bold" }, { children: [_jsx(EmailSentIcon, { width: "16px", height: "16px" }), "E-mail sent"] })))] })), _jsx(Divider, {}), _jsxs(Stack, Object.assign({ direction: "row", px: "8", py: "5", justifyContent: "space-between", spacing: "20", alignItems: "center" }, { children: [_jsx(Stack, Object.assign({ direction: "column", spacing: "8", width: "100%" }, { children: _jsxs(Stack, Object.assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoMailOutline, {}), subtitle: "Subject" }, { children: email.subject })), _jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoCalendarOutline, {}), subtitle: "Date" }, { children: email.sendsAt
                                        ? format(new Date(email.sendsAt), DATE_FORMAT)
                                        : "" })), _jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoTimeOutline, {}), subtitle: "Time" }, { children: email.sendsAt
                                        ? format(new Date(email.sendsAt), TIME_FORMAT)
                                        : "x" })), email.isSent && (_jsx(Button, Object.assign({ variant: "outline", leftIcon: _jsx(IoDownloadOutline, {}), onClick: triggerDownload }, { children: "Download Report" })))] })) })), _jsx(EmailPreview, { email: email })] })), _jsx(EmailStatistics, { id: email.id })] })));
};
export default EmailDetailsView;
