import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, } from "@chakra-ui/react";
import React, { useRef } from "react";
const ChangeCampaignResponseAlert = ({ isOpen, onClose, onSubmit, newStatus, }) => {
    const cancelRef = useRef();
    return (_jsx(_Fragment, { children: _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Change response status" })), _jsxs(AlertDialogBody, { children: ["Are you sure? ", _jsx("br", {}), newStatus
                                    ? `You are trying to change status from
              UNSUBSCRIBED to ${newStatus}`
                                    : `You are trying to clear UNSUBSCRIBED status`, "."] }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", colorScheme: "gray", ref: cancelRef.current, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ colorScheme: "red", variant: "solid", onClick: () => {
                                        onSubmit();
                                        onClose();
                                    }, ml: 3 }, { children: "Change" }))] })] }) }) })) }));
};
export default ChangeCampaignResponseAlert;
