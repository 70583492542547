var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import merge from "lodash/merge";
import { Steps } from "./steps/Steps";
import { Providers } from "./components/Providers";
import { ModalWrapper } from "./components/ModalWrapper";
import { translations } from "./translationsRSIProps";
import "react-data-grid/lib/styles.css";
export const defaultRSIProps = {
    autoMapHeaders: true,
    allowInvalidSubmit: true,
    autoMapDistance: 2,
    translations: translations,
    uploadStepHook: (value) => __awaiter(void 0, void 0, void 0, function* () { return value; }),
    matchColumnsStepHook: (table) => __awaiter(void 0, void 0, void 0, function* () { return table; }),
    dateFormat: "yyyy-mm-dd",
    parseRaw: true,
    contactListId: "",
};
export const SpreadSheetImport = (props) => {
    const mergedTranslations = props.translations !== translations
        ? merge(translations, props.translations)
        : translations;
    return (_jsx(Providers, Object.assign({ rsiValues: Object.assign(Object.assign({}, props), { translations: mergedTranslations }) }, { children: _jsx(ModalWrapper, Object.assign({ isOpen: props.isOpen, onClose: props.onClose }, { children: _jsx(Steps, {}) })) })));
};
SpreadSheetImport.defaultProps = defaultRSIProps;
