import * as Yup from "yup";
export const addContactInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    company: "",
    country: "",
};
export const editContactInitialValues = (contact) => {
    console.log(contact);
    return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        jobTitle: contact.jobTitle ? contact.jobTitle : "",
        company: contact.leadCompany ? contact.leadCompany : "",
        country: contact.country ? contact.country : "",
    };
};
export const addContactValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    jobTitle: Yup.string().required("Job title is required"),
    company: Yup.string().required("Company is required"),
    country: Yup.string().required("Country is required"),
});
