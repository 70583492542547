var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, MenuItem, useToast, } from "@chakra-ui/react";
import { DeleteContactDocument } from "@packages/apollo-hooks";
import { useCallback, useRef, useState } from "react";
import { IoTrash, IoTrashOutline } from "react-icons/io5";
const DeleteContactDialog = ({ contactIds, name, setPageNumber, iconButton = false, afterDeleteAction, contactListId, }) => {
    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();
    const [deleteContact] = useMutation(DeleteContactDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getContacts", "getContactLists"],
    });
    const handleDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield deleteContact({
                variables: {
                    input: { contactIds, contactListId },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.deleteContact.__typename) {
                case "ContactSuccessfulDeleteResponse":
                    setPageNumber(0);
                    if (afterDeleteAction) {
                        afterDeleteAction();
                    }
                    onClose();
                    return;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [
        afterDeleteAction,
        contactIds,
        deleteContact,
        setPageNumber,
        toast,
        contactListId,
    ]);
    return (_jsxs(_Fragment, { children: [iconButton ? (_jsx(IconButton, { onClick: () => setIsOpen(true), size: "sm", fontSize: "md", variant: "outline_color", "aria-label": "Delete", icon: _jsx(IoTrashOutline, {}), mr: "2" })) : (_jsxs(MenuItem, Object.assign({ icon: _jsx(IoTrash, {}), onClick: () => setIsOpen(true) }, { children: ["Delete ", contactIds.length > 1 && "many"] }))), _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Delete Contact" })), _jsx(AlertDialogBody, { children: name
                                    ? `Do you want to delete ${name} Contact?`
                                    : `Do you want to delete all selected contacts?` }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", colorScheme: "gray", ref: cancelRef.current, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ colorScheme: "red", variant: "solid", onClick: handleDelete, ml: 3 }, { children: "Delete" }))] })] }) }) }))] }));
};
export default DeleteContactDialog;
