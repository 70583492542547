import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { GetCampaignsDocument } from "@packages/apollo-hooks";
import { useState } from "react";
import useIsAdmin from "../../common/helpers/use-is-admin";
import Loader from "../shared/components/loader";
import PageContent from "../shared/components/page/page-content";
import PageHeader from "../shared/components/page/page-header";
import Pagination from "../shared/components/table/pagination";
import { DEFAULT_INITIAL_PAGE_SIZE, DEFAULT_PAGE_INDEX, } from "../shared/components/table/pagination/pagination";
import CampaignsButtons from "./campaigns-buttons/campaigns-buttons";
import CampaignsFilterPanel from "./campaigns-filter-panel";
import CampaignsTable from "./campaigns-table";
const Campaigns = () => {
    const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_INDEX);
    const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE);
    const [searchTerm, setSearchTerm] = useState("");
    const [companyOrEmail, setCompanyOrEmail] = useState("");
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const isAdmin = useIsAdmin();
    const { data, loading, error } = useQuery(GetCampaignsDocument, {
        variables: {
            pageNumber,
            pageSize,
            searchPhrase: searchTerm,
            companyOrEmail,
            from: fromDate === null || fromDate === void 0 ? void 0 : fromDate.toDateString(),
            to: toDate === null || toDate === void 0 ? void 0 : toDate.toDateString(),
        },
        fetchPolicy: "network-only",
        // to set loading to true while refetch
        notifyOnNetworkStatusChange: true,
    });
    const handlePrevPage = () => {
        setPageNumber(pageNumber - 1);
    };
    const handleNextPage = () => {
        setPageNumber(pageNumber + 1);
    };
    const handleChangeSearch = (value) => {
        if (value !== searchTerm) {
            setSearchTerm(value);
            setPageNumber(DEFAULT_PAGE_INDEX);
        }
    };
    const handleChangeCompanyOrEmail = (value) => {
        if (value !== companyOrEmail) {
            setCompanyOrEmail(value);
            setPageNumber(DEFAULT_PAGE_INDEX);
        }
    };
    const handleFromDateChange = (value) => {
        if (value !== fromDate) {
            setFromDate(value);
            setPageNumber(DEFAULT_PAGE_INDEX);
        }
    };
    const handleToDateChange = (value) => {
        if (value !== toDate) {
            setToDate(value);
            setPageNumber(DEFAULT_PAGE_INDEX);
        }
    };
    if (loading)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(PageHeader, Object.assign({ mb: "2.5" }, { children: [_jsx(Heading, Object.assign({ size: "lg", fontSize: "4xl" }, { children: "Campaigns" })), !isAdmin && _jsx(CampaignsButtons, { fromDate: fromDate, toDate: toDate })] })), _jsx(PageContent, { children: _jsx(Box, Object.assign({ maxW: { base: "xl", md: "7xl" }, mx: "auto" }, { children: data && (_jsxs(_Fragment, { children: [_jsx(CampaignsFilterPanel, { searchTerm: searchTerm, companyOrEmail: companyOrEmail, fromDate: fromDate, toDate: toDate, handleChangeSearch: handleChangeSearch, handleChangeCompanyOrEmail: handleChangeCompanyOrEmail, handleFromDateChange: handleFromDateChange, handleToDateChange: handleToDateChange }), data.getCampaigns.items && (_jsx(CampaignsTable, { tableData: data.getCampaigns.items, setPageNumber: setPageNumber })), data.getCampaigns.items.length > 0 && (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Text, Object.assign({ color: "gray.600", fontSize: "sm" }, { children: [data.getCampaigns.totalItems, " campaign", data.getCampaigns.totalItems !== 1 && "s"] })), _jsx(Pagination, { pageIndex: pageNumber, pageCount: data.getCampaigns.pageCount, canPreviousPage: pageNumber > 0, canNextPage: pageNumber < data.getCampaigns.pageCount - 1, gotoPage: setPageNumber, previousPage: handlePrevPage, nextPage: handleNextPage, pageSize: pageSize, onPageSizeChange: setPageSize })] })))] })) })) })] }));
};
export default Campaigns;
