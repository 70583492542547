import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "../../../../../../../../common/theme";
import { WIDTH } from "../time.components";
export const SelectWrapper = styled.div `
  max-width: ${WIDTH}px;
  margin: auto;
`;
export const StyledSelect = styled.select `
  font-size: 14px;
  text-indent: 1px;
  width: calc(100% + 2px);
  border: 0;
  text-align: center;
  appearance: none;
  color: inherit;
  background: transparent;
  padding: 0;
  ${p => p.focused &&
    css `
      color: ${theme.colors.red[500]};
    `};
`;
export const StyledOption = styled.option `
  color: ${theme.colors.blue[900]};
`;
