import { addHours, getHours, subHours } from "date-fns";
export const WEEKDAY = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
};
export const WEEK_START_DAY = WEEKDAY.SUNDAY;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const isAm = (date) => getHours(date) < 12;
export const isPm = (date) => !isAm(date);
export const setAmPm = (date, value) => {
    if (value === "AM" && isPm(date)) {
        return subHours(date, 12);
    }
    else if (value === "PM" && isAm(date)) {
        return addHours(date, 12);
    }
    return date;
};
export const toggleAmPm = (oldDate) => isPm(oldDate) ? subHours(oldDate, 12) : addHours(oldDate, 12);
