var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/hooks";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast, } from "@chakra-ui/react";
import { DisableIntegrationDocument } from "@packages/apollo-hooks";
import { useCallback } from "react";
import getProviderName from "../../../campaigns/helpers/get-provider-name";
const DisableIntegrationModal = ({ type }) => {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [disableIntegration] = useMutation(DisableIntegrationDocument, {
        refetchQueries: ["getIntegrationsStatus"],
    });
    const handleDisableIntegration = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield disableIntegration({
                variables: {
                    type: type.toUpperCase(),
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.disableIntegration.__typename) {
                case "DisableIntegrationOkResponse":
                    toast({
                        title: "Integration disabled successfully",
                        status: "success",
                        isClosable: false,
                    });
                    onClose();
                    break;
                case "DisableIntegrationNotFoundResponse":
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    onClose();
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [disableIntegration, onClose, toast, type]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: onOpen, height: "40px", textTransform: "uppercase", background: "#346EF1", color: "white", fontWeight: "700", boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)", borderRadius: "2px", fontFamily: "'Roboto', sans-serif", _hover: {
                    background: "#346EF1",
                    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)",
                    color: "white",
                }, _focus: {
                    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168) !important",
                } }, { children: "Revoke access" })), _jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, size: "md" }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Disable Integration" }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: _jsxs(Text, { children: ["Are you sure? Your ", getProviderName(type), " account will be disconneted from Motar.me."] }) }), _jsxs(ModalFooter, { children: [_jsx(Button, Object.assign({ colorScheme: "gray", variant: "outline", mr: 3, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ size: "md", variant: "solid", colorScheme: "red", onClick: handleDisableIntegration }, { children: "Disable" }))] })] })] }))] }));
};
export default DisableIntegrationModal;
