import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@chakra-ui/react";
import * as React from "react";
export var ImportStatuses;
(function (ImportStatuses) {
    ImportStatuses["Failed"] = "failed";
    ImportStatuses["Created"] = "created";
    ImportStatuses["Updated"] = "updated";
})(ImportStatuses || (ImportStatuses = {}));
const SummaryBadge = ({ status }) => {
    return (_jsx(Box, Object.assign({ width: "fit-content", fontSize: "xs", textTransform: "uppercase", backgroundColor: status === ImportStatuses.Failed
            ? "#FED7D7"
            : status === ImportStatuses.Updated
                ? "#C6F6D5"
                : "#EDF2F7", px: "3", py: "1", fontWeight: "semibold", color: status === ImportStatuses.Failed
            ? "#E53E3E"
            : status === ImportStatuses.Updated
                ? "#38A169"
                : "#718096", borderRadius: "32px" }, { children: status === ImportStatuses.Failed
            ? "Failed to import"
            : status === ImportStatuses.Updated
                ? "Updated"
                : "Imported" })));
};
export default SummaryBadge;
