import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import routesPaths from "../routing/route-paths";
import Loader from "../shared/components/loader";
const GoogleIntegration = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams) {
            const status = searchParams.get("googleIntegrationSuccessful");
            if (status === "true") {
                navigate(routesPaths.SETTINGS);
                toast({
                    title: `Google account connected successfully`,
                    status: "success",
                    isClosable: true,
                });
            }
            else {
                navigate(routesPaths.SETTINGS);
                toast({
                    title: `Your Google account does not have an active Gmail inbox`,
                    status: "error",
                    isClosable: true,
                });
            }
        }
    }, [navigate, searchParams, toast]);
    return _jsx(Loader, {});
};
export default GoogleIntegration;
