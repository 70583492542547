import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormLabel, IconButton, Menu, MenuButton, Stack, Text, } from "@chakra-ui/react";
import { IoEye } from "react-icons/io5";
import CustomDrawerCloseButton from "../../../shared/components/custom-drawer-close-button";
const MessagePreview = ({ name, createdAt, email, message, }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(Menu, { children: [_jsx(MenuButton, { as: IconButton, size: "sm", "aria-label": "Show message", icon: _jsx(IoEye, {}), variant: "outline", colorScheme: "gray", onClick: onOpen }), _jsxs(Drawer, Object.assign({ isOpen: isOpen, onClose: onClose, size: "xs" }, { children: [_jsx(DrawerOverlay, {}), _jsxs(DrawerContent, Object.assign({ maxWidth: "520px" }, { children: [_jsx(CustomDrawerCloseButton, {}), _jsx(DrawerHeader, { children: "Message Preview" }), _jsx(Divider, {}), _jsx(DrawerBody, Object.assign({ maxH: "calc(100vh - 167px)" }, { children: _jsxs(Stack, Object.assign({ padding: 3, height: "100%" }, { children: [_jsxs(Stack, Object.assign({ direction: "row", width: "full", spacing: "4" }, { children: [_jsxs(Stack, Object.assign({ spacing: "2", width: "full" }, { children: [_jsx(FormLabel, { children: "Name" }), _jsx(Text, Object.assign({ padding: "8px 12px", border: "1px solid #E2E8F0", borderRadius: "4px" }, { children: name }))] })), _jsxs(Stack, Object.assign({ spacing: "2", width: "full" }, { children: [_jsx(FormLabel, { children: "Created" }), _jsx(Text, Object.assign({ padding: "8px 12px", border: "1px solid #E2E8F0", borderRadius: "4px" }, { children: createdAt }))] }))] })), _jsxs(Stack, Object.assign({ spacing: "2", width: "full" }, { children: [_jsx(FormLabel, { children: "Email" }), _jsx(Text, Object.assign({ padding: "8px 12px", border: "1px solid #E2E8F0", borderRadius: "4px" }, { children: email }))] })), _jsxs(Stack, Object.assign({ spacing: "2", width: "full" }, { children: [_jsx(FormLabel, { children: "Message" }), _jsx(Text, Object.assign({ padding: "8px 12px", border: "1px solid #E2E8F0", borderRadius: "4px" }, { children: message }))] }))] })) })), _jsxs(Box, Object.assign({ position: "absolute", bottom: "0", width: "100%" }, { children: [_jsx(Divider, {}), _jsx(DrawerFooter, { children: _jsx(Button, Object.assign({ type: "button", onClick: onClose, colorScheme: "green" }, { children: "Close" })) })] }))] }))] }))] }));
};
export default MessagePreview;
