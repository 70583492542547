var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { SignupDocument } from "@packages/apollo-hooks";
import { Formik } from "formik";
import { useCallback } from "react";
import { registerInitialValues, registerValidationSchema, } from "./form";
import RegisterFormContent from "./register-form-content";
const RegisterForm = ({ setFormSubmitted }) => {
    const toast = useToast();
    const [register] = useMutation(SignupDocument);
    const handleSubmit = useCallback((values, { setFieldError }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { email, password, firstName, lastName } = values;
            const { data } = yield register({
                variables: {
                    input: {
                        email,
                        password,
                        firstName,
                        lastName,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.signup.__typename) {
                case "SignupOkResponse":
                    console.log("ok");
                    setFormSubmitted();
                    return;
                case "SignupEmailTakenResponse":
                    setFieldError("email", "Email already taken.");
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    console.error("err");
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: true,
            });
        }
    }), [register, toast, setFormSubmitted]);
    return (_jsx(Formik, Object.assign({ initialValues: registerInitialValues, validationSchema: registerValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(RegisterFormContent, Object.assign({}, formProps)) })));
};
export default RegisterForm;
