import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
const PROFESSIONAL_FEATURES = [
    "Microsoft Integration",
    "Gmail Integration",
    "Unlimited Contacts",
    "Unlimited Campaigns",
    "Email Sequencing",
    "Email Scheduling",
    "Email Templates",
    "Email Reporting",
    "Email Open Tracking",
    "Email Support",
];
const PlanFeatures = ({ oneColumn = false, }) => (_jsxs(Stack, Object.assign({ spacing: "18px" }, { children: [_jsx(Text, Object.assign({ color: "gray.500" }, { children: "By subscribing to the professional plan you will have access to the following features:" })), _jsx(Stack, Object.assign({ spacing: "0", maxH: oneColumn ? "unset" : "140px", flexFlow: "wrap" }, { children: PROFESSIONAL_FEATURES.map(feature => (_jsxs(Stack, Object.assign({ paddingBottom: "2", w: oneColumn ? "100%" : "50%", spacing: "4", direction: "row", alignItems: "center" }, { children: [_jsx(Icon, { as: IoCheckmarkCircle, boxSize: 4, color: "green.500" }), _jsx(Text, Object.assign({ fontSize: "sm", color: "gray.700" }, { children: feature }))] }), feature))) }))] })));
export default PlanFeatures;
