import { jsx as _jsx } from "react/jsx-runtime";
import { format } from "date-fns";
import React, { useCallback, useMemo } from "react";
import SelectAmPm from "./select-am-pm";
const AmPmElements = ({ focusedColumn, setFocusedColumn, dispatch, state: { currentDate }, }) => {
    const onFocusAmPm = useCallback(() => {
        setFocusedColumn("amPm");
    }, [setFocusedColumn]);
    const onBlurAmPm = useCallback(() => {
        setFocusedColumn(null);
    }, [setFocusedColumn]);
    const onUpDownAmPm = useCallback(() => {
        setFocusedColumn("amPm");
        dispatch({ type: "toggleAmPm" });
    }, [dispatch, setFocusedColumn]);
    const onSetAmPm = useCallback((newValue) => {
        dispatch({ type: "setAmPm", value: newValue });
    }, [dispatch]);
    const displayString = useMemo(() => format(currentDate, "a"), [currentDate]);
    const isSelected = focusedColumn === "amPm";
    return (_jsx(SelectAmPm, { isSelected: isSelected, onFocus: onFocusAmPm, onBlur: onBlurAmPm, onUp: onUpDownAmPm, onSet: onSetAmPm, onDown: onUpDownAmPm, value: displayString }));
};
export default AmPmElements;
