import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import SingleContactListTable from "../../../contacts/single-contact-list/single-contact-list-table";
import EmptyState from "../../../shared/components/table/empty-state";
import SelectContactList from "./select-contact-list";
const CampaignContacts = ({ values, errors, setFieldValue, isEditable = true, }) => {
    const handleCancel = () => {
        setFieldValue("contactListId", 0);
    };
    return (_jsxs("div", { children: [_jsxs(Flex, Object.assign({ mt: "8", direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Heading, Object.assign({ size: "lg", fontSize: "2xl" }, { children: "Contacts" })), isEditable && _jsx(SelectContactList, { handleCancel: handleCancel })] })), values.contactListId ? (_jsx(Box, Object.assign({ mt: "4" }, { children: _jsx(SingleContactListTable, { contactListId: values.contactListId, showEditButton: false, showFilterTab: false, enableSelection: false, showActiveCampaignIndicator: true, enableDelete: isEditable }) }))) : (_jsxs(Box, Object.assign({ mt: "4" }, { children: [errors.contactListId && (_jsx(Text, Object.assign({ color: "gray.500", fontSize: "xs", mb: "2" }, { children: errors.contactListId }))), _jsx(EmptyState, { type: "contacts", isTable: false, hasError: errors.contactListId ? true : false })] })))] }));
};
export default CampaignContacts;
