import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, } from "@chakra-ui/react";
import { useRef } from "react";
import { useRsi } from "../../hooks/useRsi";
export const SubmitDataAlert = ({ isOpen, onClose, onConfirm }) => {
    const { allowInvalidSubmit, translations } = useRsi();
    const cancelRef = useRef(null);
    return (_jsx(AlertDialog, Object.assign({ isOpen: isOpen, onClose: onClose, leastDestructiveRef: cancelRef, isCentered: true, id: "rsi" }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: translations.alerts.submitIncomplete.headerTitle })), _jsx(AlertDialogBody, { children: allowInvalidSubmit
                            ? translations.alerts.submitIncomplete.bodyText
                            : translations.alerts.submitIncomplete.bodyTextSubmitForbidden }), _jsx(AlertDialogFooter, { children: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ ref: cancelRef, onClick: onClose, variant: "secondary" }, { children: translations.alerts.submitIncomplete.cancelButtonTitle })), allowInvalidSubmit && (_jsx(Button, Object.assign({ onClick: onConfirm, ml: 3 }, { children: translations.alerts.submitIncomplete.finishButtonTitle })))] }) })] }) }) })));
};
