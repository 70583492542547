import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { DATE_FORMAT } from "@packages/helpers";
import { IoInformationCircle, IoStatsChart } from "react-icons/io5";
import DatePicker from "../../shared/components/form/date-time-picker-new/date-picker/date-picker";
import Search from "../../shared/components/table/table-filter-panel/search";
const CampaignsFilterPanel = ({ searchTerm, companyOrEmail, fromDate, toDate, handleChangeSearch, handleChangeCompanyOrEmail, handleFromDateChange, handleToDateChange, }) => {
    return (_jsxs(HStack, Object.assign({ justify: "space-between", align: "flex-end" }, { children: [_jsxs(Flex, Object.assign({ gap: "2" }, { children: [_jsx(Search, { term: searchTerm, handleChangeSearch: handleChangeSearch, placeholder: "Search by name or email", label: "Search for Campaigns" }), _jsx(Search, { term: companyOrEmail, handleChangeSearch: handleChangeCompanyOrEmail, placeholder: "Search by name or email", label: "Search for Recipients" })] })), _jsxs(HStack, Object.assign({ alignItems: "end" }, { children: [_jsxs(VStack, Object.assign({ alignItems: "start", spacing: "2" }, { children: [_jsxs(VStack, Object.assign({ alignItems: "start", spacing: "0" }, { children: [_jsxs(HStack, Object.assign({ color: "gray.500", fontWeight: "bold", spacing: "1" }, { children: [_jsx(IoStatsChart, {}), " ", _jsx(Text, { children: "Reports" })] })), _jsxs(HStack, Object.assign({ color: "gray.700", fontWeight: "medium" }, { children: [_jsx(Text, { children: "Select Campaign Date Range" }), _jsx(Tooltip, Object.assign({ hasArrow: true, label: "To generate an Overview Report and Open Report,\nplease provide the dates of the campaigns you want to report on.", bg: "gray.800", color: "white" }, { children: _jsx("span", { children: _jsx(IoInformationCircle, { size: "24px" }) }) }))] }))] })), _jsx(DatePicker, { height: "32px", width: "256px", placeholder: "From", showClear: true, value: fromDate, formatString: DATE_FORMAT, onChange: handleFromDateChange })] })), _jsx(Text, Object.assign({ height: "32px", pt: "2px", color: "gray.500" }, { children: "-" })), _jsx(DatePicker, { height: "32px", width: "256px", placeholder: "To", showClear: true, minDate: fromDate, value: toDate, formatString: DATE_FORMAT, onChange: handleToDateChange })] }))] })));
};
export default CampaignsFilterPanel;
