var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { Button, useToast } from "@chakra-ui/react";
import { AddTemplateFolderDocument, } from "@packages/apollo-hooks";
import React, { useCallback } from "react";
import { IoAddOutline } from "react-icons/io5";
const AddTemplateFolder = () => {
    const toast = useToast();
    const [addTemplateFolder] = useMutation(AddTemplateFolderDocument, {
        refetchQueries: ["getTemplateFolders"],
    });
    const handleAddTemplate = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield addTemplateFolder({
                variables: {
                    input: {
                        name: "New folder 1",
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.addTemplateFolder.__typename) {
                case "AddTemplateFolderSuccessfulResponse":
                    return;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [addTemplateFolder, toast]);
    return (_jsx(Button, Object.assign({ size: "sm", variant: "outline", leftIcon: _jsx(IoAddOutline, {}), onClick: handleAddTemplate }, { children: "New Folder" })));
};
export default AddTemplateFolder;
