import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge } from "@chakra-ui/react";
import { CampaignStatuses } from "@packages/apollo-hooks";
import React from "react";
import CompletedIcon from "./icons/completed-icon";
import DeletedIcon from "./icons/deleted-icon";
import DraftIcon from "./icons/draft-icon";
import EmailSentIcon from "./icons/email-sent-icon";
import PauseIcon from "./icons/pause-icon";
const CampaignBadge = ({ status }) => (_jsxs(_Fragment, { children: [status === CampaignStatuses.Draft && (_jsxs(Badge, Object.assign({ colorScheme: "cyan", color: "cyan.800" }, { children: [_jsx(DraftIcon, {}), status] }))), status === CampaignStatuses.Paused && (_jsxs(Badge, Object.assign({ colorScheme: "red", color: "red.600" }, { children: [_jsx(PauseIcon, {}), status] }))), status === CampaignStatuses.Active && (_jsxs(Badge, Object.assign({ colorScheme: "green", color: "white", backgroundColor: "green.500" }, { children: [_jsx(EmailSentIcon, {}), status] }))), status === CampaignStatuses.Deleted && (_jsxs(Badge, Object.assign({ colorScheme: "gray", color: "gray.500" }, { children: [_jsx(DeletedIcon, {}), status] }))), status === CampaignStatuses.Completed && (_jsxs(Badge, Object.assign({ colorScheme: "green", color: "green.900" }, { children: [_jsx(CompletedIcon, {}), status] })))] }));
export default CampaignBadge;
