export const personalizationOptions = [
    {
        title: "Lead Company",
        description: "",
        content: "{lead_company}",
    },
    {
        title: "E-mail",
        description: "",
        content: "{email}",
    },
    {
        title: "First Name",
        description: "",
        content: "{first_name}",
    },
    {
        title: "Last Name",
        description: "",
        content: "{last_name}",
    },
    {
        title: "Job Title",
        description: "",
        content: "{job_title}",
    },
];
