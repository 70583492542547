import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Image } from "@chakra-ui/react";
import { IntegrationProviders } from "@packages/apollo-hooks";
import getIntegrationName from "../../../campaigns/helpers/get-integration-name";
import { integrationSettings } from "../utils/integration-settings";
const IntegrationButton = ({ type, onClick }) => {
    var _a;
    return (_jsxs(Button, Object.assign({ onClick: onClick, borderRadius: "2px", background: "white", boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)", height: "40px", fontFamily: "'Roboto', sans-serif", fontWeight: "500", color: "#757575", minWidth: "fit-content", px: "8px", _hover: {
            background: "white",
            boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)",
            color: "#757575",
        }, _focus: {
            boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168) !important",
        } }, { children: [_jsx(Image, { boxSize: "18px", src: (_a = integrationSettings.find(setting => setting.type === type)) === null || _a === void 0 ? void 0 : _a.iconSrc, mr: "24px" }), type === IntegrationProviders.Ses ? "Use" : "Sign in", " with", " ", getIntegrationName(type)] })));
};
export default IntegrationButton;
