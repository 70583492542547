import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
const Search = ({ term, handleChangeSearch, placeholder = "Filter by name or email", label, }) => {
    const [searchTerm, setSearchTerm] = useState(term);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleChangeSearch(searchTerm);
        }, 3000);
        return () => clearTimeout(delayDebounceFn);
    }, [handleChangeSearch, searchTerm]);
    return (_jsxs(FormControl, Object.assign({ minW: { md: "260px" }, id: "search" }, { children: [label ? (_jsx(FormLabel, { children: label })) : (_jsx(FormLabel, Object.assign({ srOnly: true }, { children: placeholder }))), _jsxs(InputGroup, Object.assign({ size: "sm" }, { children: [_jsx(InputLeftElement, Object.assign({ pointerEvents: "none", color: "gray.400" }, { children: _jsx(BsSearch, {}) })), _jsx(Input, { autoFocus: true, autoComplete: "off", rounded: "base", type: "search", value: searchTerm, placeholder: placeholder, onChange: e => setSearchTerm(e.target.value) })] }))] })));
};
export default Search;
