import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton, Link, Table, Tbody, Td, Text, Th, Thead, Tr, } from "@chakra-ui/react";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import { useMemo } from "react";
import { IoDownloadOutline } from "react-icons/io5";
import { useSortBy, useTable } from "react-table";
import EmptyState from "../../../shared/components/table/empty-state/empty-state";
const InvoicesTable = ({ tableData }) => {
    const columns = useMemo(() => {
        return [
            {
                Header: "Number",
                accessor: "number",
                Cell: function InvoiceNumber({ row: { original } }) {
                    return _jsx(Text, Object.assign({ fontWeight: "bold" }, { children: original.number }));
                },
            },
            {
                Header: "Date",
                accessor: original => format(new Date(original.date), DATE_FORMAT),
            },
            {
                Header: "Billed",
                accessor: original => original.billedAmount,
            },
            {
                Header: "",
                accessor: "invoiceUrl",
                Cell: function InvoiceDownload({ row: { original } }) {
                    return (_jsx(Box, Object.assign({ textAlign: "right" }, { children: _jsx(Link, Object.assign({ href: original.invoiceUrl, _hover: {
                                textDecor: "none",
                            } }, { children: _jsx(IconButton, { size: "sm", fontSize: "md", variant: "outline", "aria-label": "Download", icon: _jsx(IoDownloadOutline, {}), mr: "2" }) })) })));
                },
            },
        ];
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData.getInvoices.invoices }, useSortBy);
    return (_jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }), headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex }), column.render("Header"))))))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }), row.cells.map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex }), cell.render("Cell"))))));
                    }), rows.length === 0 && (_jsx(EmptyState, { emptyStateText: "No invoices yet.", columnCount: 4 }))] }))] })));
};
export default InvoicesTable;
