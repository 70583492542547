import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid, GridItem, Heading, ModalBody, Stack, Text, } from "@chakra-ui/react";
import { ContinueButton } from "../../../components/ContinueButton";
import { useRsi } from "../../../hooks/useRsi";
export const ColumnGrid = ({ columns, templateColumn, onContinue, isLoading, onGoBack, data, headerValues, }) => {
    const { translations } = useRsi();
    const flatData = data
        //NOTE: Handle empty cells at the end
        .map(item => headerValues.map((_, index) => { var _a; return (_a = item[index]) !== null && _a !== void 0 ? _a : null; }))
        .flat();
    return (_jsxs(_Fragment, { children: [_jsx(ModalBody, Object.assign({ flexDir: "column", p: 8, overflow: "auto", bg: "gray.50" }, { children: _jsxs(Stack, Object.assign({ spacing: "8" }, { children: [_jsx(Heading, { children: translations.matchColumnsStep.title }), _jsx(Text, { children: translations.matchColumnsStep.subtitle }), _jsxs(Stack, Object.assign({ spacing: "0", border: "1px solid #E2E8F0", borderRadius: "8px", overflowX: "scroll", position: "relative" }, { children: [_jsx(Grid, Object.assign({ templateColumns: `repeat(${headerValues.length},${headerValues.length < 6
                                        ? `calc(100% / ${headerValues.length})`
                                        : "200px"})` }, { children: headerValues.map((headerValue, index) => (_jsx(GridItem, Object.assign({ borderBottom: "1px solid #E2E8F0" }, { children: _jsxs(Stack, Object.assign({ direction: "column", spacing: "0" }, { children: [templateColumn(columns[index]), _jsx(Box, Object.assign({ bg: "white", padding: "16px 24px", color: "gray.500", fontSize: "sm" }, { children: headerValue }))] })) }), headerValue))) })), _jsxs(Grid, Object.assign({ height: "172px", overflowY: "auto", width: headerValues.length < 6
                                        ? "100%"
                                        : `calc(${headerValues.length * 200}px - 1px)`, overflowX: "hidden", templateColumns: `repeat(${headerValues.length},${headerValues.length < 6
                                        ? `calc(100% / ${headerValues.length})`
                                        : "200px"})` }, { children: [flatData
                                            .slice(0, flatData.length - headerValues.length)
                                            .map((dataItem, index) => (_jsx(GridItem, Object.assign({ bg: "white", padding: "16px 24px", borderBottom: "1px solid #E2E8F0", fontSize: "sm" }, { children: dataItem }), index))), flatData
                                            .slice(flatData.length - headerValues.length)
                                            .map((dataItem, index) => (_jsx(GridItem, Object.assign({ bg: "white", padding: "16px 24px", fontSize: "sm" }, { children: dataItem }), index)))] }))] }))] })) })), _jsx(ContinueButton, { isLoading: isLoading, onContinue: onContinue, title: translations.matchColumnsStep.nextButtonTitle, onGoBack: onGoBack })] }));
};
