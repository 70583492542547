var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { VerifySesIntegrationDocument, } from "@packages/apollo-hooks";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import routesPaths from "../routing/route-paths";
import Loader from "../shared/components/loader";
const SesIntegration = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [searchParams] = useSearchParams();
    const [verifySesIntegration] = useMutation(VerifySesIntegrationDocument);
    useEffect(() => {
        const verify = () => __awaiter(void 0, void 0, void 0, function* () {
            const status = searchParams.get("sesIntegrationSuccessful");
            if (status === "true") {
                try {
                    const { data } = yield verifySesIntegration();
                    if ((data === null || data === void 0 ? void 0 : data.verifySesIntegration.__typename) ===
                        "VerifySesIntegrationSuccessfulResponse") {
                        navigate(routesPaths.SETTINGS);
                        toast({
                            title: `Amazon Web Services email verified successfully`,
                            status: "success",
                            isClosable: true,
                        });
                        return;
                    }
                }
                catch (_a) { }
            }
            navigate(routesPaths.SETTINGS);
            toast({
                title: `Something went wrong with your Amazon Web Services email verification`,
                status: "error",
                isClosable: true,
            });
        });
        if (searchParams) {
            verify();
        }
    }, [navigate, searchParams, toast, verifySesIntegration]);
    return _jsx(Loader, {});
};
export default SesIntegration;
