import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Stack, TabPanel, Text } from "@chakra-ui/react";
import { CurrentUserDocument } from "@packages/apollo-hooks";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import * as React from "react";
import Banner from "../../../shared/components/banner";
import TextInfo from "../../../shared/components/text-info";
import useUserSubscription from "../../../shared/hooks/use-user-subscription";
import PlanFeatures from "../../../shared/subscription-plan/plan-features";
import SubscriptionButtons from "../../../shared/subscription-plan/subscription-buttons";
import CancelSubscriptionModal from "./cancel-subscription-modal";
const SubscriptionPanel = props => {
    const { remainingDays } = useUserSubscription();
    const { data: userData } = useQuery(CurrentUserDocument);
    const subscription = userData === null || userData === void 0 ? void 0 : userData.currentUser.subscription;
    return (_jsx(TabPanel, Object.assign({}, props, { children: subscription && (_jsxs(Stack, Object.assign({ spacing: "1" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold", mb: "16px" }, { children: "Subscription status" })), subscription.isTrial ? (_jsxs(Stack, Object.assign({ spacing: "12px" }, { children: [_jsxs(Banner, Object.assign({ borderRadius: "base", fontWeight: "medium" }, { children: ["Your trial ends in ", remainingDays, " ", remainingDays > 1 ? "days" : "day", "."] })), _jsxs(Stack, Object.assign({ spacing: "6", direction: { base: "column", xl: "row" } }, { children: [_jsxs(Stack, Object.assign({ bg: "gray.50", spacing: "2", padding: "6", borderRadius: "lg", maxWidth: { base: "unset", xl: "360px" } }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Upgrade to Professional Plan" })), _jsx(PlanFeatures, { oneColumn: true })] })), _jsxs(Stack, Object.assign({ w: "100%" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Billing" })), _jsx(SubscriptionButtons, { isDivided: true })] }))] }))] }))) : (_jsxs(Stack, Object.assign({ spacing: "3" }, { children: [_jsx(TextInfo, { children: subscription.isCanceled
                                ? `Your subscription will end on ${format(new Date(subscription.nextPaymentDate), DATE_FORMAT)}`
                                : `Your subscription will be renewed on ${format(new Date(subscription.nextPaymentDate), DATE_FORMAT)}` }), !subscription.isCanceled && (_jsx(CancelSubscriptionModal, { nextPaymentDate: new Date(subscription.nextPaymentDate) }))] })))] }))) })));
};
export default SubscriptionPanel;
