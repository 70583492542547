import { useQuery } from "@apollo/client";
import { GetAllContactListsDocument } from "@packages/apollo-hooks";
import { useMemo } from "react";
const useContactListsOptions = (isSelect, contactListId, searchValue) => {
    const { data, loading } = useQuery(GetAllContactListsDocument, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        variables: {
            searchPhrase: searchValue ? searchValue : "",
        },
    });
    const contactListsOptions = useMemo(() => {
        let usersArray = data &&
            data.getContactLists &&
            data.getContactLists.items.map(contactList => ({
                label: contactList.name,
                value: contactList.id,
            }));
        if (contactListId && usersArray) {
            usersArray = usersArray.filter(x => x.value !== contactListId);
        }
        if (isSelect) {
            if (usersArray) {
                return [{ label: "Select list", value: "" }, ...usersArray];
            }
            else {
                return [{ label: "Select list", value: "" }];
            }
        }
        else {
            if (usersArray) {
                return [...usersArray];
            }
            else {
                return [];
            }
        }
    }, [contactListId, data, isSelect]);
    return { contactListsOptions, loading };
};
export default useContactListsOptions;
