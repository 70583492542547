var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IoFolderOutline } from "react-icons/io5";
import FolderBadge from "../../../templates-browser/template-card/folder-badge";
const TemplateFolderCard = (_a) => {
    var { templatesCount, name } = _a, props = __rest(_a, ["templatesCount", "name"]);
    return (_jsx(HStack, Object.assign({ p: "2", w: "full", borderRadius: "8px", borderWidth: "1px", borderColor: "gray.200", spacing: "6", cursor: "pointer" }, props, { children: _jsxs(HStack, Object.assign({ width: "100%", justifyContent: "space-between" }, { children: [_jsxs(HStack, { children: [_jsx(Icon, { as: IoFolderOutline, boxSize: "16px" }), _jsx(Text, Object.assign({ fontSize: "sm", color: "gray.700", noOfLines: 1 }, { children: name }))] }), _jsxs(FolderBadge, Object.assign({ showIcon: false }, { children: [templatesCount, " Template", templatesCount !== 1 && "s"] }))] })) })));
};
export default TemplateFolderCard;
