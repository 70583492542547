import { useQuery } from "@apollo/client";
import { GetIntegrationsStatusDocument, IntegrationProviders, } from "@packages/apollo-hooks";
import { useMemo } from "react";
import getProviderName from "./get-provider-name";
const useIntegrationsOptions = () => {
    const { data, loading } = useQuery(GetIntegrationsStatusDocument, {
        fetchPolicy: "no-cache",
    });
    const integrationsOptions = useMemo(() => {
        const googleOption = (data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.googleIntegration.isEnabled) &&
            (data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.googleIntegration.isValid);
        const microsoftOption = (data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.microsoftIntegration.isEnabled) &&
            (data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.microsoftIntegration.isValid);
        const sesOption = (data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.sesIntegration.isEnabled) &&
            (data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.sesIntegration.isValid);
        const array = [];
        if (googleOption) {
            array.push({
                label: getProviderName(IntegrationProviders.Google),
                value: IntegrationProviders.Google,
            });
        }
        if (microsoftOption) {
            array.push({
                label: getProviderName(IntegrationProviders.Microsoft),
                value: IntegrationProviders.Microsoft,
            });
        }
        if (sesOption) {
            array.push({
                label: getProviderName(IntegrationProviders.Ses),
                value: IntegrationProviders.Ses,
            });
        }
        if (array.length > 0) {
            return [{ label: "Select option", value: "" }, ...array];
        }
        else {
            return [{ label: "Select option", value: "" }];
        }
    }, [data]);
    return { integrationsOptions, loading };
};
export default useIntegrationsOptions;
