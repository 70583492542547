import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, Text, Box, } from "@chakra-ui/react";
import { useRef } from "react";
import { useRsi } from "../../hooks/useRsi";
export const UnmatchedFieldsAlert = ({ isOpen, onClose, onConfirm, fields, }) => {
    const { allowInvalidSubmit, translations } = useRsi();
    const cancelRef = useRef(null);
    return (_jsx(AlertDialog, Object.assign({ isOpen: isOpen, onClose: onClose, leastDestructiveRef: cancelRef, isCentered: true, id: "rsi" }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: translations.alerts.unmatchedRequiredFields.headerTitle })), _jsxs(AlertDialogBody, { children: [translations.alerts.unmatchedRequiredFields.bodyText, _jsxs(Box, Object.assign({ pt: 3 }, { children: [_jsx(Text, Object.assign({ display: "inline" }, { children: translations.alerts.unmatchedRequiredFields.listTitle })), _jsxs(Text, Object.assign({ display: "inline", fontWeight: "bold" }, { children: [" ", fields.join(", ")] }))] }))] }), _jsx(AlertDialogFooter, { children: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ ref: cancelRef, onClick: onClose, variant: "secondary" }, { children: translations.alerts.unmatchedRequiredFields.cancelButtonTitle })), allowInvalidSubmit && (_jsx(Button, Object.assign({ onClick: onConfirm, ml: 3 }, { children: translations.alerts.unmatchedRequiredFields
                                        .continueButtonTitle })))] }) })] }) }) })));
};
