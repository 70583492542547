import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, Select, } from "@chakra-ui/react";
import { Field } from "formik";
import { forwardRef } from "react";
const SelectField = forwardRef((props, ref) => {
    const { label, name, options, hasPadding = true, isDisabled } = props;
    return (_jsx(Field, Object.assign({ name: name }, { children: ({ field, meta }) => (_jsxs(FormControl, Object.assign({ p: hasPadding ? "3" : "0", position: "relative", isInvalid: !!(meta.touched && meta.error) }, { children: [label && _jsx(FormLabel, { children: label }), _jsx(Select, Object.assign({ ref: ref }, field, { isDisabled: isDisabled }, { children: options.map((o, index) => (_jsx("option", Object.assign({ value: o.value }, { children: o.label }), index))) })), _jsx(FormErrorMessage, { children: meta.touched && meta.error ? meta.error : undefined })] }))) })));
});
SelectField.displayName = "SelectField";
export default SelectField;
