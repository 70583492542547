import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { GetTemplateFoldersDocument, } from "@packages/apollo-hooks";
import React from "react";
import { IoFolderOutline, IoHeartOutline, IoTimeOutline, } from "react-icons/io5";
import { useLocation } from "react-router";
import routesPaths from "../../../routing/route-paths";
import AddTemplateFolder from "../add-template-folder";
import NavItem from "./nav-item";
const TemplatesSidebar = () => {
    var _a;
    const location = useLocation();
    const { data, loading } = useQuery(GetTemplateFoldersDocument, {
        fetchPolicy: "network-only",
        // to set loading to true while refetch
        notifyOnNetworkStatusChange: true,
    });
    return (_jsxs(Flex, Object.assign({ w: "256px", p: "4", backgroundColor: "gray.100", direction: "column", borderLeftRadius: "8px", maxHeight: "100%", overflowY: "scroll" }, { children: [_jsxs(Stack, Object.assign({ spacing: "2", borderBottomColor: "#E2E8F0", borderBottomWidth: "1px", paddingBottom: "4" }, { children: [_jsx(NavItem, { label: "Recent", icon: _jsx(IoTimeOutline, {}), active: location.pathname.includes("recent") ||
                            location.pathname === routesPaths.TEMPLATES, href: routesPaths.TEMPLATES_WITH_MODE.replace(":id", "recent") }), _jsx(NavItem, { label: "Favorites", icon: _jsx(IoHeartOutline, {}), active: location.pathname.includes("favorites"), href: routesPaths.TEMPLATES_WITH_MODE.replace(":id", "favorites") })] })), !loading && data && (_jsxs(Stack, Object.assign({ spacing: "2", paddingTop: "4" }, { children: [_jsx(NavItem, { label: "Folders", icon: _jsx(IoFolderOutline, {}), active: false }), _jsxs(Stack, Object.assign({ spacing: "2", pl: "4" }, { children: [(_a = data.getTemplateFolders.items) === null || _a === void 0 ? void 0 : _a.map(item => {
                                return (_jsx(NavItem, { href: routesPaths.TEMPLATES_WITH_MODE.replace(":id", item.id), label: item.name, icon: _jsx(IoFolderOutline, {}), active: location.pathname ===
                                        routesPaths.TEMPLATES_WITH_MODE.replace(":id", item.id) }, item.id));
                            }), _jsx(Box, { children: _jsx(AddTemplateFolder, {}) })] }))] })))] })));
};
export default TemplatesSidebar;
