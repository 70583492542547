var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { VerifyUserDocument } from "@packages/apollo-hooks";
import routesPaths from "../routing/route-paths";
import Loader from "../shared/components/loader";
import failureImg from "../verify/verify-failure/failure.png";
import successImg from "../verify/verify-success/success.png";
import VerifyWrapper from "../verify/verify-wrapper";
const ActivateUser = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [activateUser] = useMutation(VerifyUserDocument);
    const toast = useToast();
    useEffect(() => {
        function activate() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield activateUser({
                        variables: {
                            input: {
                                token,
                            },
                        },
                    });
                    switch (data === null || data === void 0 ? void 0 : data.verifyUser.__typename) {
                        case "VerifyUserOkResponse":
                            setMessage("Verification succeed.");
                            setSuccess(true);
                            navigate(routesPaths.LOGIN, {
                                state: {
                                    accountVerifiedSuccessfully: true,
                                },
                            });
                            return;
                        case "VerifyUserInvalidTokenResponse":
                            setMessage("Verification failed.");
                            console.error("Something went wrong");
                            navigate(routesPaths.LOGIN, {
                                state: {
                                    accountVerificationFailed: true,
                                },
                            });
                            return;
                        default:
                            navigate(routesPaths.LOGIN, {
                                state: {
                                    accountVerificationFailed: true,
                                },
                            });
                            break;
                    }
                }
                catch (e) {
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    console.error("Something went wrong");
                }
            });
        }
        if (!message) {
            activate();
        }
    }, [message, toast, token, activateUser, navigate]);
    if (!message) {
        return _jsx(Loader, {});
    }
    return (_jsx(_Fragment, { children: success ? (_jsx(VerifyWrapper, { title: "Success!", text: message, imageSrc: successImg })) : (_jsx(VerifyWrapper, { title: "Something went wrong...", text: message, imageSrc: failureImg, isWide: true })) }));
};
export default ActivateUser;
