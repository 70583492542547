import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { WIDTH } from "../time.components";
import theme from "../../../../../../../../common/theme";
export const NumberInput = styled.input `
  margin: auto;
  font-size: 14px;
  max-width: ${WIDTH}px;
  width: 100%;
  padding: 0;
  border: 0;
  text-align: center;
  appearance: none;
  color: inherit;
  ${p => p.focused &&
    css `
      color: ${theme.colors.cyan[400]};
    `};
`;
