import * as Yup from "yup";
export const emailCreatorInitialValues = {
    subject: "",
    sendsAt: "",
    content: "<p></p>",
    sendsNow: false,
};
export const emailEditorInitialValues = (email) => {
    return {
        subject: email.subject,
        sendsAt: new Date(email.sendsAt),
        content: email.body,
        sendsNow: false,
    };
};
export const emailCreatorValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    sendsAt: Yup.date().required("Sending date is required"),
    content: Yup.string().required("Content is required"),
});
