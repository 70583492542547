import { isAfter } from "date-fns";
import { useFormikContext } from "formik";
import { useMemo } from "react";
const useMinDate = (minDateProp, minDateField) => {
    const formik = useFormikContext();
    const minDate = useMemo(() => {
        const dates = [];
        if (minDateField) {
            const fieldValue = formik.getFieldMeta(minDateField).value;
            if (fieldValue instanceof Date) {
                dates.push(fieldValue);
            }
        }
        if (minDateProp instanceof Date) {
            dates.push(minDateProp);
        }
        return dates.length === 0
            ? undefined
            : dates.reduce((prev, curr) => (isAfter(curr, prev) ? curr : prev));
    }, [minDateProp, formik, minDateField]);
    return minDate;
};
export default useMinDate;
