import styled from "@emotion/styled";
import theme from "../../../../../../common/theme";
import Calendar from "../shared/calendar";
export const StyledCalendar = styled(Calendar) `
  position: absolute;
  top: calc(100% + 8px);
  ${p => (p.placement === "bottom-right" ? "right: 0;" : "left: 0;")};
  z-index: ${theme.zIndices.dropdown};
  box-shadow: ${theme.shadows.dropdown};
`;
export const Wrapper = styled.div `
  display: flex;
  position: relative;
`;
