import { jsx as _jsx } from "react/jsx-runtime";
import { format } from "date-fns";
import React, { useCallback, useMemo } from "react";
import EditableNumber from "./editable-number";
const NumberElements = ({ focusedColumn, setFocusedColumn, formatString, names: { column, addAction, setAction, subAction }, valueRange: { min, max }, dispatch, state: { currentDate }, type, }) => {
    const onFocus = useCallback(() => {
        setFocusedColumn(column);
    }, [column, setFocusedColumn]);
    const onBlur = useCallback(() => {
        setFocusedColumn(null);
    }, [setFocusedColumn]);
    const onUp = useCallback(() => {
        setFocusedColumn(column);
        dispatch({ type: addAction });
    }, [dispatch, addAction, column, setFocusedColumn]);
    const onSet = useCallback((newValue) => {
        dispatch({ type: setAction, value: newValue });
    }, [dispatch, setAction]);
    const onDown = useCallback(() => {
        setFocusedColumn(column);
        dispatch({ type: subAction });
    }, [dispatch, column, setFocusedColumn, subAction]);
    const displayString = useMemo(() => format(currentDate, formatString), [currentDate, formatString]);
    const isSelected = focusedColumn === column;
    return (_jsx(EditableNumber, { isSelected: isSelected, minValue: min, maxValue: max, onFocus: onFocus, onBlur: onBlur, onUp: onUp, onSet: onSet, onDown: onDown, value: displayString, type: type }));
};
export default NumberElements;
