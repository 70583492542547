var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { Button, useToast } from "@chakra-ui/react";
import { AddCampaignWithTemplateDocument, } from "@packages/apollo-hooks";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import routesPaths from "../../routing/route-paths";
const UseTemplateInNewCampaign = ({ templateId }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [addCampaignWithTemplate] = useMutation(AddCampaignWithTemplateDocument, {
        refetchQueries: ["getCampaigns"],
    });
    const handleUseTemplate = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield addCampaignWithTemplate({
                variables: {
                    input: {
                        templateId,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.addCampaignWithTemplate.__typename) {
                case "CampaignWithTemplateSuccessfulAddedResponse":
                    navigate(routesPaths.CAMPAIGN_EDITOR.replace(":id", data.addCampaignWithTemplate.id.toString()));
                    return;
                case "TemplateNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Template doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [addCampaignWithTemplate, navigate, templateId, toast]);
    return _jsx(Button, Object.assign({ onClick: handleUseTemplate }, { children: "Use Template" }));
};
export default UseTemplateInNewCampaign;
