import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Divider, Icon, Stack, StackDivider, Text } from "@chakra-ui/react";
import { GetCampaignDetailsDocument, } from "@packages/apollo-hooks";
import { IoMail, IoMailOpen, IoMailUnread } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Loader from "../../../shared/components/loader";
import StatisticsGrid from "./statistics-grid";
const Statistics = ({ statistics }) => {
    var _a, _b, _c;
    const { id } = useParams();
    const showOne = statistics.uniqueOpenCount === statistics.totalSentCount;
    const isEmail = statistics.__typename === "CampaignEmailType";
    const { data, loading, error } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId: id,
        },
    });
    const contactsCount = (_b = (_a = data === null || data === void 0 ? void 0 : data.getCampaignDetails) === null || _a === void 0 ? void 0 : _a.contactList) === null || _b === void 0 ? void 0 : _b.contactsCount;
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsxs("div", { children: ["Error: ", error.message] });
    return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(Stack, Object.assign({ direction: "column", spacing: "0", divider: _jsx(StackDivider, { borderColor: "gray.100" }) }, { children: [_jsx(Stack, Object.assign({ direction: "row", spacing: "0", divider: _jsx(StackDivider, { borderColor: "gray.100" }) }, { children: _jsxs(Stack, Object.assign({ p: "6", display: "flex", flex: "1" }, { children: [_jsxs(Stack, Object.assign({ direction: "row", justifyContent: "space-between" }, { children: [_jsxs(Stack, Object.assign({ direction: "row", spacing: "1", alignItems: "center" }, { children: [_jsx(Text, Object.assign({ fontSize: "sm", color: "gray.500" }, { children: "Opened" })), _jsx(Text, Object.assign({ fontSize: "sm" }, { children: statistics.uniqueOpenCount.toString() })), _jsx(Icon, { as: IoMailOpen, boxSize: "12px", color: isEmail ? "green.500" : "cyan.400" })] })), _jsxs(Stack, Object.assign({ direction: "row", spacing: "1", alignItems: "center" }, { children: [_jsx(Text, Object.assign({ fontSize: "sm", color: "gray.500" }, { children: "Sends" })), _jsx(Text, Object.assign({ fontSize: "sm" }, { children: statistics.totalResponseCount.toString() })), _jsx(Icon, { as: IoMailUnread, boxSize: "12px", color: isEmail ? "green.900" : "cyan.200" })] })), _jsxs(Stack, Object.assign({ direction: "row", spacing: "1", alignItems: "center" }, { children: [_jsx(Text, Object.assign({ fontSize: "sm", color: "gray.500" }, { children: "Total Recipients" })), _jsx(Text, Object.assign({ fontSize: "sm" }, { children: isEmail
                                                        ? statistics.totalRecipientsCount
                                                        : (_c = contactsCount === null || contactsCount === void 0 ? void 0 : contactsCount.toString()) !== null && _c !== void 0 ? _c : "" })), _jsx(Icon, { as: IoMail, boxSize: "12px", color: "gray.200" })] }))] })), _jsx(ResponsiveContainer, Object.assign({ debounce: 100, width: "100%", height: 32 }, { children: _jsxs(BarChart, Object.assign({ data: [
                                            Object.assign(Object.assign({}, statistics), { openedCount: statistics.uniqueOpenCount, totalComplement: statistics.uniqueOpenCount >= statistics.totalSentCount
                                                    ? statistics.totalResponseCount -
                                                        statistics.uniqueOpenCount
                                                    : statistics.totalResponseCount -
                                                        statistics.totalSentCount, firstComplement: statistics.uniqueOpenCount > statistics.totalSentCount
                                                    ? statistics.totalSentCount
                                                    : statistics.uniqueOpenCount, secondComplement: statistics.uniqueOpenCount > statistics.totalSentCount
                                                    ? statistics.uniqueOpenCount - statistics.totalSentCount
                                                    : statistics.totalSentCount -
                                                        statistics.uniqueOpenCount }),
                                        ], layout: "vertical", margin: {
                                            top: 12,
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                        }, maxBarSize: 8, stackOffset: "expand" }, { children: [_jsx(XAxis, { hide: true, type: "number" }), _jsx(YAxis, { hide: true, type: "category" }), showOne ? (_jsx(Bar, { dataKey: "uniqueOpenCount", stackId: "a", fill: isEmail ? "#2BA530" : "#0BC5EA" })) : (_jsxs(_Fragment, { children: [_jsx(Bar, { dataKey: "firstComplement", stackId: "a", fill: isEmail
                                                            ? statistics.uniqueOpenCount >
                                                                statistics.totalSentCount
                                                                ? "#1A7A1E"
                                                                : "#2BA530"
                                                            : statistics.uniqueOpenCount >
                                                                statistics.totalSentCount
                                                                ? "#9DECF9"
                                                                : "#0BC5EA" }), _jsx(Bar, { dataKey: "secondComplement", stackId: "a", fill: isEmail
                                                            ? statistics.uniqueOpenCount <
                                                                statistics.totalSentCount
                                                                ? "#1A7A1E"
                                                                : "#2BA530"
                                                            : statistics.uniqueOpenCount <
                                                                statistics.totalSentCount
                                                                ? "#9DECF9"
                                                                : "#0BC5EA" })] })), _jsx(Bar, { dataKey: "totalComplement", stackId: "a", fill: "#EDF2F7" })] })) })), _jsxs(Stack, Object.assign({ direction: "row", spacing: "1" }, { children: [_jsx(Text, Object.assign({ fontSize: "sm", color: "gray.500" }, { children: "Open rate" })), _jsxs(Text, Object.assign({ fontSize: "sm" }, { children: [statistics.openRate, "%"] }))] }))] })) })), _jsx(StatisticsGrid, { statistics: statistics })] }))] }));
};
export default Statistics;
