import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { GetCampaignEmailStatisticsDocument, } from "@packages/apollo-hooks";
import Loader from "../../../../../../shared/components/loader";
import Statistics from "../../../../statistics";
const EmailStatistics = ({ id }) => {
    const { data, loading, error } = useQuery(GetCampaignEmailStatisticsDocument, {
        variables: {
            id: id,
        },
    });
    if (loading || !data || !data.getCampaignEmail)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return _jsx(Statistics, { statistics: data.getCampaignEmail });
};
export default EmailStatistics;
