import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Button, HStack } from "@chakra-ui/react";
import { CampaignStatuses, GetCampaignDetailsDocument, } from "@packages/apollo-hooks";
import { IoMailOutline, IoPauseOutline, IoPencilOutline, } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import useIsAdmin from "../../../common/helpers/use-is-admin";
import routesPaths from "../../routing/route-paths";
import Loader from "../../shared/components/loader";
import PageContent from "../../shared/components/page/page-content";
import PageHeader from "../../shared/components/page/page-header";
import PageTitle from "../../shared/components/page/page-header/page-title";
import useIsCampaignEditable from "../helpers/use-is-campaign-editable";
import useStartCampaign from "../helpers/use-start-campaign";
import useStopCampaign from "../helpers/use-stop-campaign";
import CampaignDetails from "./campaign-details";
import OverviewAndMessagesTab from "./overview-and-messages-tab";
const SingleCampaign = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isAdmin = useIsAdmin();
    const isEditable = useIsCampaignEditable(id);
    const { handleStopCampaign, stopCampaignLoading } = useStopCampaign(id);
    const { handleStartCampaign, startCampaignLoading } = useStartCampaign(id);
    const { data, loading, error } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId: id,
        },
    });
    if (loading || !data || !data.getCampaignDetails)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(PageHeader, { children: [_jsx(PageTitle, { title: data.getCampaignDetails.name, handleBackIconClick: () => navigate(routesPaths.CAMPAIGNS) }), _jsxs(HStack, Object.assign({ justify: "flex-end", flex: "1", w: { base: "full", md: "auto" }, spacing: { base: "2", md: "2" } }, { children: [!isAdmin && isEditable && (_jsx(Button, Object.assign({ leftIcon: _jsx(IoPencilOutline, {}), onClick: () => navigate(routesPaths.CAMPAIGN_EDITOR.replace(":id", id)), variant: "outline" }, { children: "Edit Campaign" }))), !isAdmin &&
                                data.getCampaignDetails.status === CampaignStatuses.Active && (_jsx(Button, Object.assign({ leftIcon: _jsx(IoPauseOutline, {}), onClick: handleStopCampaign, variant: "outline", isLoading: stopCampaignLoading, colorScheme: "red" }, { children: "Pause Campaign" }))), !isAdmin && isEditable && (_jsx(Button, Object.assign({ leftIcon: _jsx(IoMailOutline, {}), onClick: handleStartCampaign, isLoading: startCampaignLoading, variant: "solid", colorScheme: "green" }, { children: data.getCampaignDetails.status === CampaignStatuses.Paused
                                    ? "Restart Campaign"
                                    : "Start Campaign" })))] }))] }), _jsxs(PageContent, { children: [_jsx(CampaignDetails, { campaignDetails: data.getCampaignDetails }), _jsx(OverviewAndMessagesTab, {})] })] }));
};
export default SingleCampaign;
