import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Avatar, Box, SkeletonText, Stack, Text } from "@chakra-ui/react";
import { CurrentUserDocument } from "@packages/apollo-hooks";
import React from "react";
const UserCard = () => {
    const { data, loading } = useQuery(CurrentUserDocument, {
        fetchPolicy: "cache-first",
    });
    return (_jsx(Box, { children: _jsxs(Stack, Object.assign({ spacing: "4", direction: "row", alignItems: "center" }, { children: [_jsx(Avatar, { size: "sm" }), loading ? (_jsx(SkeletonText, { height: "10px", width: "100%" })) : (_jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsxs(Text, Object.assign({ fontSize: "sm", fontWeight: "medium" }, { children: [data === null || data === void 0 ? void 0 : data.currentUser.firstName, " ", data === null || data === void 0 ? void 0 : data.currentUser.lastName] })), _jsx(Text, Object.assign({ fontSize: "xs", fontWeight: "medium", color: "gray.600" }, { children: data === null || data === void 0 ? void 0 : data.currentUser.email }))] })))] })) }));
};
export default UserCard;
