import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { GetTemplatesDocument, } from "@packages/apollo-hooks";
import React from "react";
import Loader from "../../../shared/components/loader";
import NoTemplates from "../../no-templates";
import TemplateCard from "../../templates-browser/template-card";
const RecentFavoriteTabPanel = ({ templateFolderId, handleSelectTemplate, selectedTemplate, favorite = false, }) => {
    const { data, loading } = useQuery(GetTemplatesDocument, {
        variables: templateFolderId
            ? {
                templateFolderId,
            }
            : favorite
                ? {
                    isFavorite: true,
                }
                : {},
        fetchPolicy: "network-only",
        // to set loading to true while refetch
        notifyOnNetworkStatusChange: true,
    });
    return (_jsx(_Fragment, { children: loading ? (_jsx(Loader, {})) : (_jsx(Stack, Object.assign({ spacing: "1" }, { children: data && data.getTemplates.items.length > 0 ? (data.getTemplates.items.map(template => {
                return (_jsx(TemplateCard, { template: template, showFolderBadge: true, showPreview: false, cursor: "pointer", borderColor: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) === template.id
                        ? "green.500"
                        : "gray.200", onClick: () => {
                        handleSelectTemplate(template);
                    }, modal: true }, template.id));
            })) : (_jsx(Box, Object.assign({ height: "250px" }, { children: _jsx(NoTemplates, {}) }))) }))) }));
};
export default RecentFavoriteTabPanel;
