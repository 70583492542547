var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { eachDayOfInterval, endOfDay, endOfMonth, endOfWeek, format, isAfter, isBefore, isSameDay, isSunday, isWeekend as isDateWeekend, isWithinInterval, startOfDay, startOfMonth, startOfWeek, } from "date-fns";
import React, { useCallback, useMemo, } from "react";
import { WEEK_START_DAY } from "../../../../../../../../common/helpers/date-fns";
import ResponsiveSquare from "../shared/responsive-square";
import { Day, Weekday } from "./days.components";
const Days = ({ todayDate, displayDate, selectionInterval, minDate, maxDate, dispatch, }) => {
    const checkIfDateInSelection = useCallback((date) => {
        if (!selectionInterval) {
            return false;
        }
        return isWithinInterval(date, selectionInterval);
    }, [selectionInterval]);
    const currentCalendarDaysInfo = useMemo(() => {
        return eachDayOfInterval({
            start: startOfWeek(startOfMonth(displayDate), {
                weekStartsOn: WEEK_START_DAY,
            }),
            end: endOfWeek(endOfMonth(displayDate), {
                weekStartsOn: WEEK_START_DAY,
            }),
        }).map(dayDate => {
            var _a, _b;
            return ({
                value: dayDate,
                label: format(dayDate, "d"),
                isHoliday: isSunday(dayDate),
                isWeekend: isDateWeekend(dayDate),
                isPrevMonth: isBefore(dayDate, startOfMonth(displayDate)),
                isNextMonth: isAfter(dayDate, endOfMonth(displayDate)),
                isPast: isBefore(dayDate, startOfDay(todayDate)),
                isToday: isSameDay(dayDate, todayDate),
                isFuture: isAfter(dayDate, endOfDay(todayDate)),
                isSelected: checkIfDateInSelection(dayDate),
                isFirstSelection: (_a = (selectionInterval && isSameDay(dayDate, selectionInterval.start))) !== null && _a !== void 0 ? _a : false,
                isLastSelection: (_b = (selectionInterval && isSameDay(dayDate, selectionInterval.end))) !== null && _b !== void 0 ? _b : false,
                isDisabled: (minDate instanceof Date && isBefore(dayDate, minDate)) ||
                    (maxDate instanceof Date && isBefore(maxDate, dayDate)),
            });
        });
    }, [
        displayDate,
        todayDate,
        checkIfDateInSelection,
        selectionInterval,
        minDate,
        maxDate,
    ]);
    const onDateClick = useCallback((value) => () => {
        dispatch({ type: "setDate", date: value });
    }, [dispatch]);
    const days = useMemo(() => currentCalendarDaysInfo.map((_a) => {
        var { value, label } = _a, dayInfo = __rest(_a, ["value", "label"]);
        return (_jsx(ResponsiveSquare, { children: _jsx(Day, Object.assign({ onClick: onDateClick(value), dayInfo: dayInfo, "data-cy-value": format(value, "y-M-d"), "data-cy-disabled": dayInfo.isDisabled }, { children: label })) }, value.toISOString()));
    }), [currentCalendarDaysInfo, onDateClick]);
    const weekdayLabels = useMemo(() => eachDayOfInterval({
        start: startOfWeek(todayDate, { weekStartsOn: WEEK_START_DAY }),
        end: endOfWeek(todayDate, { weekStartsOn: WEEK_START_DAY }),
    }).map(dayDate => format(dayDate, "ccc")), [todayDate]);
    const weekdays = useMemo(() => weekdayLabels.map(label => (_jsx(ResponsiveSquare, { children: _jsx(Weekday, { children: label }) }, label))), [weekdayLabels]);
    return (_jsxs(_Fragment, { children: [weekdays, days] }));
};
export default Days;
