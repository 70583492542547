import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ModalBody, ModalFooter } from "@chakra-ui/react";
import { Form } from "formik";
import React from "react";
import SelectField from "../../../shared/components/form/select-field";
import useContactListsOptions from "../add-contact/use-contact-lists-options";
const CopyContactsFromListFormContent = ({ isSubmitting, isValid, submitCount, onClose, contactListId, }) => {
    const { contactListsOptions, loading } = useContactListsOptions(true, contactListId);
    return (_jsx(Form, { children: _jsxs(_Fragment, { children: [_jsx(ModalBody, { children: !loading && (_jsx(SelectField, { name: "list", options: contactListsOptions })) }), _jsxs(ModalFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", mr: 3, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "submit", isLoading: isSubmitting, disabled: submitCount > 0 && !isValid }, { children: "Save Contacts" }))] })] }) }));
};
export default CopyContactsFromListFormContent;
