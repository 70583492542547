import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Stack, TabPanel, Text } from "@chakra-ui/react";
import { GetInvoicesDocument } from "@packages/apollo-hooks";
import * as React from "react";
import Loader from "../../../shared/components/loader";
import InvoicesTable from "./invoices-table";
const InvoicesPanel = props => {
    const { data, loading, error } = useQuery(GetInvoicesDocument, {
        fetchPolicy: "network-only",
        // to set loading to true while refetch
        notifyOnNetworkStatusChange: true,
    });
    if (loading) {
        return (_jsx(TabPanel, Object.assign({}, props, { children: _jsx(Loader, {}) })));
    }
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsx(TabPanel, Object.assign({}, props, { children: _jsxs(Stack, Object.assign({ spacing: "1" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold", mb: "16px" }, { children: "Invoices" })), data && _jsx(InvoicesTable, { tableData: data })] })) })));
};
export default InvoicesPanel;
