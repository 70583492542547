import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, IconButton, useBreakpointValue, useDisclosure, } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import Logo from "../../../shared/components/logo";
import Sidebar from "../sidebar";
const useMobileMenuState = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, lg: false });
    useEffect(() => {
        if (isMobile === false) {
            onClose();
        }
    }, [isMobile, onClose]);
    return { isOpen, onClose, onOpen };
};
const Topbar = () => {
    const { isOpen, onClose, onOpen } = useMobileMenuState();
    return (_jsxs(Flex, Object.assign({ align: "center", justify: "space-between", py: "2", px: "4", bg: "gray.50", display: { base: "flex", md: "none" }, borderBottomWidth: "1px" }, { children: [_jsx(Logo, {}), _jsx(IconButton, { onClick: onOpen, variant: "unstyled", display: "flex", cursor: "pointer", "aria-label": "Menu", icon: _jsx(HiOutlineMenu, { fontSize: "1.5rem" }) }), _jsxs(Drawer, Object.assign({ size: "xs", placement: "left", isOpen: isOpen, blockScrollOnMount: false, onClose: onClose }, { children: [_jsx(DrawerOverlay, {}), _jsxs(DrawerContent, Object.assign({ bg: "white", shadow: "none", position: "relative", maxW: "64" }, { children: [_jsx(Sidebar, { width: "full", height: "full", bg: "inherit", border: "0" }), _jsx(DrawerCloseButton, { bg: "blue.500", _hover: { bg: "blue.600" }, _active: { bg: "blue.700" }, rounded: "0", position: "absolute", color: "white", right: "-8", top: "0" })] }))] }))] })));
};
export default Topbar;
