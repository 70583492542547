export const translations = {
    uploadStep: {
        title: "Upload file",
        manifestTitle: "We expect the following columns:",
        maxRecordsExceeded: (maxRecords) => `Too many records. Up to ${maxRecords} allowed`,
        dropzone: {
            title: "Upload .xlsx, .xls or .csv file",
            errorToastDescription: "upload rejected",
            activeDropzoneTitle: "Drop file here...",
            buttonTitle: "Select file",
            loadingTitle: "Processing...",
        },
        selectSheet: {
            title: "Select the sheet to use",
            nextButtonTitle: "Continue",
        },
    },
    matchColumnsStep: {
        title: "Match Columns",
        subtitle: "Confirm the field names match columns below",
        nextButtonTitle: "Continue",
        userTableTitle: "Your table",
        templateTitle: "Will become",
        selectPlaceholder: "Select Column",
        ignoredColumnText: "Column ignored",
        subSelectPlaceholder: "Select...",
        matchDropdownTitle: "Match",
        unmatched: "Unmatched",
        duplicateColumnWarningTitle: "Column Unselected",
        duplicateColumnWarningDescription: "You have already matched a column using this heading. Please select a different heading to continue",
    },
    validationStep: {
        title: "Review Data and Confirm",
        nextButtonTitle: "Confirm",
        noRowsMessage: "No data found",
        noRowsMessageWhenFiltered: "No data containing errors",
        discardButtonTitle: "Discard selected rows",
        filterSwitchTitle: "Show only rows with errors",
    },
    summaryStep: {
        title: "Summary",
        sectionTitle: "Save contact list",
        newListSectionTitle: "Name and save contact list",
    },
    alerts: {
        confirmClose: {
            headerTitle: "Exit",
            bodyText: "Are you sure? Your current information will not be saved.",
            cancelButtonTitle: "Cancel",
            exitButtonTitle: "Exit",
        },
        submitIncomplete: {
            headerTitle: "Errors detected",
            bodyText: "There are still some rows that contain errors. Rows with invalid data or missing information will not be imported.",
            bodyTextSubmitForbidden: "There are still some rows containing errors.",
            cancelButtonTitle: "Cancel",
            finishButtonTitle: "Continue",
        },
        unmatchedRequiredFields: {
            headerTitle: "Not all columns matched",
            bodyText: "There are required columns that are not matched or ignored. Do you want to continue?",
            listTitle: "Columns not matched:",
            cancelButtonTitle: "Cancel",
            continueButtonTitle: "Continue",
        },
        toast: {
            error: "Error",
        },
    },
};
