const stepper = {
    baseStyle: {
        stepper: {
            gap: 0,
            paddingBottom: "24px",
        },
        step: {
            gap: 0,
            alignItems: "flex-start",
        },
        separator: {
            marginInlineStart: "0 !important",
        },
        title: {
            position: "absolute",
            bottom: "-100%",
            transform: "translateX(calc(12px - 50%))",
        },
    },
};
export default stepper;
