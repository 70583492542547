var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text, } from "@chakra-ui/react";
import * as React from "react";
import { IoCloseOutline } from "react-icons/io5";
import AppEventTarget from "../../../../common/events/app-event-target";
import LogoutRequestEvent from "../../../../common/events/logout-request-event";
import PlanFeatures from "../plan-features";
import SubscriptionButtons from "../subscription-buttons";
const TrialEndedModal = ({ isOpen, onClose, }) => {
    const logout = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        AppEventTarget.dispatchEvent(new LogoutRequestEvent());
    }), []);
    return (_jsxs(Modal, Object.assign({ size: "xl", isOpen: isOpen, onClose: onClose }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsxs(ModalHeader, Object.assign({ py: "21px", px: "22px", display: "flex", justifyContent: "space-between" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg" }, { children: "We apologize, but your trial for Motar.me has ended." })), _jsx(IconButton, { ml: "auto", size: "xs", fontSize: "xl", variant: "ghost", colorScheme: "gray", "aria-label": "Logout", icon: _jsx(IoCloseOutline, {}), onClick: logout })] })), _jsx(ModalBody, Object.assign({ pt: "0", pb: "6", px: "22px" }, { children: _jsxs(Stack, Object.assign({ spacing: "6" }, { children: [_jsx(PlanFeatures, {}), _jsx(SubscriptionButtons, {})] })) }))] })] })));
};
export default TrialEndedModal;
