var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { StartCampaignDocument } from "@packages/apollo-hooks";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import routesPaths from "../../routing/route-paths";
const useStartCampaign = (campaignId) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [startCampaign, { loading }] = useMutation(StartCampaignDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getCampaignDetails"],
    });
    const handleStartCampaign = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data: startData } = yield startCampaign({
                variables: {
                    input: {
                        campaignId,
                    },
                },
            });
            switch (startData === null || startData === void 0 ? void 0 : startData.startCampaign.__typename) {
                case "StartCampaignSuccessfulResponse":
                    toast({
                        title: "Campaign has started",
                        status: "success",
                        isClosable: false,
                    });
                    return;
                case "StartCampaignNoEmailsResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign has no emails",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "StartCampaignNoProviderResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign has no provider selected",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "CampaignNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "CampaignEmailInvalidDateResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign email invalid date",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "CampaignContactListNotExists":
                    toast({
                        title: "Something went wrong",
                        description: "Contact list doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "CampaignAlreadyActiveResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign already active",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "StartCampaignEmailsInvalidDates":
                    toast({
                        title: "Something went wrong",
                        description: "Invalid email sending date",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "GoogleIntegrationDisabledResponse":
                    toast({
                        title: "Your google integration has expired",
                        description: "Please connect your account again",
                        status: "error",
                        isClosable: false,
                    });
                    navigate(routesPaths.SETTINGS);
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    return;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [campaignId, navigate, startCampaign, toast]);
    return { handleStartCampaign, startCampaignLoading: loading };
};
export default useStartCampaign;
