var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { Box, Table as ChakraTable, Heading, ModalBody, Stack, Tbody, Td, Text, Th, Thead, Tr, useToast, } from "@chakra-ui/react";
import { ImportContactsDocument, } from "@packages/apollo-hooks";
import { Form, Formik } from "formik";
import { useCallback, useMemo, useRef, useState } from "react";
import DataGrid from "react-data-grid";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import * as Yup from "yup";
import { ContinueButton } from "../../components/ContinueButton";
import { useRsi } from "../../hooks/useRsi";
import { generateColumns } from "./components/columns";
import FormTopSection from "./components/form-top-section";
import SummaryBadge, { ImportStatuses } from "./components/summary-badge";
export const summaryInitialValues = (contactListId) => {
    return {
        contactListId: contactListId || "",
        contactListName: "",
    };
};
export const summaryValidationSchema = Yup.object().shape({
    contactListId: Yup.string().when("contactListName", {
        is: (val) => !val || val.length === 0,
        then: Yup.string().required(),
        otherwise: Yup.string(),
    }),
    contactListName: Yup.string().when("contactListId", {
        is: (val) => !val || val.length === 0,
        then: Yup.string().required(),
        otherwise: Yup.string(),
    }),
}, [["contactListId", "contactListName"]]);
export const SummaryStep = ({ validData, onGoBack, }) => {
    const formRef = useRef();
    const { id } = useParams();
    const handleSubmitFromOutside = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };
    const [afterSubmit, setAfterSubmit] = useState(false);
    const [contactsResult, setContactsResult] = useState([]);
    const [contactListName, setContactListName] = useState("");
    const toast = useToast();
    const { translations, fields, onClose, contactListId } = useRsi();
    const [importContacts] = useMutation(ImportContactsDocument, {
        refetchQueries: [id ? "getContactList" : "getContactLists"],
    });
    const tableColumns = useMemo(() => {
        return [
            { Header: "First name", accessor: "firstName" },
            { Header: "Last name", accessor: "lastName" },
            { Header: "E-mail address", accessor: "email" },
            { Header: "Company", accessor: "leadCompany" },
            { Header: "Job title", accessor: "jobTitle" },
            { Header: "Country", accessor: "country" },
            {
                Header: "",
                accessor: "status",
                Cell: ({ row: { original } }) => (_jsx(SummaryBadge, { status: original.status })),
            },
        ];
    }, []);
    const handleSubmit = useCallback((values, { setFieldError }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield importContacts({
                variables: {
                    input: {
                        contactListId: values.contactListId,
                        contactListName: values.contactListName.length > 0
                            ? values.contactListName
                            : null,
                        contacts: validData,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.importContacts.__typename) {
                case "ImportContactsSuccessResponse":
                    setAfterSubmit(true);
                    setContactsResult(data.importContacts.results);
                    setContactListName(data.importContacts.contactListName);
                    return;
                case "ContactListNotExistResponse":
                    setFieldError("contactListId", data.importContacts.className);
                    break;
                case "ContactListNameTakenResponse":
                    setFieldError("contactListName", "Contact list with this name already exists");
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    console.error("err");
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: true,
            });
        }
    }), [importContacts, validData, toast]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns: tableColumns,
        data: contactsResult || [],
    });
    const columns = useMemo(() => generateColumns(fields), [fields]);
    const rowKeyGetter = useCallback((row) => row.__index, []);
    return (_jsx(_Fragment, { children: !afterSubmit ? (_jsxs(_Fragment, { children: [_jsx(ModalBody, Object.assign({ p: 8, bg: "gray.50" }, { children: _jsx(Formik, Object.assign({ innerRef: formRef, initialValues: summaryInitialValues(contactListId), validationSchema: summaryValidationSchema, onSubmit: handleSubmit }, { children: _jsxs(Form, { children: [_jsx(Box, Object.assign({ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "left", mb: "2rem", flexWrap: "wrap", gap: "8px" }, { children: _jsx(FormTopSection, {}) })), _jsx(Box, Object.assign({ height: "300px", flexGrow: 1 }, { children: _jsx(DataGrid, { headerRowHeight: 52, rowHeight: 52, className: "rdg-light", rowKeyGetter: rowKeyGetter, rows: validData, columns: columns }) }))] }) })) })), _jsx(ContinueButton, { onContinue: handleSubmitFromOutside, title: translations.validationStep.nextButtonTitle, onGoBack: onGoBack })] })) : (_jsxs(_Fragment, { children: [_jsx(ModalBody, Object.assign({ p: 8, bg: "gray.50" }, { children: _jsxs(Stack, Object.assign({ spacing: "8" }, { children: [_jsx(Heading, { children: "Summary" }), contactListName && (_jsx(Text, Object.assign({ fontSize: "2xl", fontWeight: "semibold" }, { children: contactListName }))), _jsx(Box, Object.assign({ style: {
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                } }, { children: _jsxs(ChakraTable, Object.assign({}, getTableProps(), { style: {
                                        display: "table",
                                        margin: 0,
                                    } }, { children: [_jsx(Thead, Object.assign({ style: {
                                                position: "sticky",
                                                top: 0,
                                                left: 0,
                                            } }, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }), headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex, bg: "gray.200", minWidth: "105px" }), column.render("Header"))))))) })), _jsx(Tbody, Object.assign({}, getTableBodyProps(), { children: rows.map((row, rowIndex) => {
                                                prepareRow(row);
                                                return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }), row.cells.map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex, bg: row.original.status === ImportStatuses.Failed
                                                        ? "#FFF5F5"
                                                        : row.original.status ===
                                                            ImportStatuses.Updated
                                                            ? "#F0FFF4"
                                                            : "white" }), cell.render("Cell"))))));
                                            }) }))] })) }))] })) })), _jsx(ContinueButton, { onContinue: onClose, title: translations.validationStep.nextButtonTitle })] })) }));
};
