var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, Text, useToast } from "@chakra-ui/react";
import { DATE_FORMAT } from "@packages/helpers";
import format from "date-fns/format";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { IoDownloadOutline, IoMailOpenOutline } from "react-icons/io5";
import { getUserToken } from "../../../common/helpers/user-token";
import CreateCampaign from "../create-campaign";
const CampaignsButtons = ({ fromDate, toDate }) => {
    const toast = useToast();
    const token = getUserToken();
    const bothDatesSelected = !!fromDate && !!toDate;
    const triggerDownload = useCallback((reportType) => __awaiter(void 0, void 0, void 0, function* () {
        if (!bothDatesSelected)
            return;
        const endpoint = reportType === "open"
            ? "campaign/open-report"
            : "campaign/overview-report";
        try {
            let url = `${process.env.REACT_APP_API_URL}/${endpoint}/${fromDate === null || fromDate === void 0 ? void 0 : fromDate.toDateString()}/${toDate === null || toDate === void 0 ? void 0 : toDate.toDateString()}`;
            if (reportType === "open")
                url = `${url}/${new Date().getTimezoneOffset()}`;
            const response = yield fetch(url, {
                method: "GET",
                headers: new Headers({
                    Authorization: `Bearer ${token === null || token === void 0 ? void 0 : token.token}`,
                }),
            });
            if (response.status === 200) {
                const reportName = reportType === "open" ? "open_report" : "overviewReport";
                FileSaver.saveAs(yield response.blob(), `${reportName}_${format(fromDate, DATE_FORMAT)}_${format(toDate, DATE_FORMAT)}.xlsx`);
            }
            if (response.status === 400) {
                toast({
                    title: "Something went wrong",
                    description: "Invalid dates selected",
                    status: "error",
                    isClosable: false,
                });
            }
        }
        catch (err) {
            console.error(err);
            toast({
                title: "Something went wrong",
                description: err.message,
                status: "error",
                isClosable: false,
            });
        }
    }), [bothDatesSelected, fromDate, toDate, toast, token === null || token === void 0 ? void 0 : token.token]);
    return bothDatesSelected ? (_jsxs(HStack, { children: [_jsx(Button, Object.assign({ onClick: () => triggerDownload("campaignOverview") }, { children: _jsxs(HStack, { children: [_jsx(IoDownloadOutline, { fontSize: "20px" }), _jsx(Text, { children: "Download Overview Report" })] }) })), _jsx(Button, Object.assign({ onClick: () => triggerDownload("open"), variant: "outline" }, { children: _jsxs(HStack, { children: [_jsx(IoMailOpenOutline, { fontSize: "20px" }), _jsx(Text, { children: "Download Open Report" })] }) }))] })) : (_jsx(CreateCampaign, { inSidebar: false }));
};
export default CampaignsButtons;
