import { isBefore } from "date-fns";
import { useFormikContext } from "formik";
import { useMemo } from "react";
const useMaxDate = (maxDateProp, maxDateField) => {
    const formik = useFormikContext();
    const maxDate = useMemo(() => {
        const dates = [];
        if (maxDateField) {
            const fieldValue = formik.getFieldMeta(maxDateField).value;
            if (fieldValue instanceof Date) {
                dates.push(fieldValue);
            }
        }
        if (maxDateProp instanceof Date) {
            dates.push(maxDateProp);
        }
        return dates.length === 0
            ? undefined
            : dates.reduce((prev, curr) => (isBefore(curr, prev) ? curr : prev));
    }, [maxDateProp, formik, maxDateField]);
    return maxDate;
};
export default useMaxDate;
