var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/toast";
import { Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import SaveTemplateModal from "../../save-template-modal";
import { templateCreatorFirstStepValidationSchema, templateEditorInitialValues, } from "../../template-creator/form";
import TemplateEditorFormContent from "./template-editor-form-content";
const TemplateEditorForm = ({ onClose, template, }) => {
    var _a;
    const [isOpen, setIsOpen] = useState(false);
    const [subject, setSubject] = useState(template.subject);
    const [content, setContent] = useState(template.body);
    const toast = useToast();
    const cancelRef = useRef();
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setSubject(values.subject);
            setContent(values.content);
            setIsOpen(true);
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [toast]);
    return (_jsxs(_Fragment, { children: [_jsx(Formik, Object.assign({ initialValues: templateEditorInitialValues(template.subject, template.body), validationSchema: templateCreatorFirstStepValidationSchema, onSubmit: handleSubmit }, { children: formProps => (_jsx(TemplateEditorFormContent, Object.assign({}, formProps, { onClose: onClose }))) })), _jsx(SaveTemplateModal, { isOpen: isOpen, cancelRef: cancelRef, onClose: () => setIsOpen(false), onDrawerClose: onClose, subject: subject, content: content, templateId: template.id, templateName: template.name, templateFolderId: (_a = template.templateFolder) === null || _a === void 0 ? void 0 : _a.id })] }));
};
export default TemplateEditorForm;
