const input = {
    parts: ["field"],
    variants: {
        outline: {
            field: {
                bg: "white",
                borderRadius: "base",
                fontSize: "sm",
                px: 4,
                borderColor: "gray.200",
                color: "gray.600",
                _hover: {
                    borderColor: "cyan.200",
                },
                _invalid: {
                    boxShadow: "none",
                },
                _focus: {
                    borderColor: "cyan.400",
                },
                _placeholder: {
                    color: "gray.300",
                },
                _disabled: {
                    cursor: "not-allowed",
                    borderColor: "gray.200",
                },
            },
        },
    },
};
export default input;
