import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Box, useTheme } from "@chakra-ui/react";
import { usePopper } from "@chakra-ui/popper";
import { rootId } from "../Providers";
import { useRsi } from "../../hooks/useRsi";
function createWrapperAndAppendToBody(wrapperId) {
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}
export const SELECT_DROPDOWN_ID = "react-select-dropdown-wrapper";
const MenuPortal = (props) => {
    const theme = useTheme();
    const { rtl } = useRsi();
    const { popperRef, referenceRef } = usePopper({
        strategy: "fixed",
        matchWidth: true,
    });
    const [wrapperElement, setWrapperElement] = useState(null);
    useLayoutEffect(() => {
        let element = document.getElementById(SELECT_DROPDOWN_ID);
        let systemCreated = false;
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(SELECT_DROPDOWN_ID);
        }
        setWrapperElement(element);
        return () => {
            if (systemCreated && (element === null || element === void 0 ? void 0 : element.parentNode)) {
                element.parentNode.removeChild(element);
            }
        };
    }, []);
    useEffect(() => {
        referenceRef(props.controlElement);
    }, [props.controlElement, referenceRef]);
    // wrapperElement state will be null on very first render.
    if (wrapperElement === null)
        return null;
    return ReactDOM.createPortal(_jsx(Box, Object.assign({ dir: rtl ? "rtl" : "ltr", ref: popperRef, zIndex: theme.zIndices.tooltip, sx: {
            "&[data-popper-reference-hidden]": {
                visibility: "hidden",
                pointerEvents: "none",
            },
        }, id: rootId }, { children: props.children })), wrapperElement);
};
export const customComponents = {
    MenuPortal,
};
