import { jsx as _jsx } from "react/jsx-runtime";
import { TabPanel } from "@chakra-ui/react";
import * as React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import routesPaths from "../../routing/route-paths";
import useUserSubscription from "../../shared/hooks/use-user-subscription";
import { getPaymentTabNames, PAYMENT_TAB_NAME } from "../settings-content";
const PaymentsPanel = props => {
    const { tab } = useParams();
    const navigate = useNavigate();
    const { hasDirectPayment } = useUserSubscription();
    React.useEffect(() => {
        if (tab === PAYMENT_TAB_NAME) {
            navigate(generatePath(routesPaths.SETTINGS_BY_TAB, {
                tab: getPaymentTabNames(hasDirectPayment)[0],
            }));
        }
    }, [tab, navigate, hasDirectPayment]);
    return _jsx(TabPanel, Object.assign({}, props));
};
export default PaymentsPanel;
