var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, } from "@chakra-ui/form-control";
import { Field } from "formik";
import React from "react";
import DatePicker from "./date-time-picker";
import useMaxDate from "../shared/use-max-date";
import useMinDate from "../shared/use-min-date";
const DateTimePickerField = (_a) => {
    var { className, placeholder, type, showerror, isDisabled, formatString, timePickerDirection, minDate: minDateProp, minDateField, label, maxDate: maxDateProp, maxDateField, hasPadding = true } = _a, fieldProps = __rest(_a, ["className", "placeholder", "type", "showerror", "isDisabled", "formatString", "timePickerDirection", "minDate", "minDateField", "label", "maxDate", "maxDateField", "hasPadding"]);
    const minDate = useMinDate(minDateProp, minDateField);
    const maxDate = useMaxDate(maxDateProp, maxDateField);
    return (_jsx(Field, Object.assign({}, fieldProps, { children: ({ field, meta, form }) => (_jsxs(FormControl, Object.assign({ p: hasPadding ? "3" : "0", position: "relative", isInvalid: (meta.touched || form.submitCount > 0) && meta.error ? true : false }, { children: [label && _jsx(FormLabel, { children: label }), _jsx(DatePicker, Object.assign({}, field, { className: className, placeholder: placeholder, type: type, showerror: showerror, isDisabled: isDisabled, 
                    //   error={
                    //     /* NOTE: Formik treats Date in initialValues as empty object {}, making meta.touched = false on submit.
                    // https://github.com/jaredpalmer/formik/issues/1623 */
                    //     (meta.touched || form.submitCount > 0) && meta.error
                    //       ? meta.error
                    //       : undefined
                    //   }
                    formatString: formatString, timePickerDirection: timePickerDirection, minDate: minDate, maxDate: maxDate, onChange: (value) => {
                        form.setFieldValue(field.name, value);
                    }, onBlur: () => {
                        form.setFieldTouched(field.name, true);
                    } })), _jsx(FormErrorMessage, { children: (meta.touched || form.submitCount > 0) && meta.error
                        ? meta.error
                        : undefined })] }))) })));
};
export default DateTimePickerField;
