import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useParams } from "react-router-dom";
import LeftSide from "../../app/public-layout/left-side";
import SetNewPasswordForm from "./set-new-password-form";
const SetNewPassword = () => {
    const { resetToken } = useParams();
    return (_jsx(LeftSide, Object.assign({ title: "Set new password" }, { children: _jsx(SetNewPasswordForm, { resetToken: resetToken }) })));
};
export default SetNewPassword;
