import { getHours, getMinutes, setHours, setMinutes } from "date-fns";
import { isPm, setAmPm, toggleAmPm, } from "../../../../../../../common/helpers/date-fns";
const timeReducer = (prevState, action) => {
    switch (action.type) {
        case "addHour":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: setHours(prevState.currentDate, (isPm(prevState.currentDate) ? 12 : 0) +
                    ((getHours(prevState.currentDate) + 1) % 12)) });
        case "addMinute":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: setMinutes(prevState.currentDate, (getMinutes(prevState.currentDate) + 1) % 60) });
        case "subHour":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: setHours(prevState.currentDate, (isPm(prevState.currentDate) ? 12 : 0) +
                    ((12 + getHours(prevState.currentDate) - 1) % 12)) });
        case "subMinute":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: setMinutes(prevState.currentDate, (60 + getMinutes(prevState.currentDate) - 1) % 60) });
        case "toggleAmPm":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: toggleAmPm(prevState.currentDate) });
        case "setHour":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: setHours(prevState.currentDate, (isPm(prevState.currentDate) ? 12 : 0) + action.value) });
        case "setMinute":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: setMinutes(prevState.currentDate, action.value) });
        case "setAmPm":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: setAmPm(prevState.currentDate, action.value) });
        case "setTime":
            return Object.assign(Object.assign({}, prevState), { touched: true, currentDate: action.time });
    }
};
export default timeReducer;
