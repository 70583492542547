import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Button, Link, Stack, Text } from "@chakra-ui/react";
import { CurrentUserDocument } from "@packages/apollo-hooks";
import * as React from "react";
const SubscriptionCard = ({ isHighlighted, subscriptionPrice, subscriptionType, isSolid, }) => {
    const { data: userData } = useQuery(CurrentUserDocument);
    const url = `${process.env.REACT_APP_API_URL}/subscription/activate?stripeCustomerId=${userData === null || userData === void 0 ? void 0 : userData.currentUser.stripeCustomerId}&billingPeriod=${subscriptionType}`;
    return (_jsxs(Stack, Object.assign({ py: "6", px: "3", direction: "row", justify: "space-between", align: "center", spacing: "2", backgroundColor: isHighlighted ? "gray.50" : "transparent", borderRadius: "lg" }, { children: [_jsxs(Stack, Object.assign({ spacing: "0", w: "80px" }, { children: [_jsx(Text, Object.assign({ fontSize: "xl", fontWeight: "bold", color: "gray.700", textTransform: "capitalize" }, { children: subscriptionType })), _jsx(Text, Object.assign({ fontSize: "sm", color: "gray.700", lineHeight: "16px" }, { children: "1 user" }))] })), _jsxs(Stack, Object.assign({ height: "24px", spacing: "1", direction: "row", alignItems: "baseline" }, { children: [_jsx(Text, Object.assign({ color: "green.500", fontSize: "2xl", fontWeight: "bold" }, { children: subscriptionPrice })), _jsx(Text, Object.assign({ fontSize: "sm", color: "gray.700", fontWeight: "medium", lineHeight: "16px", pt: "1.5" }, { children: "/month" }))] })), _jsx(Link, Object.assign({ href: url, _hover: {
                    textDecor: "none",
                } }, { children: _jsx(Button, Object.assign({ variant: isSolid ? "solid" : "outline" }, { children: "Subscribe" })) }))] })));
};
export default SubscriptionCard;
