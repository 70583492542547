import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "../../../../../../../../common/theme";
import BaseGridElement from "../shared/base-grid-element";
export const Year = styled(BaseGridElement) `
  ${p => p.yearInfo.isCurrentYear &&
    css `
      background: ${theme.colors.gray[50]};
    `}
`;
