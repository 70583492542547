const alert = {
    parts: ["container", "icon", "title", "description"],
    baseStyle: {
        container: {
            borderRadius: "lg",
            color: "white",
        },
        icon: {
            color: "white",
        },
        title: {
            color: "white",
        },
        description: {
            color: "white",
        },
    },
};
export default alert;
