import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { Form } from "formik";
import React from "react";
import routesPaths from "../../routing/route-paths";
import InputField from "../../shared/components/form/input-field";
const LoginFormContent = ({ isSubmitting, isValid, submitCount, }) => {
    return (_jsxs(Form, { children: [_jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(InputField, { label: "Email", type: "email", name: "email", placeholder: "Enter e-mail" }), _jsx(Box, { children: _jsx(InputField, { label: "Password", type: "password", name: "password", placeholder: "Enter password" }) })] })), _jsxs(Flex, Object.assign({ direction: { base: "column-reverse", md: "row" }, mt: "3", align: "center", justify: "space-between", ml: "3" }, { children: [_jsx(Box, Object.assign({ display: "inline-block", as: "a", href: routesPaths.RESET_PASSWORD, color: "green.500", fontWeight: "bold", fontSize: "sm" }, { children: "Forgot password?" })), _jsx(Button, Object.assign({ mr: "3", size: "lg", mb: { base: "4", md: "0" }, w: { base: "full", md: "auto" }, type: "submit", isLoading: isSubmitting, disabled: submitCount > 0 && !isValid }, { children: "Sign in" }))] })), _jsxs(Flex, Object.assign({ ml: "3", mt: "3" }, { children: [_jsx(Text, Object.assign({ fontSize: "sm", color: "gray.600" }, { children: "New user?" })), _jsx(Box, Object.assign({ display: "inline-block", as: "a", href: routesPaths.REGISTER, color: "green.500", fontWeight: "bold", fontSize: "sm", ml: "1" }, { children: "Create an Account" }))] }))] }));
};
export default LoginFormContent;
