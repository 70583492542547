import { useMemo } from "react";
import { StepType } from "../steps/UploadFlow";
export const useRsiInitialStep = (initialStep) => {
    const steps = [
        "uploadStep",
        "matchColumnsStep",
        "validationStep",
        "summaryStep",
    ];
    const initialStepNumber = useMemo(() => {
        switch (initialStep) {
            case StepType.upload:
                return 0;
            case StepType.matchColumns:
                return 1;
            case StepType.validateData:
                return 2;
            case StepType.summary:
                return 3;
            default:
                return 0;
        }
    }, [initialStep]);
    return { steps, initialStep: initialStepNumber };
};
