import { useQuery } from "@apollo/client";
import { GetTemplateFoldersDocument, } from "@packages/apollo-hooks";
import { useMemo } from "react";
const useFoldersOptions = () => {
    const { data, loading } = useQuery(GetTemplateFoldersDocument, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    const foldersOptions = useMemo(() => {
        const foldersArray = (data === null || data === void 0 ? void 0 : data.getTemplateFolders.items)
            ? data.getTemplateFolders.items.map(folder => ({
                label: folder.name,
                value: folder.id,
            }))
            : null;
        if (foldersArray) {
            return [{ label: "Select Folder", value: 0 }, ...foldersArray];
        }
        else {
            return [{ label: "Select Folder", value: 0 }];
        }
    }, [data]);
    return { foldersOptions, loading };
};
export default useFoldersOptions;
