var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { differenceInMinutes } from "date-fns";
import AppEventTarget from "../../../common/events/app-event-target";
import LogoutRequestEvent from "../../../common/events/logout-request-event";
import { getUserToken, setUserToken, } from "../../../common/helpers/user-token";
import refreshToken from "./refresh-token";
const authLink = setContext((_, context) => __awaiter(void 0, void 0, void 0, function* () {
    const { headers, skipAuthentication = false, skipAutoTokenRefresh = false, } = context;
    if (skipAuthentication) {
        return context;
    }
    const userToken = getUserToken();
    if (userToken !== null && !skipAutoTokenRefresh) {
        const diff = differenceInMinutes(new Date(userToken.expiresAt), new Date());
        if (diff > 0 && diff < 30) {
            // token is still valid, but about to expire soon so new one is requested
            const newUserToken = yield refreshToken();
            if (newUserToken) {
                userToken.token = newUserToken.token;
                userToken.expiresAt = newUserToken.expiresAt;
                setUserToken(userToken);
            }
            else {
                setUserToken(null);
            }
        }
    }
    return Object.assign(Object.assign({}, context), { headers: Object.assign(Object.assign({}, headers), (userToken && { authorization: `Bearer ${userToken.token}` })) });
}));
// interface Exception {
//   response: string;
//   message: string;
//   status: number;
// }
const unauthorizedErrorLink = onError(({ graphQLErrors }) => {
    if (!graphQLErrors) {
        return;
    }
    for (const error of graphQLErrors) {
        console.error("gql error", error);
        if (error.message === "Unauthorized") {
            AppEventTarget.dispatchEvent(new LogoutRequestEvent({
                showToast: false,
                redirectPathname: window.location.pathname,
            }));
        }
        // const errException: Exception | null = error.extensions?.exception;
        // if (errException?.message === "Unauthorized") {
        //   AppEventTarget.dispatchEvent(
        //     new LogoutRequestEvent({
        //       showToast: false,
        //       redirectPathname: window.location.pathname,
        //     }),
        //   );
        // }
    }
});
const authFlowLink = authLink.concat(unauthorizedErrorLink);
export default authFlowLink;
