var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import client from "../../config/apollo/apollo";
import routesPaths from "../../modules/routing/route-paths";
import AppEventTarget, { appEventTypes } from "../events/app-event-target";
import { deleteUserToken, getUserToken } from "../helpers/user-token";
const useLogoutRequestHandler = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const handler = ({ detail: { showToast } }) => __awaiter(void 0, void 0, void 0, function* () {
            yield client.clearStore();
            const token = getUserToken();
            if (token) {
                deleteUserToken();
            }
            const target = routesPaths.LOGIN;
            if (showToast) {
                navigate(target);
                // navigate(target, {
                //   replace: false,
                //   state: {
                //     xxx: true,
                //   },
                // });
            }
            else {
                navigate(target);
            }
        });
        AppEventTarget.addEventListener(appEventTypes.LOGOUT_REQUEST, {
            handleEvent: handler,
        });
        return () => {
            AppEventTarget.removeEventListener(appEventTypes.LOGOUT_REQUEST, {
                handleEvent: handler,
            });
        };
    }, [navigate]);
};
export default useLogoutRequestHandler;
