var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Heading, ModalBody, Switch } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import DataGrid from "react-data-grid";
import { IoTrashBinOutline } from "react-icons/io5";
import { SubmitDataAlert } from "../../components/Alerts/SubmitDataAlert";
import { ContinueButton } from "../../components/ContinueButton";
import { useRsi } from "../../hooks/useRsi";
import { generateColumns } from "./components/columns";
import { addErrorsAndRunHooks } from "./utils/dataMutations";
export const ValidationStep = ({ initialData, file, onContinue: onContinueFromProps, onGoBack, }) => {
    const { translations, fields, rowHook, tableHook } = useRsi();
    const [data, setData] = useState(useMemo(() => addErrorsAndRunHooks(initialData, fields, rowHook, tableHook), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []));
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [filterByErrors, setFilterByErrors] = useState(false);
    const [showSubmitAlert, setShowSubmitAlert] = useState(false);
    const updateData = useCallback((rows) => {
        setData(addErrorsAndRunHooks(rows, fields, rowHook, tableHook));
    }, [setData, rowHook, tableHook, fields]);
    const deleteSelectedRows = () => {
        if (selectedRows.size) {
            const newData = data.filter(value => !selectedRows.has(value.__index));
            updateData(newData);
            setSelectedRows(new Set());
        }
    };
    const updateRow = useCallback((rows, changedData) => {
        const changes = changedData === null || changedData === void 0 ? void 0 : changedData.indexes.reduce((acc, index) => {
            // when data is filtered val !== actual index in data
            const realIndex = data.findIndex(value => value.__index === rows[index].__index);
            acc[realIndex] = rows[index];
            return acc;
        }, {});
        const newData = Object.assign([], data, changes);
        updateData(newData);
    }, [data, updateData]);
    const columns = useMemo(() => generateColumns(fields), [fields]);
    const tableData = useMemo(() => {
        if (filterByErrors) {
            return data.filter(value => {
                var _a;
                if (value === null || value === void 0 ? void 0 : value.__errors) {
                    return (_a = Object.values(value.__errors)) === null || _a === void 0 ? void 0 : _a.filter(err => err.level === "error").length;
                }
                return false;
            });
        }
        return data;
    }, [data, filterByErrors]);
    const rowKeyGetter = useCallback((row) => row.__index, []);
    const submitData = () => __awaiter(void 0, void 0, void 0, function* () {
        const calculatedData = data.reduce((acc, value) => {
            const { __index, __errors } = value, values = __rest(value, ["__index", "__errors"]);
            if (__errors) {
                for (const key in __errors) {
                    if (__errors[key].level === "error") {
                        if (__errors[key].isFirst) {
                            acc.validData.push(values);
                            return acc;
                        }
                        else {
                            acc.invalidData.push(values);
                            return acc;
                        }
                    }
                }
            }
            acc.validData.push(values);
            return acc;
        }, { validData: [], invalidData: [], all: data });
        // onSubmit(calculatedData, file);
        onContinueFromProps(calculatedData.validData);
        setShowSubmitAlert(false);
        // onClose();
    });
    const onContinue = () => {
        const invalidData = data.find(value => {
            var _a;
            if (value === null || value === void 0 ? void 0 : value.__errors) {
                return !!((_a = Object.values(value.__errors)) === null || _a === void 0 ? void 0 : _a.filter(err => err.level === "error").length);
            }
            return false;
        });
        if (!invalidData) {
            submitData();
        }
        else {
            setShowSubmitAlert(true);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(SubmitDataAlert, { isOpen: showSubmitAlert, onClose: () => setShowSubmitAlert(false), onConfirm: submitData }), _jsxs(ModalBody, Object.assign({ p: 8, bg: "gray.50" }, { children: [_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "2rem", flexWrap: "wrap", gap: "8px" }, { children: [_jsx(Heading, { children: translations.validationStep.title }), _jsxs(Box, Object.assign({ display: "flex", gap: "16px", alignItems: "center", flexWrap: "wrap" }, { children: [_jsx(Button, Object.assign({ variant: "solid", leftIcon: _jsx(IoTrashBinOutline, {}), size: "sm", colorScheme: "red", isDisabled: selectedRows.size === 0, onClick: deleteSelectedRows }, { children: translations.validationStep.discardButtonTitle })), _jsx(Switch, Object.assign({ display: "flex", colorScheme: "green", alignItems: "center", isChecked: filterByErrors, onChange: () => setFilterByErrors(!filterByErrors) }, { children: translations.validationStep.filterSwitchTitle }))] }))] })), _jsx(Box, { children: _jsx(DataGrid, { style: {
                                height: "367px",
                            }, enableVirtualization: false, headerRowHeight: 52, rowHeight: 52, className: "rdg-light", rowKeyGetter: rowKeyGetter, rows: tableData, onRowsChange: updateRow, columns: columns, selectedRows: selectedRows, onSelectedRowsChange: setSelectedRows, renderers: {
                                noRowsFallback: (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center", gridColumn: "1/-1", mt: "32px" }, { children: filterByErrors
                                        ? translations.validationStep.noRowsMessageWhenFiltered
                                        : translations.validationStep.noRowsMessage }))),
                            } }) })] })), _jsx(ContinueButton, { isDisabled: tableData.length < 1, onContinue: onContinue, title: translations.validationStep.nextButtonTitle, onGoBack: onGoBack })] }));
};
