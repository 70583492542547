import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Text } from "@chakra-ui/react";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import routesPaths from "../../../routing/route-paths";
import { getPaymentTabNames } from "../../../settings/settings-content";
import Banner from "../../components/banner";
const SubscriptionBanner = ({ trialRemainingDays, }) => {
    return (_jsxs(Banner, Object.assign({ position: "fixed", top: "0", left: "285px", width: "calc(100% - 285px)", zIndex: 100 }, { children: [trialRemainingDays > 0 ? (_jsxs(_Fragment, { children: ["Your trial will end in", " ", _jsxs("b", { children: [trialRemainingDays, " ", trialRemainingDays > 1 ? "days." : "day."] }), " ", "To continue using Motar.me after the end date please", " "] })) : (_jsxs(_Fragment, { children: [_jsx("b", { children: "Your free trial is coming to an end." }), " To continue using Motar.me please", " "] })), _jsx(Link, Object.assign({ to: generatePath(routesPaths.SETTINGS_BY_TAB, {
                    tab: getPaymentTabNames(false)[1],
                }) }, { children: _jsx(Text, Object.assign({ as: "span", fontWeight: "bold", textDecoration: "underline" }, { children: "subscribe" })) }))] })));
};
export default SubscriptionBanner;
