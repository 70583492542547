import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Stack, } from "@chakra-ui/react";
import { DATE_FORMAT, TIME_FORMAT } from "@packages/helpers";
import { Editor } from "@tinymce/tinymce-react";
import { format } from "date-fns";
import { IoCalendarOutline, IoMailOutline, IoTimeOutline, } from "react-icons/io5";
import CustomDrawerCloseButton from "../custom-drawer-close-button";
import DetailWithIcon from "../detail-with-icon";
const EmailPreview = ({ email }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ borderWidth: "1px", borderColor: "gray.200", borderRadius: "base", h: "116px", w: "116px", onClick: onOpen, overflow: "hidden" }, { children: _jsx(Box, { w: "200%", h: "200%", transform: "scale(0.5) translateX(-50%) translateY(-50%)", dangerouslySetInnerHTML: {
                        __html: email.body,
                    } }) })), _jsx(Drawer, Object.assign({ isOpen: isOpen, onClose: onClose, size: "lg" }, { children: _jsx(DrawerOverlay, { children: _jsxs(DrawerContent, Object.assign({ maxWidth: "calc(100vw - 320px)" }, { children: [_jsx(CustomDrawerCloseButton, {}), _jsx(DrawerHeader, { children: "E-mail Preview " }), _jsx(Divider, {}), _jsxs(Stack, Object.assign({ spacing: "14", direction: "row", p: "6", justifyContent: "space-between" }, { children: [_jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoMailOutline, {}), subtitle: "Subject" }, { children: email.subject })), _jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoCalendarOutline, {}), subtitle: "Date" }, { children: email.sendsAt
                                            ? format(new Date(email.sendsAt), DATE_FORMAT)
                                            : "" })), _jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoTimeOutline, {}), subtitle: "Time" }, { children: email.sendsAt
                                            ? format(new Date(email.sendsAt), TIME_FORMAT)
                                            : "x" }))] })), _jsx(DrawerBody, { children: _jsx(Editor, { apiKey: process.env.REACT_APP_TINY_KEY, value: email.body, disabled: true, init: {
                                        height: "calc(100vh - 172px)",
                                        plugins: "",
                                        menubar: "",
                                        toolbar: "",
                                    } }) })] })) }) }))] }));
};
export default EmailPreview;
