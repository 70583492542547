import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useRef, useState, } from "react";
import AmPmElements from "./am-pm-elements";
import NumberElements from "./number-elements";
import { Grid, Header, Title, Wrapper } from "./time.components";
const Time = ({ className, state, dispatch }) => {
    const [focusedColumn, setFocusedColumn] = useState("hours");
    const gridRef = useRef(null);
    const onWrapperClick = useCallback((event) => {
        var _a;
        if (((_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) &&
            gridRef.current !== event.target) {
            return;
        }
        setFocusedColumn(null);
    }, []);
    return (_jsxs(Wrapper, Object.assign({ onClick: onWrapperClick, className: className, "data-cy": "time" }, { children: [_jsx(Header, { children: _jsx(Title, { children: "Timepicker" }) }), _jsxs(Grid, Object.assign({ ref: gridRef }, { children: [_jsx(NumberElements, { dispatch: dispatch, focusedColumn: focusedColumn, formatString: "h", names: {
                            addAction: "addHour",
                            column: "hours",
                            setAction: "setHour",
                            subAction: "subHour",
                        }, setFocusedColumn: setFocusedColumn, state: state, valueRange: { min: 1, max: 12 }, type: "hour" }), _jsx("div", {}), _jsx("span", { children: ":" }), _jsx("div", {}), _jsx(NumberElements, { dispatch: dispatch, focusedColumn: focusedColumn, formatString: "mm", names: {
                            addAction: "addMinute",
                            column: "minutes",
                            setAction: "setMinute",
                            subAction: "subMinute",
                        }, setFocusedColumn: setFocusedColumn, state: state, valueRange: { min: 0, max: 59 }, type: "minute" }), _jsx(AmPmElements, { dispatch: dispatch, focusedColumn: focusedColumn, setFocusedColumn: setFocusedColumn, state: state })] }))] })));
};
export default Time;
