var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/react";
import { AddContactListDocument } from "@packages/apollo-hooks";
import { useCallback } from "react";
import { IoListOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useIsAdmin from "../../../../common/helpers/use-is-admin";
import routesPaths from "../../../routing/route-paths";
const CreateNewList = () => {
    const toast = useToast();
    const isAdmin = useIsAdmin();
    const navigate = useNavigate();
    const [addContactList] = useMutation(AddContactListDocument, {
        refetchQueries: ["getContactLists"],
    });
    const handleCreate = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield addContactList({
                variables: {
                    input: {
                        contactIds: [],
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.addContactList.__typename) {
                case "ContactListSuccessfulAddedResponse":
                    if (data) {
                        navigate(routesPaths.CONTACT_LIST.replace(":id", data.addContactList.id.toString()));
                    }
                    return;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [addContactList, navigate, toast]);
    return (_jsx(_Fragment, { children: !isAdmin && (_jsx(Button, Object.assign({ colorScheme: "green", variant: "outline", leftIcon: _jsx(IoListOutline, {}), fontSize: "sm", onClick: handleCreate }, { children: "Create New List" }))) }));
};
export default CreateNewList;
