import { appEventTypes } from "./app-event-target";
class LogoutRequestEvent extends CustomEvent {
    constructor(settings) {
        var _a;
        super(appEventTypes.LOGOUT_REQUEST, {
            detail: {
                showToast: (_a = settings === null || settings === void 0 ? void 0 : settings.showToast) !== null && _a !== void 0 ? _a : false,
                redirectPathname: settings === null || settings === void 0 ? void 0 : settings.redirectPathname,
            },
        });
    }
}
export default LogoutRequestEvent;
