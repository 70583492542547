import { css } from "@emotion/react";
import styled from "@emotion/styled";
import theme from "../../../../../../../../common/theme";
import BaseGridElement from "../shared/base-grid-element";
export const Month = styled(BaseGridElement) `
  padding: 4px 8px;

  ${p => p.monthInfo.isCurrentMonth &&
    css `
      background: ${theme.colors.cyan[50]};
    `}
`;
