var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Progress, useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useRsi } from "../hooks/useRsi";
import { exceedsMaxRecords } from "../utils/exceedsMaxRecords";
import { mapWorkbook } from "../utils/mapWorkbook";
import { MatchColumnsStep } from "./MatchColumnsStep/MatchColumnsStep";
import { UploadStep } from "./UploadStep/UploadStep";
import { ValidationStep } from "./ValidationStep/ValidationStep";
import { SummaryStep } from "./summary-step/summary-step";
export var StepType;
(function (StepType) {
    StepType["upload"] = "upload";
    StepType["matchColumns"] = "matchColumns";
    StepType["validateData"] = "validateData";
    StepType["summary"] = "summary";
})(StepType || (StepType = {}));
export const UploadFlow = ({ nextStep, previousStep }) => {
    const { initialStepState } = useRsi();
    const [state, setState] = useState(initialStepState || {
        type: StepType.upload,
        data: [],
        headerValues: [],
        uploadData: [],
    });
    const [uploadedFile, setUploadedFile] = useState(null);
    const { maxRecords, translations, uploadStepHook, matchColumnsStepHook } = useRsi();
    const toast = useToast();
    const errorToast = useCallback((description) => {
        toast({
            status: "error",
            variant: "left-accent",
            position: "bottom-left",
            title: `${translations.alerts.toast.error}`,
            description,
            isClosable: true,
        });
    }, [toast, translations]);
    switch (state.type) {
        case StepType.upload:
            return (_jsx(UploadStep, { onContinue: (workbook, file) => __awaiter(void 0, void 0, void 0, function* () {
                    setUploadedFile(file);
                    const isSingleSheet = workbook.SheetNames.length === 1;
                    if (isSingleSheet) {
                        if (maxRecords &&
                            exceedsMaxRecords(workbook.Sheets[workbook.SheetNames[0]], maxRecords)) {
                            errorToast(translations.uploadStep.maxRecordsExceeded(maxRecords.toString()));
                            return;
                        }
                        try {
                            const mappedWorkbook = yield uploadStepHook(mapWorkbook(workbook));
                            const selectedRowIndex = 0;
                            // We consider data above heder to be redundant
                            const headerValues = mappedWorkbook[selectedRowIndex];
                            const trimmedData = mappedWorkbook.slice(selectedRowIndex + 1);
                            setState({
                                type: StepType.matchColumns,
                                uploadData: trimmedData,
                                headerValues,
                                data: [],
                            });
                            nextStep();
                        }
                        catch (e) {
                            errorToast(e.message);
                        }
                    }
                    else {
                        const firstSheetName = workbook.SheetNames[0];
                        if (maxRecords &&
                            exceedsMaxRecords(workbook.Sheets[firstSheetName], maxRecords)) {
                            errorToast(translations.uploadStep.maxRecordsExceeded(maxRecords.toString()));
                            return;
                        }
                        try {
                            const mappedWorkbook = yield uploadStepHook(mapWorkbook(workbook, firstSheetName));
                            const selectedRowIndex = 0;
                            // We consider data above heder to be redundant
                            const headerValues = mappedWorkbook[selectedRowIndex];
                            const trimmedData = mappedWorkbook.slice(selectedRowIndex + 1);
                            // await onContinue(data[selectedRowIndex], trimmedData);
                            setState({
                                type: StepType.matchColumns,
                                uploadData: trimmedData,
                                headerValues,
                                data: [],
                            });
                            nextStep();
                        }
                        catch (e) {
                            errorToast(e.message);
                        }
                    }
                }) }));
        case StepType.matchColumns:
            return (_jsx(MatchColumnsStep, { data: state.uploadData, headerValues: state.headerValues, onContinue: (values, rawData, columns) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const data = yield matchColumnsStepHook(values, rawData, columns);
                        setState(p => (Object.assign(Object.assign({}, p), { type: StepType.validateData, data, uploadData: p.uploadData })));
                        nextStep();
                    }
                    catch (e) {
                        errorToast(e.message);
                    }
                }), onGoBack: () => {
                    setState({
                        type: StepType.upload,
                        data: [],
                        headerValues: [],
                        uploadData: [],
                    });
                    previousStep();
                } }));
        case StepType.validateData:
            return (_jsx(ValidationStep, { initialData: state.data, file: uploadedFile, onContinue: (validData) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        setState(p => (Object.assign(Object.assign({}, p), { type: StepType.summary, validData: validData })));
                        nextStep();
                    }
                    catch (e) {
                        errorToast(e.message);
                    }
                }), onGoBack: () => {
                    setState(p => (Object.assign(Object.assign({}, p), { type: StepType.matchColumns, validData: [] })));
                    previousStep();
                } }));
        case StepType.summary:
            return (_jsx(SummaryStep, { validData: state.validData, onGoBack: () => {
                    setState(p => (Object.assign(Object.assign({}, p), { type: StepType.validateData })));
                    previousStep();
                } }));
        default:
            return _jsx(Progress, { isIndeterminate: true });
    }
};
