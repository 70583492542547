import { ColumnType } from "../MatchColumnsStep";
import { uniqueEntries } from "./uniqueEntries";
export const setColumn = (oldColumn, field, data) => {
    switch (field === null || field === void 0 ? void 0 : field.fieldType.type) {
        case "select":
            return Object.assign(Object.assign({}, oldColumn), { type: ColumnType.matchedSelect, value: field.key, matchedOptions: uniqueEntries(data || [], oldColumn.index) });
        case "checkbox":
            return {
                index: oldColumn.index,
                type: ColumnType.matchedCheckbox,
                value: field.key,
                header: oldColumn.header,
            };
        case "input":
            return {
                index: oldColumn.index,
                type: ColumnType.matched,
                value: field.key,
                header: oldColumn.header,
            };
        default:
            return {
                index: oldColumn.index,
                header: oldColumn.header,
                type: ColumnType.empty,
            };
    }
};
