import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react";
import { Form } from "formik";
import React from "react";
import InputField from "../../shared/components/form/input-field";
import SelectField from "../../shared/components/form/select-field";
import useFoldersOptions from "./use-folders-options";
const SaveTemplateFormContent = ({ isSubmitting, isValid, submitCount, onClose, cancelRef, }) => {
    const { foldersOptions, loading } = useFoldersOptions();
    return (_jsxs(Form, { children: [_jsxs(AlertDialogBody, { children: [_jsx(InputField, { placeholder: "Enter template name", label: "Name", type: "text", name: "name" }), !loading && (_jsx(SelectField, { label: "Save In Folder", name: "templateFolder", options: foldersOptions }))] }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", mr: 3, onClick: onClose, ref: cancelRef.current }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "submit", isLoading: isSubmitting, disabled: submitCount > 0 && !isValid, colorScheme: "green" }, { children: "Save Template" }))] })] }));
};
export default SaveTemplateFormContent;
