var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useMutation } from "@apollo/client";
import { Select, Table, Tbody, Td, Th, Thead, Tr, useToast, } from "@chakra-ui/react";
import { CampaignResponseStatuses, EditCampaignResponseDocument, } from "@packages/apollo-hooks";
import { useCallback, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import EmptyState from "../../../../../../shared/components/table/empty-state";
import { getResponseBackgroundColor, getResponseColor, } from "../../../../../helpers/responses-colors";
import ChangeCampaignResponseAlert from "./change-campaign-response-alert";
const getStatusOptions = [
    {
        value: undefined,
        label: "",
    },
    {
        value: CampaignResponseStatuses.Bounced,
        label: "Bounced",
    },
    {
        value: CampaignResponseStatuses.Negative,
        label: "Negative",
    },
    {
        value: CampaignResponseStatuses.Neutral,
        label: "Neutral",
    },
    {
        value: CampaignResponseStatuses.Other,
        label: "Other",
    },
    {
        value: CampaignResponseStatuses.Positive,
        label: "Positive",
    },
    {
        value: CampaignResponseStatuses.ColleagueNegative,
        label: "Colleague Negative",
    },
    {
        value: CampaignResponseStatuses.ColleagueNeutral,
        label: "Colleague Neutral",
    },
    {
        value: CampaignResponseStatuses.ColleaguePositive,
        label: "Colleague Positive",
    },
    {
        value: CampaignResponseStatuses.Unsubscribed,
        label: "Unsubscribed",
    },
];
const ResponsesTableContent = ({ tableData }) => {
    const [editCampaignResponse] = useMutation(EditCampaignResponseDocument, {
        refetchQueries: [
            "getCampaignResponses",
            "getCampaignOverviewResponses",
            "getCampaignStatistics",
            "getCampaignEmailStatistics",
        ],
    });
    const toast = useToast();
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(null);
    const onClose = () => setIsAlertDialogOpen(null);
    const handleStatusChange = useCallback((status, responseId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield editCampaignResponse({
                variables: {
                    input: {
                        id: responseId,
                        status: status,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.editCampaignResponse.__typename) {
                case "EditCampaignResponseSuccessfulResponse":
                    toast({
                        title: "Campaign response changed",
                        status: "success",
                        isClosable: true,
                    });
                    return;
                case "CampaignResponseNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign response doesn't exist",
                        status: "error",
                        isClosable: true,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    console.error("err");
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [editCampaignResponse, toast]);
    const columns = useMemo(() => {
        return [
            {
                Header: "First Name",
                accessor: original => original.firstName,
            },
            {
                Header: "Last Name",
                accessor: original => original.lastName,
            },
            {
                Header: "E-mail Address",
                accessor: original => original.email,
            },
            {
                Header: "Lead Company",
                accessor: original => original.leadCompany,
            },
            {
                Header: "Job Title",
                accessor: original => original.jobTitle,
            },
            {
                Header: "Country",
                accessor: original => original.country,
            },
            {
                Header: "Open count",
                accessor: "openCount",
            },
        ];
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData.getCampaignResponses.items }, useSortBy);
    return (_jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }),
                    headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex }), column.render("Header")))),
                    _jsx(Th, {})))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }),
                            row.cells.map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex, bg: getResponseBackgroundColor(row.original.status
                                    ? row.original.status
                                    : CampaignResponseStatuses.Other) }), cell.render("Cell")))),
                            _jsx(Td, Object.assign({ bg: getResponseBackgroundColor(row.original.status
                                    ? row.original.status
                                    : CampaignResponseStatuses.Other) }, { children: _jsx(Select, Object.assign({ w: "124px", rounded: "base", size: "sm", variant: "filled", value: row.original.status || "", backgroundColor: getResponseColor(row.original.status
                                        ? row.original.status
                                        : CampaignResponseStatuses.Other), onChange: e => {
                                        const status = e.target.value === ""
                                            ? null
                                            : e.target.value;
                                        if (row.original.status === "UNSUBSCRIBED") {
                                            setIsAlertDialogOpen({
                                                newStatus: status,
                                                responseId: row.original.id,
                                            });
                                        }
                                        else {
                                            handleStatusChange(status, row.original.id);
                                        }
                                    } }, { children: getStatusOptions.map((o, index) => (_jsx("option", Object.assign({ value: o.value }, { children: o.label }), index))) })) }))));
                    }), _jsx(ChangeCampaignResponseAlert, { onClose: onClose, isOpen: isAlertDialogOpen !== null, onSubmit: () => {
                            if (isAlertDialogOpen !== null) {
                                handleStatusChange(isAlertDialogOpen.newStatus, isAlertDialogOpen.responseId);
                            }
                        }, newStatus: isAlertDialogOpen
                            ? isAlertDialogOpen.newStatus
                            : CampaignResponseStatuses.Unsubscribed }), rows.length === 0 && _jsx(EmptyState, { type: "responses", columnCount: 9 })] }))] })));
};
export default ResponsesTableContent;
