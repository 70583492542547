import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { IconButton, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Th, Thead, Tr, } from "@chakra-ui/react";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import { useMemo } from "react";
import { IoEllipsisVerticalOutline, IoPencilSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import useIsAdmin from "../../../common/helpers/use-is-admin";
import routesPaths from "../../routing/route-paths";
import EmptyState from "../../shared/components/table/empty-state";
import DeleteContactListDialog from "./delete-contact-list-dialog";
const ContactListsTable = ({ tableData, setPageNumber, }) => {
    const isAdmin = useIsAdmin();
    const columns = useMemo(() => [
        {
            Header: "List Name",
            accessor: "name",
        },
        {
            Header: "Number of contacts",
            accessor: "contactCount",
        },
        {
            Header: "Created",
            accessor: original => format(new Date(original.createdAt), DATE_FORMAT),
        },
    ], []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData.getContactLists.items }, useSortBy);
    const navigate = useNavigate();
    return (_jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }),
                    headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex }), column.render("Header")))),
                    _jsx(Th, {})))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }),
                            row.cells.map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex, onClick: () => {
                                    if (cellIndex !== row.cells.length) {
                                        navigate(routesPaths.CONTACT_LIST.replace(":id", row.original.id));
                                    }
                                } }), cell.render("Cell")))),
                            _jsx(Td, Object.assign({ textAlign: "right" }, { children: _jsxs(Menu, { children: [_jsx(MenuButton, { as: IconButton, size: "sm", "aria-label": "Options", icon: _jsx(IoEllipsisVerticalOutline, {}), variant: "outline", colorScheme: "gray" }), _jsxs(MenuList, { children: [_jsx(MenuItem, Object.assign({ icon: _jsx(IoPencilSharp, {}), onClick: () => navigate(routesPaths.CONTACT_LIST.replace(":id", row.original.id)) }, { children: "Edit" })), !isAdmin && (_jsx(DeleteContactListDialog, { setPageNumber: setPageNumber, name: row.original.name, contactListId: row.original.id }))] })] }) }))));
                    }), rows.length === 0 && _jsx(EmptyState, { type: "contacts", columnCount: 5 })] }))] })));
};
export default ContactListsTable;
