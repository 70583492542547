var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/toast";
import { AddContactDocument } from "@packages/apollo-hooks";
import { Formik } from "formik";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import routesPaths from "../../../routing/route-paths";
import AddContactFormContent from "./add-contact-form-content";
import { addContactInitialValues, addContactValidationSchema, } from "./form";
const AddContactForm = ({ onClose, contactListId, }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [addContact] = useMutation(AddContactDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getContacts", "getContactLists"],
    });
    const handleSubmit = useCallback((values, { setFieldError }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield addContact({
                variables: {
                    input: {
                        leadCompany: values.company,
                        lastName: values.lastName,
                        firstName: values.firstName,
                        email: values.email,
                        jobTitle: values.jobTitle,
                        contactsListId: contactListId,
                        country: values.country,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.addContact.__typename) {
                case "ContactSuccessfulAddedResponse":
                    onClose();
                    return;
                case "ContactListNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Contact list not exist",
                        status: "error",
                        isClosable: false,
                    });
                    navigate(routesPaths.CONTACT_LISTS);
                    break;
                case "ContactEmailTakenResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Contact email taken",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [addContact, contactListId, onClose, toast, navigate]);
    return (_jsx(Formik, Object.assign({ initialValues: addContactInitialValues, validationSchema: addContactValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(AddContactFormContent, Object.assign({ onClose: onClose }, formProps)) })));
};
export default AddContactForm;
