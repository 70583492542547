import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routesPaths from "../routing/route-paths";
import Loader from "../shared/components/loader";
const MicrosoftIntegration = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    useEffect(() => {
        if (search && search === "?microsoftIntegrationSuccessful=true") {
            navigate(routesPaths.SETTINGS);
            toast({
                title: `Microsoft account connected successfully`,
                status: "success",
                isClosable: true,
            });
        }
    }, [navigate, search, toast]);
    return _jsx(Loader, {});
};
export default MicrosoftIntegration;
