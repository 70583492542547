import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Text, Box, Stack } from "@chakra-ui/react";
import { useRsi } from "../../../hooks/useRsi";
import { ColumnType } from "../MatchColumnsStep";
import { MatchColumnSelect } from "../../../components/Selects/MatchColumnSelect";
import MatchedIcon from "../icons/match-icon";
import NotMatchedIcon from "../icons/not-matched-icon";
export const TemplateColumn = ({ column, onChange, }) => {
    const { translations, fields } = useRsi();
    const isIgnored = column.type === ColumnType.ignored;
    const isChecked = column.type === ColumnType.matched ||
        column.type === ColumnType.matchedCheckbox ||
        column.type === ColumnType.matchedSelectOptions;
    const selectOptions = fields.map(({ label, key }) => ({ value: key, label }));
    const selectValue = selectOptions.find(({ value }) => "value" in column && column.value === value);
    return (_jsx(Flex, Object.assign({ background: "#E2E8F0", minH: 10, w: "100%", padding: "2", flexDir: "column", justifyContent: "center" }, { children: isIgnored ? (_jsx(Text, { children: translations.matchColumnsStep.ignoredColumnText })) : (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ alignItems: "start", minH: 10, w: "100%", direction: "column" }, { children: [_jsx(Box, Object.assign({ flex: 1 }, { children: _jsx(MatchColumnSelect, { placeholder: translations.matchColumnsStep.selectPlaceholder, value: selectValue, onChange: value => onChange(value === null || value === void 0 ? void 0 : value.value, column.index), options: selectOptions, name: column.header }) })), _jsxs(Stack, Object.assign({ direction: "row", pl: "4" }, { children: [isChecked ? _jsx(MatchedIcon, {}) : _jsx(NotMatchedIcon, {}), _jsx(Text, Object.assign({ fontSize: "xs" }, { children: isChecked ? "Matched" : "Not matched" }))] }))] })) })) })));
};
