const menu = {
    parts: ["item"],
    baseStyle: {
        item: {
            fontSize: "md",
            color: "gray.500",
            svg: {
                "&.chakra-menu__icon": {
                    width: "20px !important",
                    height: "20px !important",
                    color: "gray.400",
                },
            },
        },
    },
};
export default menu;
