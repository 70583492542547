var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Input, InputGroup, InputRightElement, } from "@chakra-ui/react";
import { format, getHours, getMilliseconds, getMinutes, getSeconds, isEqual, setHours, setMilliseconds, setMinutes, setSeconds, startOfDay, } from "date-fns";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState, } from "react";
import useOnClickOutside from "../../../../../../common/helpers/use-on-click-outside";
import calendarReducer from "../shared/calendar/calendar-reducer";
import { validateInitialValue } from "../shared/helpers";
import { ReactComponent as CalendarIcon } from "../shared/icons/calendar.svg";
import timeReducer from "../shared/time/time-reducer";
import { PickerWrapper, StyledCalendar, StyledTime, Wrapper, } from "./date-time-picker.components";
const mergeDates = (date, time) => {
    return setHours(setMinutes(setSeconds(setMilliseconds(date, getMilliseconds(time)), getSeconds(time)), getMinutes(time)), getHours(time));
};
const DateTimePicker = (_a) => {
    var { formatString, value, minDate, maxDate, timePickerDirection = "right", onChange, onBlur } = _a, inputProps = __rest(_a, ["formatString", "value", "minDate", "maxDate", "timePickerDirection", "onChange", "onBlur"]);
    const wrapperRef = useRef(null);
    const [opened, setOpened] = useState(false);
    const [selectedDate, setSelectedDate] = useState(undefined);
    const [timeState, timeDispatch] = useReducer(timeReducer, {
        currentDate: validateInitialValue(value),
        touched: false,
    });
    const [calendarState, calendarDispatch] = useReducer(calendarReducer(false), {
        displayDate: validateInitialValue(value),
        displayLevel: "days",
        selection: undefined,
    });
    useEffect(() => {
        if (!(calendarState.selection instanceof Date)) {
            setSelectedDate(undefined);
            return;
        }
        setSelectedDate(mergeDates(calendarState.selection, timeState.currentDate));
    }, [timeState.currentDate, calendarState.selection]);
    const selectedDateRef = useRef(selectedDate);
    useEffect(() => {
        if (!selectedDate ||
            (selectedDateRef.current &&
                selectedDate &&
                isEqual(selectedDateRef.current, selectedDate))) {
            return;
        }
        selectedDateRef.current = selectedDate;
        onChange === null || onChange === void 0 ? void 0 : onChange(selectedDate);
    }, [selectedDate, onChange]);
    useEffect(() => {
        calendarDispatch({ type: "setSelection", selection: value });
        if (value instanceof Date) {
            timeDispatch({ type: "setTime", time: value });
        }
    }, [value]);
    useEffect(() => {
        const newValue = minDate ? startOfDay(minDate) : undefined;
        calendarDispatch({ type: "setMinDate", minDate: newValue });
    }, [minDate]);
    useEffect(() => {
        const newValue = maxDate ? startOfDay(maxDate) : undefined;
        calendarDispatch({ type: "setMaxDate", maxDate: newValue });
    }, [maxDate]);
    const showPicker = useCallback(() => {
        setOpened(true);
    }, []);
    const hidePicker = useCallback(() => {
        setOpened(false);
        calendarDispatch({ type: "resetView" });
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    }, [onBlur, calendarDispatch]);
    useOnClickOutside(wrapperRef, () => opened && hidePicker());
    const globalEventHandler = useCallback((event) => {
        var _a;
        if ((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
            return;
        }
        hidePicker();
    }, [hidePicker, wrapperRef]);
    useEffect(() => {
        if (!opened) {
            return;
        }
        document.addEventListener("click", globalEventHandler, { capture: true });
        return () => {
            document.removeEventListener("click", globalEventHandler, {
                capture: true,
            });
        };
    }, [opened, globalEventHandler]);
    const formattedValue = useMemo(() => {
        if (!(selectedDate instanceof Date)) {
            return "";
        }
        if (formatString) {
            return format(selectedDate, formatString);
        }
        else {
            return selectedDate.toLocaleString();
        }
    }, [selectedDate, formatString]);
    return (_jsxs(_Fragment, { children: [opened && (_jsx(Box, { position: "absolute", left: "0", top: "90px", width: "100%", height: "calc(100vh - 300px)", zIndex: "popover" })), _jsxs(Wrapper, Object.assign({ ref: wrapperRef }, { children: [_jsxs(InputGroup, { children: [_jsx(Input, Object.assign({}, inputProps, { onFocus: showPicker, readOnly: true, value: formattedValue })), _jsx(InputRightElement, Object.assign({ onClick: () => {
                                    if (!inputProps.isDisabled) {
                                        showPicker();
                                    }
                                }, color: "gray.500", mt: inputProps.mt ? inputProps.mt : 0, width: "48px" }, { children: _jsx(CalendarIcon, {}) }))] }), _jsx(PickerWrapper, Object.assign({ timePickerDirection: timePickerDirection }, { children: opened && (_jsxs(_Fragment, { children: [_jsx(StyledCalendar, { state: calendarState, dispatch: calendarDispatch }), _jsx(StyledTime, { state: timeState, dispatch: timeDispatch, direction: timePickerDirection })] })) }))] }))] }));
};
export default DateTimePicker;
