var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast, } from "@chakra-ui/react";
import { CancelSubscriptionDocument, } from "@packages/apollo-hooks";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
const CancelSubscriptionModal = ({ nextPaymentDate, }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const toast = useToast();
    const [cancelSubscription] = useMutation(CancelSubscriptionDocument, {
        refetchQueries: ["currentUser"],
    });
    const handleCancelSubscription = () => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield cancelSubscription();
        onClose();
        switch (data === null || data === void 0 ? void 0 : data.cancelSubscription.__typename) {
            case "CancelSubscriptionSuccessfulResponse":
                toast({
                    title: "Your subscription has been cancelled successfully",
                    status: "success",
                });
                break;
            case "CancelSubscriptionAlreadyCancelledResponse":
                toast({
                    title: "Your subscription is already cancelled",
                    status: "info",
                });
                break;
            default:
                toast({
                    title: "Something went wrong",
                    status: "error",
                });
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "outline", alignSelf: "end", onClick: onOpen }, { children: "Cancel Subscription" })), _jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, size: "md" }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Are you sure?" }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: _jsx(Text, { children: `Your subscription will remain active until ${format(nextPaymentDate, DATE_FORMAT)}` }) }), _jsxs(ModalFooter, { children: [_jsx(Button, Object.assign({ colorScheme: "gray", variant: "outline", mr: 3, onClick: onClose }, { children: "Go back" })), _jsx(Button, Object.assign({ size: "md", variant: "solid", colorScheme: "red", onClick: handleCancelSubscription }, { children: "Cancel Subscription" }))] })] })] }))] }));
};
export default CancelSubscriptionModal;
