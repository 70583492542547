var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, MenuItem, useToast, } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { IoTrash } from "react-icons/io5";
import { DeleteCampaignDocument } from "@packages/apollo-hooks";
const DeleteCampaignDialog = ({ campaignId, name, setPageNumber, }) => {
    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();
    const [deleteCampaign] = useMutation(DeleteCampaignDocument, {
        refetchQueries: ["getCampaigns"],
    });
    const handleDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield deleteCampaign({
                variables: {
                    input: { campaignId },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.deleteCampaign.__typename) {
                case "DeleteCampaignSuccessfulResponse":
                    setPageNumber(0);
                    return;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [deleteCampaign, campaignId, setPageNumber, toast]);
    return (_jsxs(_Fragment, { children: [_jsx(MenuItem, Object.assign({ icon: _jsx(IoTrash, {}), onClick: () => setIsOpen(true) }, { children: "Delete" })), _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Delete Campaign" })), _jsxs(AlertDialogBody, { children: ["Do you want to delete ", name, "?"] }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", colorScheme: "gray", ref: cancelRef.current, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ colorScheme: "red", variant: "solid", onClick: handleDelete, ml: 3 }, { children: "Delete" }))] })] }) }) }))] }));
};
export default DeleteCampaignDialog;
