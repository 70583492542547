import * as Yup from "yup";
export const editUserInitialValues = (user) => {
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        hasDirectPayment: user.hasDirectPayment,
    };
};
export const editUserValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    hasDirectPayment: Yup.bool(),
});
