var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Button, Link, Stack, TabPanel, Text, } from "@chakra-ui/react";
import { CurrentUserDocument, GetBillingDataDocument, } from "@packages/apollo-hooks";
import * as React from "react";
import Loader from "../../../shared/components/loader/loader";
import TextInfo from "../../../shared/components/text-info";
const BillingDataHeader = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(Text, Object.assign({ fontSize: "sm", fontWeight: "bold" }, props, { children: children })));
};
const BillingDataContent = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(Text, Object.assign({ color: "gray.500" }, props, { children: children })));
};
const BillingDataPanel = props => {
    const { data, loading } = useQuery(GetBillingDataDocument);
    const { data: userData } = useQuery(CurrentUserDocument);
    const url = `${process.env.REACT_APP_API_URL}/subscription/edit-billing-data?stripeCustomerId=${userData === null || userData === void 0 ? void 0 : userData.currentUser.stripeCustomerId}`;
    const billingData = data && data.getBillingData && data.getBillingData.billingData;
    if (loading) {
        return (_jsx(TabPanel, Object.assign({}, props, { children: _jsx(Loader, {}) })));
    }
    return (_jsx(TabPanel, Object.assign({}, props, { children: _jsxs(Stack, Object.assign({ spacing: "2" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold", mb: "16px" }, { children: "Billing data" })), billingData ? (_jsxs(Stack, Object.assign({ spacing: "5" }, { children: [_jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(BillingDataHeader, { children: "Name" }), _jsx(BillingDataContent, { children: billingData.name })] })), _jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(BillingDataHeader, { children: "Billing email" }), _jsx(BillingDataContent, { children: billingData.email })] })), _jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(BillingDataHeader, { children: "Country" }), _jsx(BillingDataContent, { children: billingData.country })] })), _jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(BillingDataHeader, { children: "Tax ID" }), _jsx(BillingDataContent, { children: billingData.taxId })] })), _jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(BillingDataHeader, { children: "City" }), _jsx(BillingDataContent, { children: billingData.city })] })), _jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(BillingDataHeader, { children: "Address" }), _jsxs(BillingDataContent, { children: [billingData.line1, " ", billingData.line2] })] })), _jsxs(Stack, Object.assign({ spacing: "0" }, { children: [_jsx(BillingDataHeader, { children: "Zip code" }), _jsx(BillingDataContent, { children: billingData.postal_code })] })), _jsx(Link, Object.assign({ href: url, _hover: {
                                textDecor: "none",
                            }, alignSelf: "end" }, { children: _jsx(Button, Object.assign({ variant: "solid" }, { children: "Edit" })) }))] }))) : (_jsx(TextInfo, { children: "Billing data will appear here after your first payment." }))] })) })));
};
export default BillingDataPanel;
