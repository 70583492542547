var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { UnsubscribeDocument, } from "@packages/apollo-hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
const Unsubscribe = () => {
    const { token } = useParams();
    const [message, setMessage] = useState("");
    const [unsubscribe] = useMutation(UnsubscribeDocument);
    const toast = useToast();
    useEffect(() => {
        function unsubscribeEmail() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield unsubscribe({
                        variables: {
                            input: {
                                token,
                            },
                        },
                    });
                    switch (data === null || data === void 0 ? void 0 : data.unsubscribe.__typename) {
                        case "UnsubscribeSuccessfulResponse":
                            setMessage("You have successfully unsubscribed from this mailing list.");
                            return;
                        case "UnsubscribeInvalidResponse":
                            setMessage("This link is no longer valid.");
                            console.error("Something went wrong");
                            break;
                        default:
                            toast({
                                title: "Something went wrong",
                                status: "error",
                                isClosable: false,
                            });
                            break;
                    }
                }
                catch (e) {
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    console.error(e);
                }
            });
        }
        if (!message) {
            unsubscribeEmail();
        }
    }, [message, toast, token, unsubscribe]);
    return _jsx("div", { children: message });
};
export default Unsubscribe;
