import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import SaveTemplateModal from "../save-template-modal";
const SaveAsTemplate = ({ subject, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const cancelRef = useRef();
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "outline", onClick: () => setIsOpen(true) }, { children: "Save as Template" })), _jsx(SaveTemplateModal, { isOpen: isOpen, cancelRef: cancelRef, onClose: () => setIsOpen(false), subject: subject, content: content })] }));
};
export default SaveAsTemplate;
