var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { EditPasswordDocument, } from "@packages/apollo-hooks";
import { Formik } from "formik";
import { useCallback } from "react";
import { passwordInitialValues, passwordValidationSchema, } from "./form";
import PasswordFormContent from "./password-form-content";
const PasswordForm = () => {
    const [editUser] = useMutation(EditPasswordDocument);
    const toast = useToast();
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield editUser({
                variables: {
                    input: {
                        currentPassword: values.currentPassword,
                        password: values.password,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.editPassword.__typename) {
                case "EditPasswordSuccessfulResponse":
                    toast({
                        title: "Changes saved",
                        status: "success",
                        isClosable: true,
                    });
                    return;
                case "InvalidPasswordResponse":
                    toast({
                        title: "Password is invalid",
                        status: "error",
                        isClosable: true,
                    });
                    break;
                case "UserNotExistResponse":
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: true,
            });
        }
    }), [editUser, toast]);
    return (_jsx(Formik, Object.assign({ initialValues: passwordInitialValues, validationSchema: passwordValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(PasswordFormContent, Object.assign({}, formProps)) })));
};
export default PasswordForm;
