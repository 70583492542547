import { css } from "@emotion/react";
import styled from "@emotion/styled";
import theme from "../../../../../../common/theme";
import Time from "../shared/time";
import Calendar from "../shared/calendar";
const DEFAULT_MARGIN = 3;
export const PickerWrapper = styled.div `
  pointer-events: none;
  & > * {
    pointer-events: initial;
  }

  align-items: flex-start;
  position: absolute;
  display: flex;
  top: calc(100% + 8px);
  z-index: ${theme.zIndices.skipLink};
  ${p => p.timePickerDirection === "left" &&
    css `
      flex-direction: row-reverse;
      right: 0;
    `}
`;
export const StyledCalendar = styled(Calendar) `
  box-shadow: ${theme.shadows.dropdown};
  border: 1px solid ${theme.colors.gray[300]};
`;
export const StyledTime = styled(Time) `
  box-shadow: ${theme.shadows.dropdown};
  border: 1px solid ${theme.colors.gray[300]};

  ${p => p.direction === "left"
    ? css `
          margin-right: ${DEFAULT_MARGIN}px;
        `
    : css `
          margin-left: ${DEFAULT_MARGIN}px;
        `}
`;
export const Wrapper = styled.div `
  display: flex;
  position: relative;
`;
