import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@chakra-ui/layout";
import React from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import theme from "../../../../../../../../common/theme";
import { SelectWrapper, StyledOption, StyledSelect, } from "./select-am-pm.components";
const SelectAmPm = ({ value, onBlur, onFocus, isSelected, onUp, onSet, onDown, }) => {
    const handleChange = (event) => {
        onSet(event.target.value);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ _hover: {
                    color: theme.colors.cyan[400],
                    cursor: "pointer",
                }, color: "gray.500", onClick: onUp }, { children: _jsx(IoChevronUp, { fontSize: "20px" }) })), _jsx(SelectWrapper, { children: _jsxs(StyledSelect, Object.assign({ focused: isSelected, onBlur: onBlur, onFocus: onFocus, value: value, onChange: handleChange, "data-cy": "am-pm" }, { children: [_jsx(StyledOption, Object.assign({ value: "AM" }, { children: "AM" })), _jsx(StyledOption, Object.assign({ value: "PM" }, { children: "PM" }))] })) }), _jsx(Box, Object.assign({ _hover: {
                    color: theme.colors.cyan[400],
                    cursor: "pointer",
                }, color: "gray.500", onClick: onDown }, { children: _jsx(IoChevronDown, { fontSize: "20px" }) }))] }));
};
export default SelectAmPm;
