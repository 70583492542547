import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { CurrentUserDocument } from "@packages/apollo-hooks";
import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import routesPaths from "../../../routing/route-paths";
import Loader from "../../../shared/components/loader/loader";
import useUserSubscription from "../../../shared/hooks/use-user-subscription";
import { getPaymentTabNames } from "../../settings-content";
const StripePayment = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const { hasNormalSubscription } = useUserSubscription();
    const { refetch } = useQuery(CurrentUserDocument);
    React.useEffect(() => {
        if (hasNormalSubscription) {
            toast({
                title: `Successfully subscribed to motar.me Professional`,
                status: "success",
                isClosable: true,
                position: "top",
            });
            navigate(generatePath(routesPaths.SETTINGS_BY_TAB, {
                tab: getPaymentTabNames(false)[1],
            }));
        }
    }, [hasNormalSubscription, navigate, toast]);
    React.useEffect(() => {
        if (search && search === "?cancel=true") {
            navigate(generatePath(routesPaths.SETTINGS_BY_TAB, {
                tab: getPaymentTabNames(false)[1],
            }));
            toast({
                title: `Payment cancelled`,
                status: "warning",
                isClosable: true,
                position: "top",
            });
        }
        if (search && search === "?check=true") {
            setTimeout(() => refetch(), 5000);
        }
    }, [navigate, search, toast, refetch]);
    return _jsx(Loader, {});
};
export default StripePayment;
