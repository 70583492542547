import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, Icon, IconButton, Input, InputGroup, InputRightElement, } from "@chakra-ui/react";
import React from "react";
import { IoArrowBackOutline, IoCheckmarkSharp } from "react-icons/io5";
import useOnClickOutside from "../../../../../../common/helpers/use-on-click-outside";
import InputField from "../../../form/input-field";
const EditablePageTitle = ({ handleBackIconClick, handleEdit, title, fieldName, handleChangeListName, nameInputRef, nameSaved, initialName, }) => {
    useOnClickOutside(nameInputRef, () => {
        if (!nameSaved &&
            handleEdit &&
            (initialName ? initialName !== title : true)) {
            handleEdit();
        }
    });
    return (_jsxs(HStack, Object.assign({ mb: { base: "4", md: "0" }, mr: "4" }, { children: [handleBackIconClick && (_jsx(IconButton, { variant: "ghost", colorScheme: "gray", fontSize: "2xl", mr: "2", icon: _jsx(IoArrowBackOutline, {}), "aria-label": "Go back", onClick: handleBackIconClick })), _jsx(_Fragment, { children: fieldName ? (_jsx(InputField, { name: fieldName, type: "text" })) : (_jsxs(InputGroup, { children: [_jsx(Input, { ref: nameInputRef, width: "250px", value: title, onChange: e => {
                                if (handleChangeListName) {
                                    handleChangeListName(e.target.value);
                                }
                            } }), _jsx(InputRightElement, { children: nameSaved && (_jsx(Icon, { as: IoCheckmarkSharp, color: "green.500", w: 5, h: 5 })) })] })) })] })));
};
export default EditablePageTitle;
