import * as Yup from "yup";
export const templateCreatorFirstStepInitialValues = {
    subject: "",
    content: "<p></p>",
    name: "",
    templateFolder: "",
};
export const templateCreatorInitialValues = (subject, content, id, name) => {
    return {
        subject,
        content,
        name: name ? name : "",
        templateFolder: id ? id : "",
    };
};
export const templateEditorInitialValues = (subject, content, id) => {
    return {
        subject,
        content,
        name: "",
        templateFolder: id ? id : "",
    };
};
// // TODO: update type
// export const templateEditorInitialValues = (
//   email: CampaignDetailsEmailType,
// ): TemplateCreatorFormValues => {
//   return {
//     subject: email.subject,
//     content: email.body,
//   };
// };
export const templateCreatorFirstStepValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    content: Yup.string().required("Content is required"),
});
export const templateCreatorLastStepValidationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    content: Yup.string().required("Content is required"),
    name: Yup.string().required("Name is required"),
});
