import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "../../../../../../../common/theme";
const MIN_WIDTH = 343;
export const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${theme.space[4]};

  border-radius: 4px;
  overflow: hidden;
  background: white;

  min-width: ${MIN_WIDTH}px;
  user-select: none;
`;
export const Header = styled.div `
  display: flex;
`;
export const TitleWrapper = styled.div `
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: left;
`;
export const Title = styled.h3 `
  font-size: 20px;
  font-weight: 400;
  color: ${theme.colors.gray[700]};
  margin: 0;
  cursor: default;
  ${p => p.clickable &&
    css `
      cursor: pointer;
      :hover {
        color: ${theme.colors.gray[900]};
      }
    `};
`;
export const Grid = styled.div `
  display: grid;
  grid-template-columns: repeat(${p => p.gridWidth}, 1fr);
  grid-auto-rows: 1fr;
  z-index: 1;
`;
