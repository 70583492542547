var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { EditTemplateFolderNameDocument, } from "@packages/apollo-hooks";
import { Formik } from "formik";
import React, { useCallback } from "react";
import EditTemplateFolderNameFormContent from "./edit-template-folder-name-form-content";
import { editFolderNameInitialValues, editFolderNameValidationSchema, } from "./form";
const EditTemplateFolderNameForm = ({ templateFolderName, onClose, cancelRef, templateFolderId, }) => {
    const toast = useToast();
    const [editName] = useMutation(EditTemplateFolderNameDocument, {
        refetchQueries: ["getTemplateFolders"],
    });
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield editName({
                variables: {
                    input: {
                        templateFolderId,
                        name: values.name,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.editTemplateFolderName.__typename) {
                case "EditTemplateFolderNameSuccessfulResponse":
                    onClose();
                    return;
                case "TemplateFolderNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Template folder doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [editName, onClose, templateFolderId, toast]);
    return (_jsx(Formik, Object.assign({ initialValues: editFolderNameInitialValues(templateFolderName), validationSchema: editFolderNameValidationSchema, onSubmit: handleSubmit }, { children: formProps => (_jsx(EditTemplateFolderNameFormContent, Object.assign({ onClose: onClose, cancelRef: cancelRef }, formProps))) })));
};
export default EditTemplateFolderNameForm;
