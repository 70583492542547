import * as Yup from "yup";
export const campaignEditorInitialValues = (campaignDetails) => {
    return {
        contactListId: campaignDetails.contactList
            ? campaignDetails.contactList.id
            : "",
        startsAt: campaignDetails.startsAt
            ? new Date(campaignDetails.startsAt)
            : "",
        endsAt: campaignDetails.endsAt
            ? new Date(campaignDetails.endsAt)
            : "",
        name: campaignDetails.name,
        emails: campaignDetails.campaignEmails &&
            campaignDetails.campaignEmails.length > 0
            ? campaignDetails.campaignEmails.map(email => email.id)
            : [],
        provider: campaignDetails.provider ? campaignDetails.provider : "",
    };
};
export const campaignEditorValidationSchema = Yup.object().shape({
    contactListId: Yup.string().required("Contact list is required"),
    endsAt: Yup.date().required("End date is required"),
    name: Yup.string().required("Name is required"),
    provider: Yup.string().required("Provider is required"),
    emails: Yup.array().min(1, "Email is required"),
});
