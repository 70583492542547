import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Stack } from "@chakra-ui/react";
import { GetTemplateFoldersDocument, } from "@packages/apollo-hooks";
import React, { useState } from "react";
import Loader from "../../../shared/components/loader";
import RecentFavoritesTabPanel from "../recent-favorites-tab-panel";
import FolderBreadcrumb from "./folder-breadcrumb";
import TemplateFolderCard from "./template-folder-card";
const FoldersTabPanel = ({ handleSelectTemplate, selectedTemplate, }) => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const { data, loading } = useQuery(GetTemplateFoldersDocument, {
        fetchPolicy: "no-cache",
        // to set loading to true while refetch
        notifyOnNetworkStatusChange: true,
    });
    if (loading)
        return _jsx(Loader, {});
    return (_jsxs(_Fragment, { children: [selectedFolder && (_jsx(FolderBreadcrumb, Object.assign({ onClick: () => setSelectedFolder(null) }, { children: selectedFolder.name }))), !selectedFolder ? (_jsx(Stack, Object.assign({ spacing: "1" }, { children: data === null || data === void 0 ? void 0 : data.getTemplateFolders.items.map(folder => (_jsx(TemplateFolderCard, { name: folder.name, onClick: () => setSelectedFolder(folder), templatesCount: folder.templatesCount }, folder.id))) }))) : (_jsx(RecentFavoritesTabPanel, { handleSelectTemplate: handleSelectTemplate, selectedTemplate: selectedTemplate, templateFolderId: selectedFolder.id }))] }));
};
export default FoldersTabPanel;
