import { ColumnType } from "../MatchColumnsStep";
import { normalizeCheckboxValue } from "./normalizeCheckboxValue";
export const normalizeTableData = (columns, data, fields) => data.map(row => columns.reduce((acc, column, index) => {
    var _a;
    const curr = row[index];
    switch (column.type) {
        case ColumnType.matchedCheckbox: {
            const field = fields.find(field => field.key === column.value);
            if ("booleanMatches" in field.fieldType &&
                Object.keys(field.fieldType).length) {
                const booleanMatchKey = Object.keys(field.fieldType.booleanMatches || []).find(key => key.toLowerCase() === (curr === null || curr === void 0 ? void 0 : curr.toLowerCase()));
                const booleanMatch = (_a = field.fieldType.booleanMatches) === null || _a === void 0 ? void 0 : _a[booleanMatchKey];
                acc[column.value] = booleanMatchKey
                    ? booleanMatch
                    : normalizeCheckboxValue(curr);
            }
            else {
                acc[column.value] = normalizeCheckboxValue(curr);
            }
            return acc;
        }
        case ColumnType.matched: {
            acc[column.value] = curr === "" ? undefined : curr;
            return acc;
        }
        case ColumnType.matchedSelect:
        case ColumnType.matchedSelectOptions: {
            const matchedOption = column.matchedOptions.find(({ entry, value }) => entry === curr);
            acc[column.value] = (matchedOption === null || matchedOption === void 0 ? void 0 : matchedOption.value) || undefined;
            return acc;
        }
        case ColumnType.empty:
        case ColumnType.ignored: {
            return acc;
        }
        default:
            return acc;
    }
}, {}));
