import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import theme from "../../../../../../../../common/theme";
import { NumberInput } from "./editable-number.components";
const EditableNumber = ({ value: defaultValue, minValue, maxValue, onBlur, onFocus, isSelected, onUp, onSet, onDown, type, }) => {
    const [currentValue, setCurrentValue] = useState(defaultValue);
    useEffect(() => {
        setCurrentValue(defaultValue);
    }, [defaultValue]);
    const validateAndUpdate = () => {
        const newHours = parseInt(currentValue, 10);
        if (isNaN(newHours) || newHours < minValue || newHours > maxValue) {
            setCurrentValue(defaultValue);
            return;
        }
        onSet(newHours);
        onBlur();
    };
    const handleChange = (event) => {
        setCurrentValue(event.target.value);
    };
    const handleEnter = (event) => {
        switch (event.key) {
            case "Enter":
                return event.currentTarget.blur();
            case "ArrowUp":
                return onUp();
            case "ArrowDown":
                return onDown();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ _hover: {
                    color: theme.colors.cyan[400],
                    cursor: "pointer",
                }, color: "gray.500", onClick: onUp }, { children: _jsx(IoChevronUp, { fontSize: "20px" }) })), _jsx(NumberInput, { focused: isSelected, onBlur: validateAndUpdate, onFocus: onFocus, value: currentValue, onChange: handleChange, onKeyDown: handleEnter, maxLength: 2, "data-cy": type }), _jsx(Box, Object.assign({ _hover: {
                    color: theme.colors.cyan[400],
                    cursor: "pointer",
                }, color: "gray.500", onClick: onDown }, { children: _jsx(IoChevronDown, { fontSize: "20px" }) }))] }));
};
export default EditableNumber;
