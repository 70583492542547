var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { EditCampaignDocument, } from "@packages/apollo-hooks";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import routesPaths from "../../routing/route-paths";
const useSaveAsDraft = (campaignId) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [editCampaign, { loading }] = useMutation(EditCampaignDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getCampaignDetails", "getCampaigns"],
    });
    const handleSaveAsDraft = useCallback((values, showToast = true) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data: editCampaignData } = yield editCampaign({
                variables: {
                    input: {
                        campaignId,
                        contactListId: values.contactListId,
                        name: values.name,
                        endsAt: values.endsAt.toString().length === 0
                            ? null
                            : values.endsAt.toString(),
                        provider: values.provider ? values.provider : null,
                    },
                },
            });
            switch (editCampaignData === null || editCampaignData === void 0 ? void 0 : editCampaignData.editCampaign.__typename) {
                case "EditCampaignSuccessfulResponse":
                    if (showToast) {
                        toast({
                            title: "Campaign edited successfully",
                            status: "success",
                            isClosable: true,
                        });
                    }
                    return;
                case "ContactListNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign list doesn't exist",
                        status: "error",
                        isClosable: true,
                    });
                    break;
                case "CampaignNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign doesn't exist",
                        status: "error",
                        isClosable: true,
                    });
                    navigate(routesPaths.CAMPAIGNS);
                    break;
                case "DisableIntegrationNotFoundResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Integration not found",
                        status: "error",
                        isClosable: true,
                    });
                    break;
                case "CampaignAlreadyActiveResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign already active",
                        status: "error",
                        isClosable: true,
                    });
                    navigate(routesPaths.CAMPAIGN.replace(":id", campaignId));
                    break;
                case "CampaignInvalidEndDateResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Invalid end date",
                        status: "error",
                        isClosable: true,
                    });
                    break;
                case "CampaignDeletedResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign is deleted",
                        status: "error",
                        isClosable: true,
                    });
                    navigate(routesPaths.CAMPAIGNS);
                    break;
                case "CampaignCompletedResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign is completed",
                        status: "error",
                        isClosable: true,
                    });
                    navigate(routesPaths.CAMPAIGN.replace(":id", campaignId));
                    break;
                case "EditProviderNotAvailableAfterEmailSentResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Provider is not editable after sending first email",
                        status: "error",
                        isClosable: true,
                    });
                    navigate(routesPaths.CAMPAIGN.replace(":id", campaignId));
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    console.error("err");
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [campaignId, editCampaign, navigate, toast]);
    return { handleSaveAsDraft, saveAsDraftLoading: loading };
};
export default useSaveAsDraft;
