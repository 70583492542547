import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, IconButton, Menu, MenuButton, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr, } from "@chakra-ui/react";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { IoEllipsisVerticalOutline } from "react-icons/io5";
import { useSortBy, useTable } from "react-table";
import useIsAdmin from "../../../../common/helpers/use-is-admin";
import EmptyState from "../../../shared/components/table/empty-state";
import AddContact from "../add-contact";
import DeleteContactDialog from "../delete-contact-dialog";
const SingleContactTableContent = ({ tableData, setPageNumber, showEditButton, selectedContacts, handleSelectSingle, handleSelectSpecificContacts, handleClearAllSelected, enableSelection, showActiveCampaignIndicator, enableDelete, afterDeleteAction, contactListId, }) => {
    const isAdmin = useIsAdmin();
    const [allSelected, setAllSelected] = useState(false);
    const [showActiveCampaignText, setShowActiveCampaignText] = useState(false);
    const columns = useMemo(() => {
        return [
            {
                Header: "First Name",
                accessor: "firstName",
            },
            {
                Header: "Last Name",
                accessor: "lastName",
            },
            {
                Header: "E-mail Address",
                accessor: "email",
            },
            {
                Header: "Lead Company",
                accessor: "leadCompany",
            },
            {
                Header: "Job Title",
                accessor: "jobTitle",
            },
            {
                Header: "Country",
                accessor: "country",
            },
            {
                Header: "Created",
                accessor: original => format(new Date(original.createdAt), DATE_FORMAT),
            },
        ];
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData.getContacts.items }, useSortBy);
    const ids = tableData.getContacts.items.map(x => x.id);
    useEffect(() => {
        if (ids.every(r => selectedContacts.includes(r))) {
            setAllSelected(true);
        }
        else {
            setAllSelected(false);
        }
    }, [ids, selectedContacts]);
    useEffect(() => {
        if (showActiveCampaignIndicator) {
            setShowActiveCampaignText(rows.map(x => x.original.hasActiveCampaign).includes(true));
        }
    }, [rows, showActiveCampaignIndicator]);
    return (_jsxs(_Fragment, { children: [showActiveCampaignText && (_jsx(Text, Object.assign({ pb: "4", color: "gray.500", fontSize: "sm" }, { children: "Highlighted contacts are used in another active campaign. Please remove the highlighted contacts or pause the campaign that uses them." }))), _jsxs(Table, Object.assign({}, getTableProps(), { mt: showActiveCampaignIndicator ? "0" : "8" }, { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }),
                            enableSelection && !isAdmin && (_jsx(Th, Object.assign({ width: "32px" }, { children: _jsx(Checkbox, { variant: "dark", onChange: () => handleSelectSpecificContacts(ids, !allSelected), isChecked: allSelected }) }))),
                            headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex }), column.render("Header")))),
                            !isAdmin && _jsx(Th, {})))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                                const showIndicator = showActiveCampaignIndicator && row.original.hasActiveCampaign;
                                prepareRow(row);
                                return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex, backgroundColor: !showIndicator ? "white" : "#FFFFF0" }),
                                    enableSelection && !isAdmin && (_jsxs(Td, Object.assign({ textAlign: "left" }, { children: [_jsx(Checkbox, { isChecked: selectedContacts.includes(row.original.id), onChange: () => handleSelectSingle(row.original.id) }), " "] }))),
                                    row.cells.map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex }), cell.render("Cell")))),
                                    !isAdmin && (_jsx(Td, Object.assign({ textAlign: "right" }, { children: enableDelete &&
                                            (!showEditButton ? (_jsx(DeleteContactDialog, { setPageNumber: setPageNumber, contactIds: [row.original.id], name: row.original.firstName + " " + row.original.lastName, iconButton: true, afterDeleteAction: afterDeleteAction, contactListId: contactListId })) : (_jsxs(Menu, { children: [_jsx(MenuButton, { as: IconButton, size: "sm", "aria-label": "Options", icon: _jsx(IoEllipsisVerticalOutline, {}), variant: "outline", colorScheme: "gray" }), selectedContacts.length > 1 ? (_jsx(MenuList, { children: _jsx(DeleteContactDialog, { setPageNumber: setPageNumber, contactIds: selectedContacts, afterDeleteAction: () => handleClearAllSelected(), contactListId: contactListId }) })) : (_jsxs(MenuList, { children: [_jsx(AddContact, { isEditMode: true, contactId: row.original.id }), _jsx(DeleteContactDialog, { setPageNumber: setPageNumber, contactIds: [row.original.id], name: row.original.firstName +
                                                                    " " +
                                                                    row.original.lastName, afterDeleteAction: () => handleClearAllSelected(), contactListId: contactListId })] }))] }))) })))));
                            }), rows.length === 0 && _jsx(EmptyState, { type: "contacts", columnCount: 9 })] }))] }))] }));
};
export default SingleContactTableContent;
