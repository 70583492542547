import { extendTheme } from "@chakra-ui/react";
import Alert from "./components/alert";
import Button from "./components/button";
import Checkbox from "./components/checkbox";
import CloseButton from "./components/close-button";
import Drawer from "./components/drawer";
import FormError from "./components/form-error";
import FormLabel from "./components/form-label";
import Input from "./components/input";
import Menu from "./components/menu";
import Modal from "./components/modal";
import NumberInput from "./components/number-input";
import Select from "./components/select";
import Stepper from "./components/stepper";
import Switch from "./components/switch";
import Table from "./components/table";
import Tabs from "./components/tabs";
const colors = {
    green: {
        100: "#E2F4CB",
        500: "#2BA530",
        600: "#2BA530",
        900: "#1A7A1E",
    },
    almond: "#FFF2CC",
};
const styles = {
    global: {
        "html, body": {
            color: "gray.800",
            a: {
                _focus: {
                    boxShadow: "none !important",
                },
            },
            button: {
                _focus: {
                    boxShadow: "none !important",
                },
            },
            input: {
                _focus: {
                    boxShadow: "none !important",
                },
            },
            select: {
                _focus: {
                    boxShadow: "none !important",
                },
            },
        },
        ".rdg": {
            contain: "size layout style paint",
            borderRadius: "lg",
            border: "none",
            borderTop: "1px solid var(--rdg-border-color)",
            blockSize: "100%",
            // we have to use vars here because chakra does not autotransform unknown props
            "--rdg-row-height": "52px",
            "--rdg-header-row-height": "52px",
            "--rdg-summary-row-height": "52px",
            "--rdg-color": "var(--chakra-colors-textColor)",
            "--rdg-background-color": "white",
            "--rdg-header-background-color": "var(--chakra-colors-background)",
            "--rdg-row-hover-background-color": "var(--chakra-colors-gray-100)",
            "--rdg-selection-color": "var(--chakra-colors-blue-400)",
            "--rdg-row-selected-background-color": "var(--chakra-colors-gray-100)",
            "--row-selected-hover-background-color": "var(--chakra-colors-gray-100)",
            "--rdg-error-cell-background-color": "var(--chakra-colors-red-50)",
            "--rdg-warning-cell-background-color": "var(--chakra-colors-orange-50)",
            "--rdg-info-cell-background-color": "var(--chakra-colors-blue-50)",
            "--rdg-border-color": "var(--chakra-colors-gray-100)",
            "--rdg-header-bg-color": "var(--chakra-colors-gray-200)",
            "--rdg-frozen-cell-box-shadow": "none",
            "--rdg-font-size": "var(--chakra-fontSizes-sm)",
        },
        ".rdg-header-row .rdg-cell": {
            display: "flex",
            alignItems: "center",
            color: "textColor",
            fontSize: "xs",
            background: "var(--rdg-header-bg-color)",
            lineHeight: 10,
            fontWeight: "bold",
            letterSpacing: "wider",
            textTransform: "uppercase",
            "&[aria-selected='true']": {
                boxShadow: "none",
                outline: "none",
            },
            "&:first-of-type": {
                borderTopLeftRadius: "lg",
            },
            "&:last-child": {
                borderTopRightRadius: "lg",
            },
        },
        ".rdg-row:last-child .rdg-cell:first-of-type": {
            borderBottomLeftRadius: "lg",
        },
        ".rdg-row:last-child .rdg-cell:last-child": {
            borderBottomRightRadius: "lg",
        },
        ".rdg[dir='rtl']": {
            ".rdg-row:last-child .rdg-cell:first-of-type": {
                borderBottomRightRadius: "lg",
                borderBottomLeftRadius: "none",
            },
            ".rdg-row:last-child .rdg-cell:last-child": {
                borderBottomLeftRadius: "lg",
                borderBottomRightRadius: "none",
            },
        },
        ".rdg-row": {
            "&[aria-selected='true']:hover": {
                background: "gray.100",
            },
        },
        ".rdg-cell": {
            contain: "size layout style paint",
            borderRight: "none",
            borderInlineEnd: "none",
            borderBottom: "1px solid var(--rdg-border-color)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "&[aria-selected='true']": {
                boxShadow: "inset 0 0 0 1px var(--rdg-selection-color)",
            },
            "&[aria-selected='true']&[aria-readonly='true']": {
                boxShadow: "none",
            },
            "&:first-of-type": {
                boxShadow: "none",
                borderInlineStart: "1px solid var(--rdg-border-color)",
            },
            "&:last-child": {
                borderInlineEnd: "1px solid var(--rdg-border-color)",
            },
        },
        ".rdg-cell-error": {
            backgroundColor: "var(--rdg-error-cell-background-color)",
            "&[aria-selected='true']": {
                boxShadow: "inset 0 0 0 2px #E53E3E",
                outline: "none",
            },
        },
        ".rdg-cell-warning": {
            backgroundColor: "var(--rdg-warning-cell-background-color)",
        },
        ".rdg-cell-info": {
            backgroundColor: "var(--rdg-info-cell-background-color)",
        },
        ".rdg-example .rdg-cell": {
            "--rdg-selection-color": "none",
            borderBottom: "none",
        },
        ".rdg-checkbox": {
            border: "2px solid #E2E8F0",
            borderRadius: "2px",
            "--rdg-selection-color": "none",
            display: "flex",
            alignItems: "center",
        },
        ".rdg-checkbox-input:checked + .rdg-checkbox": {
            border: "2px solid var(--chakra-colors-green-500)",
            backroundColor: "#2BA530",
            background: "url('/check.png') no-repeat",
            backgroundSize: "18px",
            backgroundPositionX: "-1px",
            backgroundPositionY: "-1px",
            outline: "none",
        },
    },
};
const fonts = {
    heading: "'DM Sans', sans-serif",
    body: "'DM Sans', sans-serif",
};
const shadows = {
    dropdown: "0px 4px 10px rgba(0, 0, 0, 0.06)",
};
const config = {
    initialColorMode: "light",
};
export default extendTheme({
    fonts,
    colors,
    styles,
    shadows,
    components: {
        Alert,
        Button,
        FormLabel,
        Input,
        Select,
        Table,
        Drawer,
        FormError,
        Tabs,
        NumberInput,
        Modal,
        Checkbox,
        Menu,
        Switch,
        CloseButton,
        Stepper,
    },
    config,
});
