var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, useToast } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AddCampaignDocument } from "@packages/apollo-hooks";
import { useMutation } from "@apollo/client";
import { IoCreate, IoOpenOutline } from "react-icons/io5";
import NavLink from "../../app/authed-layout/sidebar/nav-link";
import routesPaths from "../../routing/route-paths";
import useIsAdmin from "../../../common/helpers/use-is-admin";
const CreateCampaign = ({ inSidebar }) => {
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const isAdmin = useIsAdmin();
    const [addCampaign] = useMutation(AddCampaignDocument);
    const handleCreateCampaign = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield addCampaign();
            switch (data === null || data === void 0 ? void 0 : data.addCampaign.__typename) {
                case "AddCampaignSuccessfulResponse":
                    navigate(routesPaths.CAMPAIGN_EDITOR.replace(":id", data.addCampaign.id));
                    return;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    console.error("err");
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: true,
            });
        }
    }), [addCampaign, navigate, toast]);
    if (isAdmin)
        return null;
    return (_jsx(_Fragment, { children: !inSidebar ? (_jsx(Button, Object.assign({ leftIcon: _jsx(IoOpenOutline, {}), onClick: handleCreateCampaign }, { children: "E-mail Campaign Creator" }))) : (_jsx(NavLink, { onClick: () => {
                if (!location.pathname.includes(routesPaths.CAMPAIGN_CREATOR)) {
                    handleCreateCampaign();
                }
            }, label: "E-mail Campaign Creator", icon: IoCreate, isActive: location.pathname.includes(routesPaths.CAMPAIGN_CREATOR) })) }));
};
export default CreateCampaign;
