import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from "@chakra-ui/react";
import { ConfirmCloseAlert } from "./Alerts/ConfirmCloseAlert";
import { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
export const ModalCloseButton = ({ onClose }) => {
    const [showModal, setShowModal] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(ConfirmCloseAlert, { isOpen: showModal, onClose: () => setShowModal(false), onConfirm: () => {
                    setShowModal(false);
                    onClose();
                } }), _jsx(IconButton, { "aria-label": "Close modal", icon: _jsx(IoCloseOutline, {}), colorScheme: "gray", fontSize: "2xl", variant: "ghost", position: "absolute", transform: "translate(50%, -50%)", right: "20px", top: "20px", onClick: () => setShowModal(true), zIndex: "toast", dir: "ltr" })] }));
};
