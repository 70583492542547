import { isValid, isDate } from "date-fns";
export const validateDate = (value) => {
    if (isDate(value) && isValid(value)) {
        return value;
    }
    return null;
};
export const validateInitialValue = (value) => {
    var _a;
    if (typeof value === "object") {
        const start = validateDate(value.start);
        if (start !== null) {
            return start;
        }
    }
    return (_a = validateDate(value)) !== null && _a !== void 0 ? _a : new Date();
};
