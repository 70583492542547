import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, HStack, Text } from "@chakra-ui/react";
import { GetContactsDocument } from "@packages/apollo-hooks";
import { useState } from "react";
import useIsAdmin from "../../../../common/helpers/use-is-admin";
import Loader from "../../../shared/components/loader";
import Pagination from "../../../shared/components/table/pagination";
import { DEFAULT_INITIAL_PAGE_SIZE, DEFAULT_PAGE_INDEX, } from "../../../shared/components/table/pagination/pagination";
import TableFilterPanel from "../../../shared/components/table/table-filter-panel/table-filter-panel";
import SingleContactTableContent from "./single-contact-list-table-content";
const SingleContactListTable = ({ contactListId, showEditButton = true, showFilterTab = true, enableSelection = true, showActiveCampaignIndicator = false, enableDelete = true, }) => {
    const isAdmin = useIsAdmin();
    const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_INDEX);
    const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedContacts, setSelectedContacts] = useState([]);
    const handleSelectSingle = (id) => {
        if (selectedContacts.includes(id)) {
            setSelectedContacts(selectedContacts.filter(x => x !== id));
        }
        else {
            setSelectedContacts([...selectedContacts, id]);
        }
    };
    const handleSelectSpecificContacts = (ids, setSelected) => {
        if (setSelected) {
            const contacts = [
                ...selectedContacts.filter(item => ids.indexOf(item) === -1),
                ...ids,
            ];
            setSelectedContacts(contacts);
        }
        else {
            setSelectedContacts(selectedContacts.filter(item => ids.indexOf(item) === -1));
        }
    };
    const handleClearAllSelected = () => {
        setSelectedContacts([]);
    };
    const { data, loading, error, refetch } = useQuery(GetContactsDocument, {
        variables: {
            pageNumber,
            pageSize,
            contactListId,
            searchPhrase: searchTerm,
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    const handlePrevPage = () => {
        setPageNumber(pageNumber - 1);
    };
    const handleNextPage = () => {
        setPageNumber(pageNumber + 1);
    };
    const handleChangeSearch = (value) => {
        if (value !== searchTerm) {
            setSearchTerm(value);
            setPageNumber(DEFAULT_PAGE_INDEX);
        }
    };
    if (loading)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsx(Box, Object.assign({ maxW: { base: "xl", md: "7xl" }, mx: "auto" }, { children: _jsx(Box, Object.assign({ overflowX: "auto" }, { children: data && (_jsxs(_Fragment, { children: [showFilterTab && (_jsx(TableFilterPanel, { searchTerm: searchTerm, handleChangeSearch: handleChangeSearch })), _jsx(SingleContactTableContent, { tableData: data, setPageNumber: setPageNumber, showEditButton: showEditButton, selectedContacts: selectedContacts, handleSelectSingle: handleSelectSingle, handleSelectSpecificContacts: handleSelectSpecificContacts, handleClearAllSelected: handleClearAllSelected, enableSelection: enableSelection, showActiveCampaignIndicator: showActiveCampaignIndicator, enableDelete: enableDelete, afterDeleteAction: refetch, contactListId: contactListId }), data.getContacts.items.length > 0 && (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(HStack, { children: [enableSelection && !isAdmin && (_jsxs(Text, Object.assign({ color: "gray.600", fontSize: "sm" }, { children: [selectedContacts.length, " selected /", " "] }))), _jsxs(Text, Object.assign({ color: "gray.500", fontSize: "sm" }, { children: [data.getContacts.totalItems, " contact", data.getContacts.totalItems !== 1 && "s"] }))] }), _jsx(Pagination, { pageIndex: pageNumber, pageCount: data.getContacts.pageCount, canPreviousPage: pageNumber > 0, canNextPage: pageNumber < data.getContacts.pageCount - 1, gotoPage: setPageNumber, previousPage: handlePrevPage, nextPage: handleNextPage, pageSize: pageSize, onPageSizeChange: setPageSize })] })))] })) })) })));
};
export default SingleContactListTable;
