import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, Stack, Text, Box } from "@chakra-ui/react";
import { Form } from "formik";
import React from "react";
import routesPaths from "../routing/route-paths";
import InputField from "../shared/components/form/input-field";
const ResetPasswordFormContent = ({ isSubmitting, isValid, submitCount, }) => {
    return (_jsxs(Form, { children: [_jsx(Stack, Object.assign({ spacing: "8" }, { children: _jsx(InputField, { label: "Email", type: "email", name: "email" }) })), _jsxs(Flex, Object.assign({ direction: { base: "column-reverse", md: "row" }, mt: "6", align: "center", justify: "space-between" }, { children: [_jsxs(Flex, Object.assign({ ml: "3" }, { children: [_jsx(Text, Object.assign({ fontSize: "sm", color: "gray.600" }, { children: "New user?" })), _jsx(Box, Object.assign({ display: "inline-block", as: "a", href: routesPaths.REGISTER, color: "green.500", fontWeight: "bold", fontSize: "sm", ml: "1" }, { children: "Create Account" }))] })), _jsx(Button, Object.assign({ size: "lg", mr: "3", mb: { base: "4", md: "0" }, w: { base: "full", md: "auto" }, type: "submit", isLoading: isSubmitting, disabled: submitCount > 0 && !isValid }, { children: "Reset Password" }))] }))] }));
};
export default ResetPasswordFormContent;
