import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import PublicLayout from "../app/public-layout";
import useAppContext from "../app/use-app-context";
import routesPaths from "./route-paths";
import ScreenLoading from "../shared/components/screen-loading";
export const PublicRoute = ({ children }) => {
    // token means that user is logged in
    const hasToken = !!useAppContext().userToken;
    if (hasToken) {
        return _jsx(Navigate, { to: routesPaths.HOME, replace: true });
    }
    return (_jsx(PublicLayout, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(ScreenLoading, {}) }, { children: children })) }));
};
export default PublicRoute;
