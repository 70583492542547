const routesPaths = {
    HOME: "/",
    LOGIN: "/login",
    UNSUBSCRIBE: "/unsubscribe/:token",
    ACTIVATE_USER: "/activate/:token",
    LOGOUT: "/logout",
    RESET_PASSWORD: "/set-password",
    SET_PASSWORD: "/set-password/:resetToken",
    REGISTER: "/register",
    ADMIN_PANEL: "/admin-panel",
    CONTACT_LISTS: "/contact-lists",
    CONTACT_LIST: "/contact-lists/:id",
    GOOGLE_INTEGRATION: "/integration/google",
    MICROSOFT_INTEGRATION: "/integration/microsoft",
    SES_INTEGRATION: "/integration/ses",
    CAMPAIGNS: "/campaigns",
    CAMPAIGN: "/campaigns/:id",
    CAMPAIGN_WITH_EMAIL: "/campaigns/:id/:emailId",
    CAMPAIGN_EDITOR: "/campaign-creator/:id",
    CAMPAIGN_CREATOR: "/campaign-creator",
    TEMPLATES: "/templates",
    TEMPLATES_WITH_MODE: "/templates/:id",
    SETTINGS: "/settings",
    SETTINGS_BY_TAB: "/settings/:tab",
    STRIPE_PAYMENT: "/stripe-payment",
};
export default routesPaths;
