import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Drawer, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, } from "@chakra-ui/react";
import React from "react";
import { IoLayersOutline } from "react-icons/io5";
import CustomDrawerCloseButton from "../../shared/components/custom-drawer-close-button";
import TemplateCreatorForm from "./template-creator-form";
const TemplateCreator = ({ templateFolderId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ leftIcon: _jsx(IoLayersOutline, {}), onClick: onOpen }, { children: "New Template" })), _jsx(Drawer, Object.assign({ isOpen: isOpen, onClose: onClose, size: "own", trapFocus: false }, { children: _jsx(DrawerOverlay, { children: _jsxs(DrawerContent, Object.assign({ maxWidth: "calc(100vw - 320px)" }, { children: [_jsx(CustomDrawerCloseButton, {}), _jsx(DrawerHeader, { children: "New Template" }), _jsx(Divider, {}), _jsx(TemplateCreatorForm, { onClose: onClose, templateFolderId: templateFolderId })] })) }) }))] }));
};
export default TemplateCreator;
