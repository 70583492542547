import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, HStack, IconButton, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { IoArrowBackOutline, IoCheckmarkOutline, IoPencilSharp, } from "react-icons/io5";
import InputField from "../../../form/input-field";
const PageTitle = ({ handleBackIconClick, handleEdit, title, fieldName, children, handleChangeListName, editable = false, }) => {
    const [isEditMode, setIsEditMode] = useState(editable);
    const handleEditMode = (value) => {
        setIsEditMode(value);
    };
    return (_jsxs(HStack, Object.assign({ mb: { base: "4", md: "0" }, mr: "4" }, { children: [handleBackIconClick && (_jsx(IconButton, { variant: "ghost", colorScheme: "gray", fontSize: "2xl", mr: "2", icon: _jsx(IoArrowBackOutline, {}), "aria-label": "Go back", onClick: handleBackIconClick })), !isEditMode ? (_jsxs(_Fragment, { children: [_jsx(Heading, Object.assign({ size: "lg", fontSize: "4xl", noOfLines: 1 }, { children: title })), children && (_jsx(Text, Object.assign({ color: "gray.500", fontSize: "sm" }, { children: children }))), (handleEdit || fieldName) && (_jsx(IconButton, { variant: "outline", size: "xs", ml: "2", icon: _jsx(IoPencilSharp, {}), "aria-label": "Edit contact list name", onClick: () => handleEditMode(true) }))] })) : (_jsxs(_Fragment, { children: [fieldName ? (_jsx(InputField, { name: fieldName, type: "text" })) : (_jsx(Input, { value: title, onChange: e => {
                            if (handleChangeListName) {
                                handleChangeListName(e.target.value);
                            }
                        } })), _jsx(IconButton, { variant: "solid", size: "xs", ml: "2", icon: _jsx(IoCheckmarkOutline, {}), "aria-label": "Save changes", onClick: () => {
                            if (handleEdit) {
                                handleEdit();
                            }
                            setIsEditMode(false);
                        } })] }))] })));
};
export default PageTitle;
