import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Flex, Spacer, Stack } from "@chakra-ui/react";
import React from "react";
import { IoExit, IoLayers, IoMail, IoPeople, IoSettings, IoShield, } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import useIsAdmin from "../../../../common/helpers/use-is-admin";
import CreateCampaign from "../../../campaigns/create-campaign";
import routesPaths from "../../../routing/route-paths";
import Logo from "../../../shared/components/logo";
import NavLink from "./nav-link";
import UserCard from "./user-card";
const Sidebar = (props) => {
    const location = useLocation();
    const isAdmin = useIsAdmin();
    return (_jsx(Flex, Object.assign({ bg: "white", direction: "column", width: "285px" }, props, { children: _jsxs(Flex, Object.assign({ direction: "column", flex: "1", pt: "5", overflowY: "auto", borderRightWidth: "1px", borderColor: "gray.200" }, { children: [_jsx(Box, Object.assign({ mb: "10", px: "4", width: "152px", className: "logged-in-logo" }, { children: _jsx(Logo, {}) })), _jsx(Box, Object.assign({ py: "6", px: "6" }, { children: _jsx(UserCard, {}) })), _jsx(Divider, { opacity: "1" }), _jsx(Stack, Object.assign({ spacing: "6", as: "nav", "aria-label": "Sidebar Navigation" }, { children: _jsxs(Stack, Object.assign({ spacing: "2", mt: "4" }, { children: [_jsx(NavLink, { href: routesPaths.CONTACT_LISTS, label: "Contact Lists", icon: IoPeople, isActive: location.pathname.includes(routesPaths.CONTACT_LISTS) }), _jsx(NavLink, { href: routesPaths.CAMPAIGNS, label: "Campaigns", icon: IoMail, isActive: location.pathname.includes(routesPaths.CAMPAIGNS) }), !isAdmin && _jsx(CreateCampaign, { inSidebar: true }), !isAdmin && (_jsx(NavLink, { href: routesPaths.TEMPLATES, label: "Templates", icon: IoLayers, isActive: location.pathname.includes(routesPaths.TEMPLATES) }))] })) })), _jsx(Spacer, {}), _jsx(Divider, { opacity: "1" }), _jsxs(Stack, Object.assign({ pt: "4", pb: "4", spacing: "2" }, { children: [isAdmin && (_jsx(NavLink, { href: routesPaths.ADMIN_PANEL, label: "Admin Panel", icon: IoShield, isActive: location.pathname.includes(routesPaths.ADMIN_PANEL) })), !isAdmin && (_jsx(NavLink, { href: routesPaths.SETTINGS, label: "Settings", icon: IoSettings, isActive: location.pathname.includes(routesPaths.SETTINGS) })), _jsx(NavLink, { href: routesPaths.LOGOUT, icon: IoExit, label: "Logout" })] }))] })) })));
};
export default Sidebar;
