var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Checkbox, IconButton, useToast, VStack, } from "@chakra-ui/react";
import { DeleteTemplateFolderDocument, } from "@packages/apollo-hooks";
import React, { useCallback, useRef, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import routesPaths from "../../../routing/route-paths";
const DeleteTemplateFolder = ({ templateFolderId, templateFolderName, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [shouldDeleteTemplates, setShouldDeleteTemplates] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();
    const toast = useToast();
    const navigate = useNavigate();
    const [deleteTemplateFolder] = useMutation(DeleteTemplateFolderDocument, {
        refetchQueries: ["getTemplateFolders"],
    });
    const handleDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield deleteTemplateFolder({
                variables: {
                    input: {
                        templateFolderId,
                        deleteAllTemplatesFromFolder: shouldDeleteTemplates,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.deleteTemplateFolder.__typename) {
                case "DeleteTemplateFolderSuccessfulResponse":
                    navigate(routesPaths.TEMPLATES);
                    return;
                case "TemplateFolderNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Template folder doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [
        deleteTemplateFolder,
        navigate,
        shouldDeleteTemplates,
        templateFolderId,
        toast,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { size: "xs", variant: "outline_color", "aria-label": "Delete template folder", icon: _jsx(IoTrashOutline, {}), onClick: () => setIsOpen(true) }), _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Delete Template Folder" })), _jsx(AlertDialogBody, { children: _jsxs(VStack, Object.assign({ spacing: "2.5", alignItems: "left" }, { children: [_jsxs(Box, Object.assign({ color: "gray.500" }, { children: ["Do you want to delete ", templateFolderName, "?", " "] })), _jsx(Checkbox, Object.assign({ colorScheme: "red", color: "gray.700", isChecked: shouldDeleteTemplates, onChange: () => setShouldDeleteTemplates(!shouldDeleteTemplates) }, { children: "Delete all the templates from this folder" }))] })) }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", colorScheme: "gray", ref: cancelRef.current, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ colorScheme: "red", variant: "solid", onClick: () => {
                                            handleDelete();
                                            onClose();
                                        }, ml: 3 }, { children: "Delete" }))] })] }) }) }))] }));
};
export default DeleteTemplateFolder;
