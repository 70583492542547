const withLine = (props) => {
    const { colorScheme } = props;
    return {
        tablist: {
            borderBottom: "4px solid",
            borderColor: "gray.200",
        },
        tab: {
            borderBottom: "4px solid",
            borderColor: "transparent",
            marginBottom: "-4px",
            color: "gray.500",
            _selected: {
                color: `${colorScheme}.500`,
                fontWeight: "bold",
                borderColor: `${colorScheme}.500`,
            },
            _active: {
                color: `${colorScheme}.500`,
                fontWeight: "bold",
                borderColor: `${colorScheme}.500`,
            },
        },
        tabpanel: {
            padding: "0",
        },
    };
};
const tabs = {
    parts: ["tablist", "tab", "tabpanel"],
    variants: {
        line: withLine,
        button: {
            tablist: {
                flexDirection: "column",
            },
            tab: {
                justifyContent: "flex-start",
                color: "gray.700",
                fontWeight: "normal",
                fontSize: "sm",
                borderRadius: "6px",
                fontFamily: "'Inter', sans-serif",
                py: "2",
                px: "12px",
                svg: {
                    mr: "10px",
                    color: "gray.400",
                },
                _selected: {
                    fontWeight: "medium",
                    backgroundColor: "#E2E8F0",
                    svg: {
                        color: "gray.700",
                    },
                },
                _active: {
                    fontWeight: "medium",
                    backgroundColor: "#E2E8F0",
                    svg: {
                        color: "gray.700",
                    },
                },
                _hover: {
                    backgroundColor: "#E2E8F0",
                },
            },
            tabpanel: {
                padding: "0",
                // h: "full",
            },
        },
    },
    sizes: {
        lg: {
            tab: {
                fontSize: "1rem",
                py: "2.5",
                px: "5",
            },
        },
    },
    defaultProps: {
        colorScheme: "green",
        size: "lg",
    },
};
export default tabs;
