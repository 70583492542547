import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDisclosure } from "@chakra-ui/hooks";
import { Divider, Drawer, DrawerContent, DrawerHeader, DrawerOverlay, MenuItem, } from "@chakra-ui/react";
import { IoPencilSharp } from "react-icons/io5";
import CustomDrawerCloseButton from "../../../shared/components/custom-drawer-close-button";
import EditUserForm from "./edit-user-form";
const EditUser = ({ userId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(MenuItem, Object.assign({ icon: _jsx(IoPencilSharp, {}), onClick: onOpen }, { children: "Edit" })), _jsxs(Drawer, Object.assign({ isOpen: isOpen, onClose: onClose, size: "xs" }, { children: [_jsx(DrawerOverlay, {}), _jsxs(DrawerContent, Object.assign({ maxWidth: "520px" }, { children: [_jsx(CustomDrawerCloseButton, {}), _jsx(DrawerHeader, { children: "Edit User" }), _jsx(Divider, {}), _jsx(EditUserForm, { userId: userId, onClose: onClose }), _jsx(Divider, { mt: "3" })] }))] }))] }));
};
export default EditUser;
