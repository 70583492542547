"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateByTimeString = exports.DATE_TIME_FORMAT = exports.TIME_FORMAT = exports.DATE_REPORT_FORMAT = exports.DATE_FORMAT = exports.DAY_MONTH_FORMAT = void 0;
exports.DAY_MONTH_FORMAT = "dd-MM";
exports.DATE_FORMAT = [exports.DAY_MONTH_FORMAT, "yyyy"].join("-");
exports.DATE_REPORT_FORMAT = "dd MMM yyyy";
exports.TIME_FORMAT = "p";
exports.DATE_TIME_FORMAT = [exports.DATE_FORMAT, exports.TIME_FORMAT].join(" ");
const getDateByTimeString = (timeString) => {
    var _a;
    const regex = /^(?<hours>(?:[01]\d|2[0-3])):(?<minutes>[0-5][0-9]):(?<seconds>[0-5][0-9])$/;
    const groups = (_a = timeString.match(regex)) === null || _a === void 0 ? void 0 : _a.groups;
    if (!groups) {
        throw new Error("Invalid time string");
    }
    const date = new Date();
    date.setHours(parseInt(groups.hours, 10), parseInt(groups.minutes, 10), parseInt(groups.seconds, 10));
    return date;
};
exports.getDateByTimeString = getDateByTimeString;
