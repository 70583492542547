var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation, useQuery } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { CurrentUserDocument, EditUserDocument, } from "@packages/apollo-hooks";
import { Formik } from "formik";
import { useCallback } from "react";
import AccountDetailsFormContent from "./account-details-form-content";
import { accountDetailsValidationSchema, } from "./form";
const AccountDetailsForm = () => {
    var _a, _b;
    const { data: currentUserData, refetch } = useQuery(CurrentUserDocument);
    const [editUser] = useMutation(EditUserDocument);
    const toast = useToast();
    const accountDetailsInitialValues = {
        firstName: (_a = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.firstName) !== null && _a !== void 0 ? _a : "",
        lastName: (_b = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.lastName) !== null && _b !== void 0 ? _b : "",
    };
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield editUser({
                variables: {
                    input: Object.assign({ id: currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.id }, values),
                },
                onCompleted: refetch,
            });
            switch (data === null || data === void 0 ? void 0 : data.editUser.__typename) {
                case "EditUserSuccessfulResponse":
                    toast({
                        title: "Changes saved",
                        status: "success",
                        isClosable: true,
                    });
                    return;
                case "UserNotExistResponse":
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: true,
            });
        }
    }), [currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.currentUser.id, editUser, refetch, toast]);
    return (_jsx(Formik, Object.assign({ initialValues: accountDetailsInitialValues, validationSchema: accountDetailsValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(AccountDetailsFormContent, Object.assign({}, formProps)) })));
};
export default AccountDetailsForm;
