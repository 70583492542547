var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { MenuItem, useToast } from "@chakra-ui/react";
import { CopyCampaignDocument } from "@packages/apollo-hooks";
import React, { useCallback } from "react";
import { IoCopy } from "react-icons/io5";
const CopyCampaign = ({ campaignId }) => {
    const toast = useToast();
    const [copyCampaign] = useMutation(CopyCampaignDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getCampaigns"],
    });
    const handleCopy = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield copyCampaign({
                variables: {
                    input: {
                        campaignId,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.copyCampaign.__typename) {
                case "CampaignSuccessfulCopiedResponse":
                    return;
                case "CampaignNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [campaignId, copyCampaign, toast]);
    return (_jsx(MenuItem, Object.assign({ icon: _jsx(IoCopy, {}), onClick: handleCopy }, { children: "Copy Campaign" })));
};
export default CopyCampaign;
