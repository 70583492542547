const generateButtonsValues = (buttonCount, currentPage, pageCount) => {
    let buttons;
    if (pageCount <= buttonCount) {
        buttons = new Array(pageCount).fill(0).map((_, index) => index);
    }
    else {
        if (currentPage < Math.ceil(buttonCount / 2)) {
            // 1  2  3  4  5  .. 18
            buttons = [
                ...new Array(buttonCount - 2).fill(0).map((_, index) => index),
                "nextArrows",
                pageCount - 1,
            ];
        }
        else if (currentPage >= pageCount - Math.ceil(buttonCount / 2)) {
            // 1  .. 14 15 16 17 18
            buttons = [
                0,
                "prevArrows",
                ...new Array(buttonCount - 2)
                    .fill(pageCount - 1)
                    .map((value, index) => value - index)
                    .reverse(),
            ];
        }
        else {
            // 1  .. 10 11 12 .. 18
            const innerCount = buttonCount - 4;
            buttons = [
                0,
                "prevArrows",
                ...new Array(innerCount)
                    .fill(currentPage - Math.floor(innerCount / 2))
                    .map((value, index) => value + index),
                "nextArrows",
                pageCount - 1,
            ];
        }
    }
    return buttons;
};
export default generateButtonsValues;
