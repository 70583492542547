import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { IconButton, Menu, MenuButton, MenuList, Table, Tbody, Td, Th, Thead, Tr, } from "@chakra-ui/react";
import { DATE_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import { useMemo } from "react";
import { IoEllipsisVerticalOutline } from "react-icons/io5";
import { useSortBy, useTable } from "react-table";
import EmptyState from "../../shared/components/table/empty-state";
import DeleteUserDialog from "./delete-user-dialog";
import EditUser from "./edit-user";
const UsersTable = ({ tableData, setPageNumber }) => {
    const columns = useMemo(() => {
        return [
            {
                Header: "E-mail",
                accessor: "email",
            },
            {
                Header: "First name",
                accessor: "firstName",
            },
            {
                Header: "Last name",
                accessor: "lastName",
            },
            {
                Header: "Created",
                accessor: original => format(new Date(original.createdAt), DATE_FORMAT),
            },
        ];
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData.getUsers.items }, useSortBy);
    return (_jsxs(Table, Object.assign({}, getTableProps(), { children: [_jsx(Thead, { children: headerGroups.map((headerGroup, groupIndex) => (_createElement(Tr, Object.assign({}, headerGroup.getHeaderGroupProps(), { key: groupIndex }),
                    headerGroup.headers.map((column, columnIndex) => (_createElement(Th, Object.assign({}, column.getHeaderProps(), { key: columnIndex }), column.render("Header")))),
                    _jsx(Th, {})))) }), _jsxs(Tbody, Object.assign({}, getTableBodyProps(), { children: [rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (_createElement(Tr, Object.assign({}, row.getRowProps(), { key: rowIndex }),
                            row.cells.map((cell, cellIndex) => (_createElement(Td, Object.assign({}, cell.getCellProps(), { key: cellIndex }), cell.render("Cell")))),
                            _jsx(Td, Object.assign({ textAlign: "right" }, { children: _jsxs(Menu, { children: [_jsx(MenuButton, { as: IconButton, size: "sm", "aria-label": "Options", icon: _jsx(IoEllipsisVerticalOutline, {}), variant: "outline", colorScheme: "gray" }), _jsxs(MenuList, { children: [_jsx(EditUser, { userId: row.original.id }), row.original.role === "USER" && (_jsx(DeleteUserDialog, { setPageNumber: setPageNumber, name: `${row.original.firstName} ${row.original.lastName}`, userId: row.original.id }))] })] }) }))));
                    }), rows.length === 0 && _jsx(EmptyState, { type: "users", columnCount: 5 })] }))] })));
};
export default UsersTable;
