import { v4 } from "uuid";
export const addErrorsAndRunHooks = (data, fields, rowHook, tableHook) => {
    const errors = {};
    const addHookError = (rowIndex, fieldKey, error) => {
        errors[rowIndex] = Object.assign(Object.assign({}, errors[rowIndex]), { [fieldKey]: error });
    };
    if (tableHook) {
        data = tableHook(data, addHookError);
    }
    if (rowHook) {
        data = data.map((value, index) => rowHook(value, (...props) => addHookError(index, ...props), data));
    }
    fields.forEach(field => {
        var _a;
        (_a = field.validations) === null || _a === void 0 ? void 0 : _a.forEach(validation => {
            switch (validation.rule) {
                case "unique": {
                    const values = data.map(entry => entry[field.key]);
                    const taken = new Set(); // Set of items used at least once
                    const duplicates = new Set(); // Set of items used multiple times
                    values.forEach(value => {
                        if (validation.allowEmpty && !value) {
                            // If allowEmpty is set, we will not validate falsy fields such as undefined or empty string.
                            return;
                        }
                        if (taken.has(value)) {
                            duplicates.add(value);
                        }
                        else {
                            taken.add(value);
                        }
                    });
                    const indexes = [];
                    duplicates.forEach(duplicate => {
                        const ind = values.findIndex(el => el === duplicate);
                        indexes.push(ind);
                    });
                    values.forEach((value, index) => {
                        if (duplicates.has(value)) {
                            errors[index] = Object.assign(Object.assign({}, errors[index]), { [field.key]: {
                                    level: validation.level || "error",
                                    message: validation.errorMessage || "Field must be unique",
                                    isFirst: indexes.includes(index),
                                } });
                        }
                    });
                    break;
                }
                case "required": {
                    data.forEach((entry, index) => {
                        if (entry[field.key] === null ||
                            entry[field.key] === undefined ||
                            entry[field.key] === "") {
                            errors[index] = Object.assign(Object.assign({}, errors[index]), { [field.key]: {
                                    level: validation.level || "error",
                                    message: validation.errorMessage || "Field is required",
                                    isFirst: false,
                                } });
                        }
                    });
                    break;
                }
                case "regex": {
                    const regex = new RegExp(validation.value, validation.flags);
                    data.forEach((entry, index) => {
                        var _a, _b;
                        const value = (_b = (_a = entry[field.key]) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "";
                        if (!value.match(regex)) {
                            errors[index] = Object.assign(Object.assign({}, errors[index]), { [field.key]: {
                                    level: validation.level || "error",
                                    message: validation.errorMessage ||
                                        `Field did not match the regex /${validation.value}/${validation.flags} `,
                                    isFirst: false,
                                } });
                        }
                    });
                    break;
                }
            }
        });
    });
    return data.map((value, index) => {
        // This is required only for table. Mutates to prevent needless rerenders
        if (!("__index" in value)) {
            value.__index = v4();
        }
        const newValue = value;
        if (errors[index]) {
            return Object.assign(Object.assign({}, newValue), { __errors: errors[index] });
        }
        if (!errors[index] && (value === null || value === void 0 ? void 0 : value.__errors)) {
            return Object.assign(Object.assign({}, newValue), { __errors: null });
        }
        return newValue;
    });
};
