var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, IconButton, useToast, } from "@chakra-ui/react";
import { DeleteTemplateDocument, } from "@packages/apollo-hooks";
import React, { useCallback, useRef, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
const DeleteTemplate = ({ templateId, templateName, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();
    const toast = useToast();
    const [deleteTemplate] = useMutation(DeleteTemplateDocument, {
        refetchQueries: ["getTemplates"],
    });
    const handleDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield deleteTemplate({
                variables: {
                    input: { templateId },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.deleteTemplate.__typename) {
                case "DeleteTemplateSuccessfulResponse":
                    onClose();
                    return;
                case "TemplateNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Template doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [deleteTemplate, templateId, toast]);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { size: "md", fontSize: "md", variant: "outline_color", "aria-label": "Delete template", icon: _jsx(IoTrashOutline, {}), onClick: () => setIsOpen(true) }), _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Delete Template" })), _jsxs(AlertDialogBody, { children: ["Do you want to delete ", templateName, "?"] }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", colorScheme: "gray", ref: cancelRef.current, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ colorScheme: "red", variant: "solid", onClick: () => {
                                            handleDelete();
                                            onClose();
                                        }, ml: 3 }, { children: "Delete" }))] })] }) }) }))] }));
};
export default DeleteTemplate;
