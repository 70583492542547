import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Stack, Text, } from "@chakra-ui/react";
import { IntegrationProviders } from "@packages/apollo-hooks";
import { Form } from "formik";
import InputField from "../../../shared/components/form/input-field";
import IntegrationButton from "../integration-button";
const SesIntegrationFormContent = ({ submitForm, }) => {
    return (_jsxs(Form, { children: [_jsx(ModalHeader, { children: "Amazon Web Services Integration" }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: _jsxs(Stack, Object.assign({ spacing: 3 }, { children: [_jsx(Text, Object.assign({ color: "rgba(113, 128, 150, 1)" }, { children: "This email address will be verified by Amazon Web Services to integrate with Motar.me." })), _jsx(InputField, { type: "email", name: "email", placeholder: "Enter e-mail", hasPadding: false })] })) }), _jsx(ModalFooter, { children: _jsx(IntegrationButton, { type: IntegrationProviders.Ses, onClick: submitForm }) })] }));
};
export default SesIntegrationFormContent;
