import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useIsAdmin from "../../common/helpers/use-is-admin";
import routesPaths from "../routing/route-paths";
import PageContent from "../shared/components/page/page-content";
import PageHeader from "../shared/components/page/page-header";
import PageTitle from "../shared/components/page/page-header/page-title";
import SettingsContent from "./settings-content";
const Settings = () => {
    const isAdmin = useIsAdmin();
    const navigate = useNavigate();
    useEffect(() => {
        if (isAdmin) {
            navigate(routesPaths.ADMIN_PANEL);
        }
    }, [isAdmin, navigate]);
    if (isAdmin)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { children: _jsx(PageTitle, { title: "Settings" }) }), _jsx(PageContent, { children: _jsx(SettingsContent, {}) })] }));
};
export default Settings;
