import styled from "@emotion/styled";
import theme from "../../../../../../../common/theme";
export const WIDTH = 24;
export const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  text-align: center;

  border-radius: 4px;
  overflow: hidden;
  background: white;

  user-select: none;
`;
export const Header = styled.div `
  padding: 10px;
  background: #f7fafc;
`;
export const Title = styled.h3 `
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.gray[700]};
  margin: auto;
`;
export const Grid = styled.div `
  display: grid;
  grid-template-columns: ${WIDTH}px 1px ${WIDTH}px ${WIDTH}px;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 8px;
  grid-auto-flow: column;
  justify-content: center;
  padding: 3px 9px;
  color: ${theme.colors.blue[900]};
`;
