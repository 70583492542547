import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Text } from "@chakra-ui/layout";
import { GetContactListsDocument } from "@packages/apollo-hooks";
import { useState } from "react";
import Loader from "../../shared/components/loader";
import Pagination from "../../shared/components/table/pagination";
import { DEFAULT_INITIAL_PAGE_SIZE, DEFAULT_PAGE_INDEX, } from "../../shared/components/table/pagination/pagination";
import TableFilterPanel from "../../shared/components/table/table-filter-panel/table-filter-panel";
import ContactListsTable from "./contact-lists-table";
const ContactLists = () => {
    const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_INDEX);
    const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE);
    const [searchTerm, setSearchTerm] = useState("");
    const { data, loading, error } = useQuery(GetContactListsDocument, {
        variables: {
            pageNumber,
            pageSize,
            searchPhrase: searchTerm,
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    const handlePrevPage = () => {
        setPageNumber(pageNumber - 1);
    };
    const handleNextPage = () => {
        setPageNumber(pageNumber + 1);
    };
    const handleChangeSearch = (value) => {
        if (value !== searchTerm) {
            setSearchTerm(value);
            setPageNumber(DEFAULT_PAGE_INDEX);
        }
    };
    if (loading)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsx(Box, Object.assign({ maxW: { base: "xl", md: "7xl" }, mx: "auto" }, { children: _jsx(Box, Object.assign({ overflowX: "auto" }, { children: data && (_jsxs(_Fragment, { children: [_jsx(TableFilterPanel, { searchTerm: searchTerm, handleChangeSearch: handleChangeSearch }), _jsx(ContactListsTable, { tableData: data, setPageNumber: setPageNumber }), data.getContactLists.items.length > 0 && (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Text, Object.assign({ color: "gray.600", fontSize: "sm" }, { children: [data.getContactLists.totalItems, " list", data.getContactLists.totalItems !== 1 && "s"] })), _jsx(Pagination, { pageIndex: pageNumber, pageCount: data.getContactLists.pageCount, canPreviousPage: pageNumber > 0, canNextPage: pageNumber < data.getContactLists.pageCount - 1, gotoPage: setPageNumber, previousPage: handlePrevPage, nextPage: handleNextPage, pageSize: pageSize, onPageSizeChange: setPageSize })] })))] })) })) })));
};
export default ContactLists;
