import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Stack, StackDivider, TabPanel, Text, useToast, } from "@chakra-ui/react";
import { GetIntegrationsStatusDocument, IntegrationProviders, } from "@packages/apollo-hooks";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routesPaths from "../../routing/route-paths";
import Loader from "../../shared/components/loader";
import IntegrationCard from "../integrations/integration-card";
const IntegrationsPanel = props => {
    const { search } = useLocation();
    const toast = useToast();
    const navigate = useNavigate();
    const { data, loading } = useQuery(GetIntegrationsStatusDocument, {
        fetchPolicy: "no-cache",
    });
    const microsoftIntegration = data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.microsoftIntegration;
    const googleIntegration = data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.googleIntegration;
    const sesIntegration = data === null || data === void 0 ? void 0 : data.getIntegrationsStatus.sesIntegration;
    React.useEffect(() => {
        if (search && search === "?error=true") {
            navigate(routesPaths.SETTINGS);
            toast({
                title: `Something went wrong while activating integration. Please try again.`,
                status: "error",
                isClosable: true,
            });
        }
    }, [navigate, search, toast]);
    if (loading) {
        return (_jsx(TabPanel, Object.assign({}, props, { children: _jsx(Loader, {}) })));
    }
    return (_jsxs(TabPanel, Object.assign({}, props, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold", mb: "16px" }, { children: "Integrations" })), _jsxs(Stack, Object.assign({ spacing: "0", divider: _jsx(StackDivider, { borderColor: "gray.100" }) }, { children: [googleIntegration && (_jsx(IntegrationCard, { type: IntegrationProviders.Google, isActive: googleIntegration.isEnabled && googleIntegration.isValid, email: googleIntegration.email })), microsoftIntegration && (_jsx(IntegrationCard, { type: IntegrationProviders.Microsoft, isActive: microsoftIntegration.isEnabled && microsoftIntegration.isValid, email: microsoftIntegration.email })), sesIntegration && (_jsx(IntegrationCard, { type: IntegrationProviders.Ses, isActive: sesIntegration.isEnabled && sesIntegration.isValid, email: sesIntegration.email }))] }))] })));
};
export default IntegrationsPanel;
