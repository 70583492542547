import { getUserToken } from "../../../common/helpers/user-token";
const imageUploadHandler = (blobInfo, progress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const userToken = getUserToken();
    xhr.open("POST", `${process.env.REACT_APP_API_URL}/image/upload`);
    if (userToken) {
        xhr.setRequestHeader("Authorization", `Bearer ${userToken.token}`);
    }
    xhr.upload.onprogress = e => {
        progress((e.loaded / e.total) * 100);
    };
    xhr.onload = () => {
        if (xhr.status === 403) {
            reject({ message: "HTTP Error: " + xhr.status, remove: true });
            return;
        }
        if (xhr.status < 200 || xhr.status >= 300) {
            reject("HTTP Error: " + xhr.status);
            return;
        }
        const json = JSON.parse(xhr.responseText);
        if (!json || typeof json.location != "string") {
            reject("Invalid JSON: " + xhr.responseText);
            return;
        }
        resolve(json.location);
    };
    xhr.onerror = () => {
        reject("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
    };
    const formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());
    xhr.send(formData);
});
export default imageUploadHandler;
