var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, ModalBody, Stack, Text } from "@chakra-ui/react";
import { DropZone } from "./components/DropZone";
import { useRsi } from "../../hooks/useRsi";
import { ExampleTable } from "./components/ExampleTable";
import { useCallback, useState } from "react";
export const UploadStep = ({ onContinue }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { translations, fields } = useRsi();
    const handleOnContinue = useCallback((data, file) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield onContinue(data, file);
        setIsLoading(false);
    }), [onContinue]);
    return (_jsxs(ModalBody, Object.assign({ padding: "8", bg: "gray.50" }, { children: [_jsxs(Stack, Object.assign({ spacing: "8" }, { children: [_jsx(Heading, { children: translations.uploadStep.title }), _jsx(Text, Object.assign({ fontSize: "2xl", fontWeight: "semibold" }, { children: translations.uploadStep.manifestTitle })), _jsx(Box, Object.assign({ position: "relative" }, { children: _jsx(ExampleTable, { fields: fields }) }))] })), _jsx(DropZone, { onContinue: handleOnContinue, isLoading: isLoading })] })));
};
