import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "../../../../../../../../common/theme";
export const Weekday = styled.div `
  text-transform: uppercase;
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.bold};
  font-stretch: normal;
  color: rgba(60, 60, 67, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MARGIN = 2;
const getDayColor = (dayInfo) => {
    if (dayInfo.isNextMonth || dayInfo.isPrevMonth || dayInfo.isDisabled) {
        // lighter colors
        if (dayInfo.isHoliday) {
            return theme.colors.gray[200];
        }
        return theme.colors.gray[200];
    }
    if (dayInfo.isHoliday) {
        return theme.colors.gray[700];
    }
    return theme.colors.blue[900];
};
export const Day = styled.div `
  position: absolute;
  top: ${MARGIN}px;
  left: ${MARGIN}px;
  height: calc(100% - ${MARGIN * 2}px);
  width: calc(100% - ${MARGIN * 2}px);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${theme.fontSizes.xl};

  ${p => p.dayInfo.isDisabled
    ? css `
          cursor: normal;
        `
    : css `
          cursor: pointer;
          :hover {
            background: ${theme.colors.cyan[50]};
          }
        `};

  color: ${p => getDayColor(p.dayInfo)};

  ${p => p.dayInfo.isToday &&
    css `
      background: ${theme.colors.gray[50]};
    `};

  ${p => (p.dayInfo.isFirstSelection || p.dayInfo.isLastSelection) &&
    css `
      background: ${theme.colors.cyan[50]};
      color: ${theme.colors.cyan[400]};
      font-weight: bold;
      :hover {
        background: ${theme.colors.cyan[50]};
      }
    `};

  ${p => p.dayInfo.isSelected &&
    css `
      ::before {
        content: "";
        z-index: -1;
        background: ${theme.colors.cyan[50]};
        top: 0;
        bottom: 0;
        right: ${p.dayInfo.isLastSelection ? "50%" : `-${MARGIN}px`};
        left: ${p.dayInfo.isFirstSelection ? "50%" : `-${MARGIN}px`};
        position: absolute;
      }
    `};

  border-radius: ${theme.radii.base};
`;
