var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useRsi } from "../../hooks/useRsi";
import { TemplateColumn } from "./components/TemplateColumn";
import { ColumnGrid } from "./components/ColumnGrid";
import { setColumn } from "./utils/setColumn";
import { normalizeTableData } from "./utils/normalizeTableData";
import { getMatchedColumns } from "./utils/getMatchedColumns";
import { UnmatchedFieldsAlert } from "../../components/Alerts/UnmatchedFieldsAlert";
import { findUnmatchedRequiredFields } from "./utils/findUnmatchedRequiredFields";
export var ColumnType;
(function (ColumnType) {
    ColumnType[ColumnType["empty"] = 0] = "empty";
    ColumnType[ColumnType["ignored"] = 1] = "ignored";
    ColumnType[ColumnType["matched"] = 2] = "matched";
    ColumnType[ColumnType["matchedCheckbox"] = 3] = "matchedCheckbox";
    ColumnType[ColumnType["matchedSelect"] = 4] = "matchedSelect";
    ColumnType[ColumnType["matchedSelectOptions"] = 5] = "matchedSelectOptions";
})(ColumnType || (ColumnType = {}));
export const MatchColumnsStep = ({ data, headerValues, onContinue, onGoBack, }) => {
    const toast = useToast();
    const { fields, autoMapHeaders, autoMapDistance, translations } = useRsi();
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState(
    // Do not remove spread, it indexes empty array elements, otherwise map() skips over them
    [...headerValues].map((value, index) => ({
        type: ColumnType.empty,
        index,
        header: value !== null && value !== void 0 ? value : "",
    })));
    const [showUnmatchedFieldsAlert, setShowUnmatchedFieldsAlert] = useState(false);
    const onChange = useCallback((value, columnIndex) => {
        const field = fields.find(field => field.key === value);
        const existingFieldIndex = columns.findIndex(column => "value" in column && column.value === field.key);
        setColumns(columns.map((column, index) => {
            if (columnIndex === index) {
                setColumn(column, field, data);
            }
            if (columnIndex === index) {
                return setColumn(column, field, data);
            }
            else if (index === existingFieldIndex) {
                toast({
                    status: "error",
                    title: translations.matchColumnsStep.duplicateColumnWarningTitle,
                    description: translations.matchColumnsStep.duplicateColumnWarningDescription,
                    isClosable: true,
                });
                return setColumn(column);
            }
            else {
                return column;
            }
        }));
    }, [
        columns,
        data,
        fields,
        toast,
        translations.matchColumnsStep.duplicateColumnWarningDescription,
        translations.matchColumnsStep.duplicateColumnWarningTitle,
    ]);
    const unmatchedRequiredFields = useMemo(() => findUnmatchedRequiredFields(fields, columns), [fields, columns]);
    const handleOnContinue = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (unmatchedRequiredFields.length > 0) {
            setShowUnmatchedFieldsAlert(true);
        }
        else {
            setIsLoading(true);
            yield onContinue(normalizeTableData(columns, data, fields), data, columns);
            setIsLoading(false);
        }
    }), [unmatchedRequiredFields.length, onContinue, columns, data, fields]);
    const handleAlertOnContinue = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setShowUnmatchedFieldsAlert(false);
        setIsLoading(true);
        yield onContinue(normalizeTableData(columns, data, fields), data, columns);
        setIsLoading(false);
    }), [onContinue, columns, data, fields]);
    useEffect(() => {
        if (autoMapHeaders) {
            setColumns(getMatchedColumns(columns, fields, data, autoMapDistance));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(UnmatchedFieldsAlert, { isOpen: showUnmatchedFieldsAlert, onClose: () => setShowUnmatchedFieldsAlert(false), fields: unmatchedRequiredFields, onConfirm: handleAlertOnContinue }), _jsx(ColumnGrid, { onGoBack: onGoBack, columns: columns, onContinue: handleOnContinue, isLoading: isLoading, data: data, headerValues: headerValues, templateColumn: column => (_jsx(TemplateColumn, { column: column, onChange: onChange })) })] }));
};
