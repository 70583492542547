import { jsx as _jsx } from "react/jsx-runtime";
import { SelectColumn } from "react-data-grid";
import styled from "@emotion/styled";
const TextEditor = styled.input `
  appearance: none;

  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding-block: 0;
  padding-inline: 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: var(--rdg-color);
  background-color: var(--rdg-background-color);

  font-family: inherit;
  font-size: var(--rdg-font-size);

  &:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
  }

  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;
function autoFocusAndSelect(input) {
    input === null || input === void 0 ? void 0 : input.focus();
    input === null || input === void 0 ? void 0 : input.select();
}
export default function textEditor({ row, column, onRowChange, onClose, }) {
    return (_jsx(TextEditor, { style: {
            width: "100%",
        }, className: "rdg-text-editor", ref: autoFocusAndSelect, value: row[column.key], onChange: event => {
            onRowChange(Object.assign(Object.assign({}, row), { [column.key]: event.target.value }));
        }, onBlur: () => onClose(true, false) }));
}
export const generateColumns = (fields) => {
    return [
        SelectColumn,
        ...fields.map(field => {
            return {
                key: field.key,
                name: field.label,
                minWidth: 150,
                resizable: true,
                editable: field.fieldType.type !== "checkbox",
                cellClass: (row) => {
                    var _a, _b;
                    switch ((_b = (_a = row.__errors) === null || _a === void 0 ? void 0 : _a[field.key]) === null || _b === void 0 ? void 0 : _b.level) {
                        case "error":
                            return "rdg-cell-error";
                        case "warning":
                            return "rdg-cell-warning";
                        case "info":
                            return "rdg-cell-info";
                        default:
                            return "";
                    }
                },
                renderEditCell: textEditor,
            };
        }),
    ];
};
