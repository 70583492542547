var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { EditContactListDocument, } from "@packages/apollo-hooks";
import React, { useRef, useState } from "react";
import EditablePageTitle from "../../../shared/components/page/page-header/editable-page-title";
const EditContactListName = ({ id, title, initialName, handleChangeListName, handleBackIconClick, }) => {
    const toast = useToast();
    const nameInputRef = useRef(null);
    const [nameSaved, setNameSaved] = useState(false);
    const [editContactList] = useMutation(EditContactListDocument);
    const handleEdit = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield editContactList({
                variables: {
                    input: {
                        contactListId: id,
                        name: title,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.editContactList.__typename) {
                case "ContactListSuccessfulEditedResponse":
                    setNameSaved(true);
                    return;
                case "ContactListNameTakenResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Contact list name taken",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "ContactListNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Contact list doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [editContactList, id, setNameSaved, title, toast]);
    return (_jsx(EditablePageTitle, { title: title, initialName: initialName, handleBackIconClick: handleBackIconClick, handleEdit: handleEdit, handleChangeListName: handleChangeListName, nameInputRef: nameInputRef, nameSaved: nameSaved }));
};
export default EditContactListName;
