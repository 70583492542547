import { addMonths, addYears, isBefore, setMonth, setYear, subMonths, subYears, isSameDay, } from "date-fns";
const processSetDate = (selection, minDate, maxDate, rangeSelectionEnabled, chosenDate) => {
    if (minDate && isBefore(chosenDate, minDate)) {
        return {};
    }
    if (maxDate && isBefore(maxDate, chosenDate)) {
        return {};
    }
    if (!rangeSelectionEnabled) {
        return {
            displayDate: chosenDate,
            selection: selection && isSameDay(selection, chosenDate)
                ? undefined
                : chosenDate,
        };
    }
    if (!selection) {
        return {
            displayDate: chosenDate,
            selection: chosenDate,
        };
    }
    if (selection instanceof Date) {
        if (isBefore(chosenDate, selection)) {
            return {
                displayDate: chosenDate,
                selection: chosenDate,
            };
        }
        return {
            displayDate: chosenDate,
            selection: { start: selection, end: chosenDate },
        };
    }
    return {
        displayDate: chosenDate,
        selection: undefined,
    };
};
const calendarReducer = (rangeSelectionEnabled) => (prevState, action) => {
    switch (action.type) {
        case "forward":
            switch (prevState.displayLevel) {
                case "days":
                    return Object.assign(Object.assign({}, prevState), { displayDate: addMonths(prevState.displayDate, 1) });
                case "months":
                    return Object.assign(Object.assign({}, prevState), { displayDate: addYears(prevState.displayDate, 1) });
                case "years":
                    return Object.assign(Object.assign({}, prevState), { displayDate: addYears(prevState.displayDate, 10) });
                default:
                    return prevState;
            }
        case "backward":
            switch (prevState.displayLevel) {
                case "days":
                    return Object.assign(Object.assign({}, prevState), { displayDate: subMonths(prevState.displayDate, 1) });
                case "months":
                    return Object.assign(Object.assign({}, prevState), { displayDate: subYears(prevState.displayDate, 1) });
                case "years":
                    return Object.assign(Object.assign({}, prevState), { displayDate: subYears(prevState.displayDate, 10) });
                default:
                    return prevState;
            }
        case "setDate":
            return Object.assign(Object.assign({}, prevState), processSetDate(prevState.selection, prevState.minDate, prevState.maxDate, rangeSelectionEnabled, action.date));
        case "setDisplayMonth":
            return Object.assign(Object.assign({}, prevState), { displayLevel: "days", displayDate: setMonth(prevState.displayDate, action.month) });
        case "setDisplayYear":
            return Object.assign(Object.assign({}, prevState), { displayLevel: "months", displayDate: setYear(prevState.displayDate, action.year) });
        case "goUp": {
            return Object.assign(Object.assign({}, prevState), { displayLevel: prevState.displayLevel === "days" ? "months" : "years" });
        }
        case "resetView": {
            return Object.assign(Object.assign({}, prevState), { displayLevel: "days", displayDate: !prevState.selection
                    ? new Date()
                    : prevState.selection instanceof Date
                        ? prevState.selection
                        : prevState.selection.start });
        }
        case "setSelection": {
            return Object.assign(Object.assign({}, prevState), { selection: action.selection });
        }
        case "setMinDate": {
            return Object.assign(Object.assign({}, prevState), { minDate: action.minDate });
        }
        case "setMaxDate": {
            return Object.assign(Object.assign({}, prevState), { maxDate: action.maxDate });
        }
    }
};
export default calendarReducer;
