var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { EnableSesIntegrationDocument } from "@packages/apollo-hooks";
import { Formik } from "formik";
import { enableSesIntegrationInitialValues, enableSesIntegrationValidationSchema, } from "./form";
import SesIntegrationFormContent from "./ses-integration-form-content";
const EnableSesIntegrationForm = ({ closeModal }) => {
    const [enableSes] = useMutation(EnableSesIntegrationDocument);
    const toast = useToast();
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield enableSes({
                variables: {
                    input: values,
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.enableSesIntegration.className) {
                case "EnableSesIntegrationSuccessfulResponse":
                    toast({
                        title: "Activation email sent. Check your inbox.",
                        status: "success",
                        isClosable: false,
                    });
                    closeModal();
                    break;
                case "EnableSesIntegrationInvalidResponse":
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    });
    return (_jsx(Formik, Object.assign({ initialValues: enableSesIntegrationInitialValues, validationSchema: enableSesIntegrationValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(SesIntegrationFormContent, Object.assign({}, formProps)) })));
};
export default EnableSesIntegrationForm;
