import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import useContactListsOptions from "../../../../contacts/single-contact-list/add-contact/use-contact-lists-options";
import useContactListQuery from "../../../../contacts/single-contact-list/hooks/use-contact-list-query";
import InputField from "../../../../shared/components/form/input-field";
import SelectField from "../../../../shared/components/form/select-field";
import { translations } from "../../../translationsRSIProps";
const FormTopSection = () => {
    var _a, _b;
    const { id } = useParams();
    const { data } = useContactListQuery(id);
    const { contactListsOptions, loading } = useContactListsOptions(true);
    const isSingleList = !!id;
    const isNewList = isSingleList && data && !((_a = data.getContactList) === null || _a === void 0 ? void 0 : _a.isEdited);
    const heading = isNewList
        ? translations.summaryStep.newListSectionTitle
        : translations.summaryStep.sectionTitle;
    const description = !isSingleList ? ("Create a new list name for these contacts, OR select an existing list from the dropdown box") : isNewList ? ("Create a new list name for these contacts") : (_jsxs(_Fragment, { children: ["The contacts will be imported to list ", _jsx("b", { children: (_b = data === null || data === void 0 ? void 0 : data.getContactList) === null || _b === void 0 ? void 0 : _b.name })] }));
    const action = !isSingleList ? (_jsxs(Stack, Object.assign({ spacing: "2", direction: "row", alignItems: "center" }, { children: [_jsx(InputField, { hasPadding: false, type: "text", name: "contactListName", placeholder: "New Contact List Name" }), _jsx(Text, { children: "or" }), !loading && (_jsx(SelectField, { hasPadding: false, name: "contactListId", options: contactListsOptions }))] }))) : isNewList ? (_jsx(Box, Object.assign({ maxW: "250" }, { children: _jsx(InputField, { hasPadding: false, type: "text", name: "contactListName", placeholder: "New Contact List Name" }) }))) : null;
    return (_jsxs(_Fragment, { children: [_jsx(Heading, { children: heading }), _jsx(Text, { children: description }), action] }));
};
export default FormTopSection;
