import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LeftSide from "../../app/public-layout/left-side";
import LoginForm from "./login-form";
const Login = () => {
    const toast = useToast();
    const location = useLocation();
    useEffect(() => {
        if (location.state) {
            const { passwordChanged, accountVerifiedSuccessfully, accountVerificationFailed, logout, } = location.state;
            if (passwordChanged === true) {
                toast({
                    title: "Your password was changed.",
                    description: "You can log in and start using Motar.me.",
                    status: "success",
                    isClosable: true,
                });
            }
            if (accountVerifiedSuccessfully === true) {
                toast({
                    title: "Account verified",
                    description: "You can log in and start using Motar.me.",
                    status: "success",
                    isClosable: true,
                });
            }
            if (accountVerificationFailed === true) {
                toast({
                    title: "Verification failed.",
                    status: "error",
                    isClosable: true,
                });
            }
            if (logout === true) {
                toast({
                    title: "You have been logged out successfully.",
                    status: "success",
                    isClosable: true,
                });
            }
        }
    }, [location.state, toast]);
    return (_jsxs(LeftSide, { children: [_jsxs(Text, Object.assign({ mx: "3", my: "5", fontSize: "sm", color: "gray.600" }, { children: ["Create campaigns and send e-mails as a Company using", " ", _jsx("b", { children: "Motar.me account." })] })), _jsx(LoginForm, {})] }));
};
export default Login;
