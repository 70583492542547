import { appEventTypes } from "./app-event-target";
class TokenChangeEvent extends CustomEvent {
    constructor(userToken) {
        super(appEventTypes.TOKEN_CHANGE, {
            detail: {
                userToken,
            },
        });
    }
}
export default TokenChangeEvent;
