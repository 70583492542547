import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement, Icon, } from "@chakra-ui/react";
import { Field } from "formik";
import React, { forwardRef } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
const InputField = forwardRef((props, ref) => {
    const { label, name, type, placeholder, hasPadding = true, autoFocus, showCheckmark = false, onChangeCapture, } = props;
    return (_jsx(Field, Object.assign({ name: name, h: 10 }, { children: ({ field, meta }) => (_jsxs(FormControl, Object.assign({ p: hasPadding ? "3" : "0", position: "relative", isInvalid: !!(meta.touched && meta.error) }, { children: [label && _jsx(FormLabel, { children: label }), _jsxs(InputGroup, { children: [_jsx(Input, Object.assign({ ref: ref, type: type, placeholder: placeholder, autoFocus: autoFocus, onChangeCapture: onChangeCapture }, field)), _jsx(InputRightElement, { children: showCheckmark && (_jsx(Icon, { as: IoCheckmarkSharp, color: "green.500", w: 5, h: 5 })) })] }), _jsx(FormErrorMessage, { children: meta.touched && meta.error ? meta.error : undefined })] }))) })));
});
InputField.displayName = "InputField";
export default InputField;
