const error = {
    parts: ["text"],
    baseStyle: {
        text: {
            color: "gray.500",
            fontSize: "xs",
        },
    },
};
export default error;
