import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import MessagesTab from "./messages-tab";
import OverviewTab from "./overview-tab";
const OverviewAndMessagesTab = () => {
    const { emailId } = useParams();
    return (_jsxs(Tabs, Object.assign({ variant: "line", mt: "8", colorScheme: "cyan", defaultIndex: emailId ? 1 : 0 }, { children: [_jsxs(TabList, { children: [_jsx(Tab, Object.assign({ w: "50%" }, { children: "Overview" })), _jsx(Tab, Object.assign({ w: "50%" }, { children: "Messages" }))] }), _jsxs(TabPanels, { children: [_jsx(TabPanel, { children: _jsx(OverviewTab, {}) }), _jsx(TabPanel, { children: _jsx(MessagesTab, {}) })] })] })));
};
export default OverviewAndMessagesTab;
