import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { GetTemplateFolderDocument, } from "@packages/apollo-hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Search from "../../shared/components/table/table-filter-panel/search";
import EditTemplateFolderName from "../edit-template-folder-name";
import DeleteTemplateFolder from "./delete-template-folder";
import TemplatesList from "./templates-list";
import TemplatesSidebar from "./templates-sidebar";
export const FOLDER_NAMES = ["recent", "favorites"];
const TemplatesBrowser = () => {
    const { id } = useParams();
    const [queryVariables, setQueryVariables] = useState({ searchPhrase: "", isFavorite: false, templateFolderId: "" });
    const { data, loading } = useQuery(GetTemplateFolderDocument, {
        variables: {
            templateFolderId: id,
        },
        fetchPolicy: "network-only",
        // to set loading to true while refetch
        notifyOnNetworkStatusChange: true,
    });
    useEffect(() => {
        if (id === "favorites") {
            setQueryVariables(p => {
                return {
                    searchPhrase: p.searchPhrase,
                    isFavorite: true,
                    templateFolderId: "",
                };
            });
        }
        else if (id === "recent") {
            setQueryVariables(p => {
                return {
                    searchPhrase: p.searchPhrase,
                    isFavorite: false,
                    templateFolderId: "",
                };
            });
        }
        else if (id && id.length > 0) {
            setQueryVariables(p => {
                return {
                    searchPhrase: p.searchPhrase,
                    isFavorite: false,
                    templateFolderId: id,
                };
            });
        }
        else {
            setQueryVariables(p => {
                return {
                    searchPhrase: p.searchPhrase,
                    isFavorite: false,
                    templateFolderId: "",
                };
            });
        }
    }, [id]);
    const handleChangeSearch = (value) => {
        if (value !== queryVariables.searchPhrase) {
            setQueryVariables(p => {
                return {
                    searchPhrase: value,
                    isFavorite: p.isFavorite,
                    templateFolderId: p.templateFolderId,
                };
            });
        }
    };
    const appFolder = id || FOLDER_NAMES[0];
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ width: "364px", mb: "4" }, { children: _jsx(Search, { term: queryVariables.searchPhrase, handleChangeSearch: handleChangeSearch, placeholder: "Filter by name..." }) })), _jsxs(Flex, Object.assign({ borderWidth: "1px", borderColor: "gray.200", height: "calc(100vh - 212px)", borderRadius: "8px" }, { children: [_jsx(TemplatesSidebar, {}), _jsxs(Box, Object.assign({ p: "4", backgroundColor: "white", w: "100%", borderRightRadius: "8px" }, { children: [_jsx(Flex, Object.assign({ justifyContent: "space-between", alignItems: "center", pb: "2", mb: "2" }, { children: _jsxs(HStack, Object.assign({ spacing: "4" }, { children: [queryVariables.searchPhrase && (_jsxs(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: ["Search for '", queryVariables.searchPhrase, "'"] }))), !queryVariables.searchPhrase && appFolder === FOLDER_NAMES[0] && (_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Recent" }))), !queryVariables.searchPhrase && appFolder === FOLDER_NAMES[1] && (_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Favorites" }))), !queryVariables.searchPhrase &&
                                            !FOLDER_NAMES.includes(appFolder) &&
                                            !loading &&
                                            data &&
                                            data.getTemplateFolder && (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: data.getTemplateFolder.name })), _jsxs(HStack, { children: [_jsx(DeleteTemplateFolder, { templateFolderId: data.getTemplateFolder.id, templateFolderName: data.getTemplateFolder.name }), _jsx(EditTemplateFolderName, { templateFolderId: data.getTemplateFolder.id, templateFolderName: data.getTemplateFolder.name })] })] }))] })) })), _jsx(TemplatesList, { queryVariables: queryVariables, appFolder: appFolder })] }))] }))] }));
};
export default TemplatesBrowser;
