var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Button, HStack } from "@chakra-ui/react";
import { CampaignStatuses, GetCampaignDetailsDocument, } from "@packages/apollo-hooks";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { IoCheckmarkOutline, IoMailOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import useOnClickOutside from "../../../common/helpers/use-on-click-outside";
import useOnClickOutsideForm from "../../../common/helpers/use-on-click-outside-form";
import routesPaths from "../../routing/route-paths";
import Loader from "../../shared/components/loader";
import PageContent from "../../shared/components/page/page-content";
import PageHeader from "../../shared/components/page/page-header";
import PageTitle from "../../shared/components/page/page-header/page-title";
import useSaveAsDraft from "../helpers/use-save-as-draft";
import useStartCampaign from "../helpers/use-start-campaign";
import ClickOutsideAlertDialog from "./click-outside-alert-dialog";
import EditCampaignContacts from "./edit-campaign-contacts";
import EditCampaignDetails from "./edit-campaign-details";
import EditCampaignMessages from "./edit-campaign-messages-new";
import { campaignEditorInitialValues, campaignEditorValidationSchema, } from "./form";
const EditCampaign = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [outsideClickEvent, setOutsideClickEvent] = useState(null);
    const wrapperRef = useRef(null);
    const formikRef = useRef(null);
    const buttonRef = useRef(null);
    const { data, loading, error } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId: id,
        },
    });
    const { handleStartCampaign } = useStartCampaign(id);
    const { handleSaveAsDraft } = useSaveAsDraft(id);
    useEffect(() => {
        var _a, _b, _c;
        if (data) {
            if (((_a = data.getCampaignDetails) === null || _a === void 0 ? void 0 : _a.status) === CampaignStatuses.Active ||
                ((_b = data.getCampaignDetails) === null || _b === void 0 ? void 0 : _b.status) === CampaignStatuses.Completed) {
                navigate(routesPaths.CAMPAIGN.replace(":id", id));
            }
            else if (((_c = data.getCampaignDetails) === null || _c === void 0 ? void 0 : _c.status) === CampaignStatuses.Deleted) {
                navigate(routesPaths.CAMPAIGNS);
            }
        }
    }, [data, id, navigate]);
    useOnClickOutside(buttonRef, () => {
        var _a, _b;
        if ((_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.errors) {
            (_b = formikRef.current) === null || _b === void 0 ? void 0 : _b.setErrors({});
        }
    });
    useOnClickOutsideForm(wrapperRef, (e) => {
        var _a;
        if ((_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.dirty) {
            setIsAlertDialogOpen(true);
            setOutsideClickEvent(e);
        }
    });
    const onClose = () => setIsAlertDialogOpen(false);
    const handleContinueClick = useCallback(() => {
        if (outsideClickEvent !== null && outsideClickEvent.target) {
            const closestEl = outsideClickEvent.target.closest("div.outside-click");
            if (closestEl) {
                closestEl.click();
            }
            else {
                outsideClickEvent.target.click();
            }
        }
    }, [outsideClickEvent]);
    const handleSaveClick = useCallback(() => {
        var _a;
        if (outsideClickEvent !== null && outsideClickEvent.target) {
            const closestEl = outsideClickEvent.target.closest("div.outside-click");
            // formikRef.current?.handleSubmit();
            if ((_a = formikRef.current) === null || _a === void 0 ? void 0 : _a.values) {
                handleSaveAsDraft(formikRef.current.values);
            }
            if (closestEl) {
                closestEl.click();
            }
            else {
                outsideClickEvent.target.click();
            }
        }
    }, [handleSaveAsDraft, outsideClickEvent]);
    if (loading || !data || !data.getCampaignDetails)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Formik, Object.assign({ initialValues: campaignEditorInitialValues(data === null || data === void 0 ? void 0 : data.getCampaignDetails), validationSchema: campaignEditorValidationSchema, innerRef: formikRef, onSubmit: () => { }, validateOnChange: false, validateOnBlur: false, enableReinitialize: true }, { children: formProps => {
                    var _a;
                    return (_jsxs(Form, Object.assign({ ref: wrapperRef }, { children: [_jsxs(PageHeader, { children: [_jsx(PageTitle, { title: data === null || data === void 0 ? void 0 : data.getCampaignDetails.name }), _jsxs(HStack, { children: [_jsx(Button, Object.assign({ colorScheme: "green", variant: "outline", leftIcon: _jsx(IoCheckmarkOutline, {}), isLoading: formProps.isSubmitting, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    formProps.setErrors({});
                                                    yield handleSaveAsDraft(formProps.values);
                                                    formProps.resetForm();
                                                }) }, { children: "Save as draft" })), _jsx(Button, Object.assign({ ref: buttonRef, type: "submit", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    yield handleSaveAsDraft(formProps.values, false);
                                                    yield handleStartCampaign();
                                                }), leftIcon: _jsx(IoMailOutline, {}), disabled: formProps.submitCount > 0 && !formProps.isValid }, { children: "Start Campaign" }))] })] }), _jsxs(PageContent, { children: [_jsx(EditCampaignDetails, Object.assign({}, formProps)), _jsx(EditCampaignMessages, Object.assign({}, formProps, { campaignId: id, handleSaveAsDraft: () => __awaiter(void 0, void 0, void 0, function* () { return yield handleSaveAsDraft(formProps.values, false); }) })), _jsx(EditCampaignContacts, Object.assign({}, formProps, { isEditable: !((_a = data.getCampaignDetails) === null || _a === void 0 ? void 0 : _a.campaignEmails.map(x => x.isSent).includes(true)) }))] })] })));
                } })), _jsx(ClickOutsideAlertDialog, { isOpen: isAlertDialogOpen, onClose: onClose, onSave: handleSaveClick, onSubmit: handleContinueClick })] }));
};
export default EditCampaign;
