import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, IconButton, NumberInput, NumberInputField, Select, Stack, Text, } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import generateButtonsValues from "./helpers/generateButtonsValues";
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_INITIAL_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZES = [5, DEFAULT_INITIAL_PAGE_SIZE, 15, 20];
const Pagination = ({ pageIndex, pageCount, canPreviousPage, canNextPage, gotoPage, previousPage, nextPage, pageSize, onPageSizeChange, pageSizes = DEFAULT_PAGE_SIZES, buttonCount = 5, arrowStep = 3, hideArrows = false, showSelect = true, showGoToPage = true, }) => {
    pageCount = Math.max(1, pageCount);
    buttonCount = Math.max(5, buttonCount);
    arrowStep = Math.max(1, arrowStep);
    const [inputNumberValue, setInputNumberValue] = useState(pageIndex + 1);
    const buttons = useMemo(() => generateButtonsValues(buttonCount, pageIndex, pageCount), [buttonCount, pageIndex, pageCount]);
    const prevArrowsClickHandler = useCallback(() => {
        gotoPage(pageIndex - arrowStep);
    }, [gotoPage, pageIndex, arrowStep]);
    const nextArrowsClickHandler = useCallback(() => {
        gotoPage(pageIndex + arrowStep);
    }, [gotoPage, pageIndex, arrowStep]);
    const options = useMemo(() => pageSizes.map(value => ({ label: `${value}`, value })), [pageSizes]);
    const currentOption = useMemo(() => options.find(option => option.value === pageSize), [options, pageSize]);
    return (_jsxs(Stack, Object.assign({ spacing: "6", direction: "row", justifyContent: !showSelect && !gotoPage ? "center" : "flex-end" }, { children: [showSelect && (_jsxs(Stack, Object.assign({ spacing: "2", direction: "row", alignItems: "center" }, { children: [_jsx(Text, Object.assign({ fontSize: "xs", width: "90px", color: "gray.500" }, { children: "rows per page:" })), _jsx(Select, Object.assign({ size: "sm", width: "50px", variant: "unstyled", color: "gray.500", fontSize: "xs", fontWeight: "bold", iconColor: "gray.400", value: currentOption.value, onChange: event => {
                            gotoPage(0);
                            onPageSizeChange(Number(event.target.value));
                        } }, { children: options.map(option => (_jsx("option", Object.assign({ value: option.value }, { children: option.label }), option.value))) }))] }))), _jsxs(Stack, Object.assign({ spacing: "2", direction: "row" }, { children: [_jsx(Button, Object.assign({ "aria-label": "left", variant: "outline", colorScheme: "gray", fontSize: "xs", size: "sm", onClick: previousPage, isDisabled: !canPreviousPage }, { children: "Previous" })), buttons.map((buttonValue, i) => {
                        const key = `${buttonValue}${i}`;
                        switch (buttonValue) {
                            case "prevArrows":
                                return (!hideArrows && (_jsx(Button, Object.assign({ bg: "white", colorScheme: "gray", variant: "outline", size: "sm", onClick: prevArrowsClickHandler }, { children: "..." }), key)));
                            case "nextArrows":
                                return (!hideArrows && (_jsx(Button, Object.assign({ bg: "white", variant: "outline", size: "sm", colorScheme: "gray", onClick: nextArrowsClickHandler }, { children: "..." }), key)));
                            default:
                                return (_jsxs(Button, Object.assign({ bg: "white", variant: "outline", colorScheme: "gray", size: "sm", _active: {
                                        color: "green.500",
                                        borderColor: "green.500",
                                        borderWidth: "1px",
                                        fontWeight: "bold",
                                    }, isActive: buttonValue === pageIndex, onClick: () => gotoPage(buttonValue) }, { children: [_jsx("div", {}), buttonValue + 1] }), key));
                        }
                    }), _jsx(Button, Object.assign({ bg: "white", variant: "outline", colorScheme: "gray", "aria-label": "right", fontSize: "xs", size: "sm", onClick: nextPage, isDisabled: !canNextPage }, { children: "Next" }))] })), showGoToPage && (_jsxs(Stack, Object.assign({ spacing: "2", direction: "row", alignItems: "center" }, { children: [_jsx(Text, Object.assign({ fontSize: "xs", width: "70px", color: "gray.500" }, { children: "go to page:" })), _jsx(NumberInput, Object.assign({ size: "xs", value: inputNumberValue, defaultValue: inputNumberValue, min: 1, max: pageCount, onChange: v => setInputNumberValue(Number(v)) }, { children: _jsx(NumberInputField, {}) })), _jsx(IconButton, { "aria-label": "Go to page", variant: "ghost", colorScheme: "gray", color: "gray.400", size: "sm", fontSize: "lg", onClick: () => gotoPage(inputNumberValue - 1), icon: _jsx(IoArrowForward, {}) })] })))] })));
};
export default Pagination;
