import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, } from "@chakra-ui/react";
import { IntegrationProviders } from "@packages/apollo-hooks";
import { getUserToken } from "../../../../common/helpers/user-token";
import IntegrationButton from "../integration-button";
const MicrosoftIntegrationModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const token = getUserToken();
    return (_jsxs(_Fragment, { children: [_jsx(IntegrationButton, { type: IntegrationProviders.Microsoft, onClick: onOpen }), _jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, size: "md" }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Outlook Integration" }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: _jsxs(Text, Object.assign({ color: "rgba(113, 128, 150, 1)" }, { children: ["Your email domain must be ", _jsx("b", { children: "hotmail.com" }), " or ", _jsx("b", { children: "outlook.com" }), " ", "to count bounces.", " ", _jsx("b", { children: "Motar.me app can\u2019t count bounces from other email domains." })] })) }), _jsx(ModalFooter, { children: _jsx("a", Object.assign({ href: `${process.env.REACT_APP_API_URL}/integration/microsoft?token=${token === null || token === void 0 ? void 0 : token.token}` }, { children: _jsx(IntegrationButton, { type: IntegrationProviders.Microsoft }) })) })] })] }))] }));
};
export default MicrosoftIntegrationModal;
