var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation, useQuery } from "@apollo/client";
import { FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";
import { EditUserDocument, GetUserDocument } from "@packages/apollo-hooks";
import { Formik } from "formik";
import { useCallback } from "react";
import Loader from "../../../shared/components/loader";
import EditUserFormContent from "./edit-user-form-content";
import { editUserInitialValues, editUserValidationSchema, } from "./form";
const EditUserForm = ({ onClose, userId }) => {
    const toast = useToast();
    const [editUser] = useMutation(EditUserDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getUsers"],
    });
    const { data, loading, error } = useQuery(GetUserDocument, {
        variables: {
            userId,
        },
    });
    const handleSubmit = useCallback((values, { setFieldError }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield editUser({
                variables: {
                    input: Object.assign({ id: userId }, values),
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.editUser.__typename) {
                case "EditUserSuccessfulResponse":
                    onClose();
                    return;
                case "UserNotExistResponse":
                    onClose();
                    toast({
                        title: "Something went wrong",
                        description: "User doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [editUser, onClose, toast, userId]);
    if (loading || !data || !data.getUser)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(FormControl, Object.assign({ px: "6", py: "3" }, { children: [_jsx(FormLabel, { children: "Email" }), _jsx(Input, { value: data.getUser.email, disabled: true })] })), _jsx(Formik, Object.assign({ initialValues: editUserInitialValues(data.getUser), validationSchema: editUserValidationSchema, onSubmit: handleSubmit }, { children: formProps => {
                    var _a;
                    return (_jsx(EditUserFormContent, Object.assign({ isAdmin: ((_a = data.getUser) === null || _a === void 0 ? void 0 : _a.role) === "ADMIN", onClose: onClose }, formProps)));
                } }))] }));
};
export default EditUserForm;
