var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AppEventTarget from "../../../common/events/app-event-target";
import LogoutRequestEvent from "../../../common/events/logout-request-event";
import { userTokenFromResponse, } from "../../../common/helpers/user-token";
import client from "../apollo";
import { RefreshTokenDocument, } from "@packages/apollo-hooks";
const refreshToken = () => __awaiter(void 0, void 0, void 0, function* () {
    let result = null;
    try {
        const mutationPromise = client.mutate({
            mutation: RefreshTokenDocument,
            fetchPolicy: "no-cache",
            context: {
                skipAutoTokenRefresh: true,
            },
        });
        const { data } = yield mutationPromise;
        switch (data === null || data === void 0 ? void 0 : data.refreshToken.__typename) {
            case "TokenResponse":
                result = userTokenFromResponse(data.refreshToken);
                break;
            case "RefreshTokenInvalidResponse":
                const ev = new LogoutRequestEvent();
                AppEventTarget.dispatchEvent(ev);
                break;
        }
    }
    catch (e) {
        console.error(e);
        console.error("Unable to refresh token", e);
    }
    return result;
});
export default refreshToken;
