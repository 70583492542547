import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import routesPaths from "../routing/route-paths";
const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(routesPaths.SETTINGS);
    }, [navigate]);
    return _jsx("div", {});
};
export default Home;
