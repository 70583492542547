export const getResponseBackgroundColor = (status) => {
    switch (status) {
        case "BOUNCED":
            return "#FFFAF0";
        case "NEGATIVE":
        case "COLLEAGUE_NEGATIVE":
            return "#EDFDFD";
        case "OTHER":
            return "#FFFFF0";
        case "POSITIVE":
        case "COLLEAGUE_POSITIVE":
            return "#F0FFF4";
        case "UNSUBSCRIBED":
            return "#EBF8FF";
        case "NEUTRAL":
        case "COLLEAGUE_NEUTRAL":
            return "#FAF5FF";
        default:
            return "#FFFFFF";
    }
};
export const getResponseColor = (status) => {
    switch (status) {
        case "BOUNCED":
            return "#FBD38D";
        case "NEGATIVE":
        case "COLLEAGUE_NEGATIVE":
            return "#9DECF9";
        case "OTHER":
            return "#FAF089";
        case "POSITIVE":
        case "COLLEAGUE_POSITIVE":
            return "#B3F1B5";
        case "UNSUBSCRIBED":
            return "#90CDF4";
        case "NEUTRAL":
        case "COLLEAGUE_NEUTRAL":
            return "#D6BCFA";
        default:
            return "#E2E8F0";
    }
};
