import * as Yup from "yup";
import { passwordRegex } from "../../../common/helpers/yup";
export const setNewPasswordInitialValues = {
    password: "",
};
export const setNewPasswordValidationSchema = Yup.object().shape({
    password: passwordRegex
        .min(8)
        .max(128)
        .matches(/^(?=.*[A-Z]).*$/, {
        message: "Should include at least one uppercase letter.",
    })
        .matches(/^(?=.*[\d]).*$/, {
        message: "Should include at least one number.",
    })
        .matches(/^(?=.*[^\p{L}\d\s]).*$/u, {
        message: "Should include at least one symbol.",
    })
        .required("Password is required"),
});
