const modal = {
    parts: ["overlay"],
    baseStyle: {
        overlay: {
            bg: "rgba(0, 0, 0, 0.2);",
        },
    },
    defaultProps: {
        isCentered: true,
    },
};
export default modal;
