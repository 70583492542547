import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, Switch, } from "@chakra-ui/react";
import { Field } from "formik";
import React, { forwardRef } from "react";
const SwitchField = forwardRef((props, ref) => {
    const { label, name, onChange } = props;
    return (_jsx(Field, Object.assign({ name: name }, { children: ({ field, meta }) => (_jsxs(FormControl, Object.assign({ p: "3", position: "relative", display: "flex", alignItems: "center", isInvalid: !!(meta.touched && meta.error) }, { children: [_jsx(Switch, Object.assign({ ref: ref }, field, { isChecked: field.value, onChange: onChange })), _jsx(FormLabel, Object.assign({ ml: "3", mb: "0", color: "gray.800", fontWeight: "bold", fontSize: "sm" }, { children: label })), _jsx(FormErrorMessage, { children: meta.touched && meta.error ? meta.error : undefined })] }))) })));
});
SwitchField.displayName = "SwitchField";
export default SwitchField;
