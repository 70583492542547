import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from "react";
import { Button, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogContent, AlertDialogOverlay, AlertDialogHeader, } from "@chakra-ui/react";
const ClickOutsideAlertDialog = ({ isOpen, onClose, onSubmit, onSave, }) => {
    const cancelRef = useRef();
    return (_jsx(_Fragment, { children: _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Quit creator" })), _jsx(AlertDialogBody, { children: "Are you sure you want to quit creator without saving changes?" }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", colorScheme: "gray", ref: cancelRef.current, onClick: () => {
                                        onSave();
                                        onClose();
                                    } }, { children: "Save changes" })), _jsx(Button, Object.assign({ colorScheme: "red", variant: "solid", onClick: () => {
                                        onSubmit();
                                        onClose();
                                    }, ml: 3 }, { children: "Yes" }))] })] }) }) })) }));
};
export default ClickOutsideAlertDialog;
