import styled from "@emotion/styled";
import theme from "../../../../../../../../common/theme";
const BaseGridElement = styled.div `
  font-size: ${theme.fontSizes.xl};
  color: ${theme.colors.blue[900]};
  cursor: pointer;
  position: relative;

  border-radius: ${theme.radii.base};
  margin: 4px auto;
  padding: 4px;

  :hover {
    background-color: ${theme.colors.cyan[50]};
  }
`;
export default BaseGridElement;
