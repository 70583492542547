const button = {
    baseStyle: {
        borderRadius: "lg",
        fontWeight: "normal",
        fontSize: "lg",
    },
    variants: {
        primary: {
            bg: "green.500",
            color: "white",
            _hover: {
                bg: "green.900",
                color: "white",
            },
            _disabled: {
                bg: "gray.300",
                color: "gray.700",
            },
        },
        solid: {
            color: "white",
            _disabled: {
                bg: "gray.100",
                color: "gray.400",
            },
        },
        // outline: {
        //   bg: "white",
        // },
        outline_color: {
            border: "1px solid",
            bg: "white",
            borderColor: "gray.200",
            color: "green.500",
            _hover: {
                color: "green.900",
                bg: "gray.100",
            },
            _disabled: {
                borderColor: "gray.400",
                color: "gray.300",
            },
        },
        accent: {
            border: "1px solid",
            bg: "white",
            borderColor: "green.500",
            color: "green.500",
            _hover: {
                color: "green.900",
                borderColor: "green.900",
            },
            _disabled: {
                borderColor: "gray.400",
                color: "gray.400",
            },
        },
    },
    defaultProps: {
        variant: "primary",
        size: "md",
        colorScheme: "green",
    },
};
export default button;
