import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Flex, Heading, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from "@chakra-ui/react";
import React from "react";
import { GetCampaignDetailsDocument } from "@packages/apollo-hooks";
import Loader from "../../../shared/components/loader";
import EmptyState from "../../../shared/components/table/empty-state";
import EmailDetails from "./email-details";
import EmailCreator from "./email-creator";
const EditCampaignMessages = ({ campaignId, errors, handleSaveAsDraft, }) => {
    var _a;
    const { data, loading, error } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId,
        },
    });
    if (loading)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    const emailCreatorDates = data.getCampaignDetails.campaignEmails.length > 0
        ? data.getCampaignDetails.campaignEmails.map(x => x.sendsAt)
        : [];
    return (_jsx(_Fragment, { children: data && data.getCampaignDetails && (_jsxs(Tabs, Object.assign({ defaultIndex: 0 }, { children: [_jsx(Box, Object.assign({ mb: "2", ml: "4", mt: "8" }, { children: _jsx(Heading, Object.assign({ size: "lg", fontSize: "2xl" }, { children: "Messages" })) })), _jsxs(Flex, Object.assign({ justify: "space-between", align: "center" }, { children: [_jsx(TabList, Object.assign({ position: "relative", zIndex: 1, mb: "4" }, { children: data.getCampaignDetails.campaignEmails.length > 0 ? (data.getCampaignDetails.campaignEmails.map((email, index) => (_jsxs(Tab, { children: ["E-mail ", index + 1] }, index)))) : (_jsx(Tab, { children: "E-mail 1" })) })), _jsx(HStack, { children: _jsx(EmailCreator, { campaignId: campaignId, handleSaveAsDraft: handleSaveAsDraft, emailsDates: emailCreatorDates }) })] })), errors.emails && (_jsx(Text, Object.assign({ color: "gray.500", fontSize: "xs", mb: "2" }, { children: errors.emails }))), _jsx(Box, Object.assign({ flex: "1" }, { children: _jsx(Box, Object.assign({ maxW: "7xl", mx: "auto" }, { children: _jsx(TabPanels, { children: data.getCampaignDetails.campaignEmails.length > 0 ? ((_a = data.getCampaignDetails) === null || _a === void 0 ? void 0 : _a.campaignEmails.map((email, index) => {
                                var _a;
                                const emailsDates = ((_a = data.getCampaignDetails) === null || _a === void 0 ? void 0 : _a.campaignEmails.filter(x => x.id !== email.id).map(x => x.sendsAt)) || [];
                                return (_jsx(TabPanel, { children: email && (_jsx(EmailDetails, { emailData: email, index: index, campaignId: campaignId, emailsDates: emailsDates })) }, index));
                            })) : (_jsx(TabPanel, { children: _jsx(EmptyState, { type: "messages", isTable: false, hasError: errors.emails ? true : false }) })) }) })) }))] }))) }));
};
export default EditCampaignMessages;
