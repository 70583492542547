import ActivateUser from "../activate-user";
import AdminPanel from "../admin-panel";
import Campaigns from "../campaigns";
import EditCampaign from "../campaigns/edit-campaign";
import SingleCampaign from "../campaigns/single-campaign";
import Contacts from "../contacts";
import ContactList from "../contacts/single-contact-list";
import GoogleIntegration from "../google-integration";
import Home from "../home";
import Logout from "../logout";
import MicrosoftIntegration from "../microsoft-integration";
import Login from "../public/login";
import Register from "../public/register";
import ResetPassword from "../reset-password";
import SetPassword from "../reset-password/set-new-password";
import SesIntegration from "../ses-integration";
import Settings from "../settings";
import StripePayment from "../settings/payments-panel/stripe-payment/stripe-payment";
import Templates from "../templates";
import Unsubscribe from "../unsubscribe";
import routesPaths from "./route-paths";
const uniqueRoutes = [
    {
        path: routesPaths.ACTIVATE_USER,
        title: "Activate User",
        type: "unique",
        element: ActivateUser,
    },
    {
        path: routesPaths.LOGOUT,
        title: "Logout",
        type: "unique",
        element: Logout,
    },
    {
        path: routesPaths.UNSUBSCRIBE,
        title: "Unsubscribe",
        type: "unique",
        element: Unsubscribe,
    },
];
const publicRoutes = [
    {
        path: routesPaths.LOGIN,
        title: "Welcome",
        type: "public",
        exact: true,
        element: Login,
    },
    {
        path: routesPaths.REGISTER,
        title: "Register",
        type: "public",
        exact: true,
        element: Register,
    },
    {
        path: routesPaths.RESET_PASSWORD,
        title: "Reset password",
        type: "public",
        exact: true,
        element: ResetPassword,
    },
    {
        path: routesPaths.SET_PASSWORD,
        title: "Set password",
        type: "public",
        exact: true,
        element: SetPassword,
    },
];
const authedRoutes = [
    {
        path: routesPaths.HOME,
        title: "Home",
        type: "authed",
        exact: true,
        element: Home,
    },
    {
        path: routesPaths.SETTINGS,
        title: "Settings",
        type: "authed",
        exact: true,
        element: Settings,
    },
    {
        path: routesPaths.SETTINGS_BY_TAB,
        title: "Settings",
        type: "authed",
        exact: true,
        element: Settings,
    },
    {
        path: routesPaths.ADMIN_PANEL,
        title: "Admin panel",
        type: "authed",
        exact: true,
        element: AdminPanel,
    },
    {
        path: routesPaths.CONTACT_LISTS,
        title: "Contacts",
        type: "authed",
        exact: true,
        element: Contacts,
    },
    {
        path: routesPaths.CONTACT_LIST,
        title: "Contacts",
        type: "authed",
        exact: true,
        element: ContactList,
    },
    {
        path: routesPaths.GOOGLE_INTEGRATION,
        title: "Google integration",
        type: "authed",
        exact: true,
        element: GoogleIntegration,
    },
    {
        path: routesPaths.MICROSOFT_INTEGRATION,
        title: "Microsoft integration",
        type: "authed",
        exact: true,
        element: MicrosoftIntegration,
    },
    {
        path: routesPaths.STRIPE_PAYMENT,
        title: "Stripe payment",
        type: "authed",
        exact: true,
        element: StripePayment,
    },
    {
        path: routesPaths.SES_INTEGRATION,
        title: "Ses integration",
        type: "authed",
        exact: true,
        element: SesIntegration,
    },
    {
        path: routesPaths.CAMPAIGN_EDITOR,
        title: "Campaign editor",
        type: "authed",
        exact: true,
        element: EditCampaign,
    },
    {
        path: routesPaths.CAMPAIGNS,
        title: "Campaigns",
        type: "authed",
        exact: true,
        element: Campaigns,
    },
    {
        path: routesPaths.CAMPAIGN,
        title: "Campaign",
        type: "authed",
        exact: true,
        element: SingleCampaign,
    },
    {
        path: routesPaths.CAMPAIGN_WITH_EMAIL,
        title: "Campaign",
        type: "authed",
        exact: true,
        element: SingleCampaign,
    },
    {
        path: routesPaths.TEMPLATES,
        title: "Templates",
        type: "authed",
        exact: true,
        element: Templates,
    },
    {
        path: routesPaths.TEMPLATES_WITH_MODE,
        title: "Templates",
        type: "authed",
        exact: true,
        element: Templates,
    },
];
export const nestedRoutes = [
    ...uniqueRoutes,
    ...publicRoutes,
    ...authedRoutes,
];
export default nestedRoutes;
