import { useQuery } from "@apollo/client";
import { CurrentUserDocument } from "@packages/apollo-hooks";
import { addDays, differenceInDays } from "date-fns";
const useUserSubscription = () => {
    var _a, _b, _c;
    const { data: userData } = useQuery(CurrentUserDocument);
    const subscription = (_b = (_a = userData === null || userData === void 0 ? void 0 : userData.currentUser) === null || _a === void 0 ? void 0 : _a.subscription) !== null && _b !== void 0 ? _b : null;
    const showSubscriptionBanner = subscription === null || subscription === void 0 ? void 0 : subscription.isTrial;
    const remainingDays = subscription
        ? differenceInDays(addDays(new Date(subscription.activationDate), 14), new Date())
        : 0;
    const showSubscriptionModal = !!userData &&
        !((_c = userData.currentUser.subscription) === null || _c === void 0 ? void 0 : _c.isActive) &&
        !userData.currentUser.hasDirectPayment &&
        userData.currentUser.role !== "ADMIN";
    const hasDirectPayment = !!userData && userData.currentUser.hasDirectPayment;
    const checkoutCompletedAt = userData && userData.currentUser.checkoutCompletedAt;
    const hasNormalSubscription = subscription && !subscription.isTrial;
    return {
        showSubscriptionBanner,
        showSubscriptionModal,
        remainingDays,
        hasDirectPayment,
        checkoutCompletedAt,
        hasNormalSubscription,
    };
};
export default useUserSubscription;
