import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Circle, Stack, StackDivider } from "@chakra-ui/react";
import { CampaignResponseStatuses, } from "@packages/apollo-hooks";
import React from "react";
import { getResponseColor } from "../../../helpers/responses-colors";
import SingleStatistic from "./single-statistic";
const StatisticsGrid = ({ statistics }) => {
    return (_jsxs(Stack, Object.assign({ py: "2", px: "4", direction: "row", spacing: "12" }, { children: [_jsxs(Stack, Object.assign({ direction: "column", flex: "1", divider: _jsx(StackDivider, { borderColor: "gray.100" }) }, { children: [_jsx(SingleStatistic, Object.assign({ title: "Positive", icon: _jsx(Circle, { m: "1.5", size: "13px", bg: getResponseColor(CampaignResponseStatuses.Positive) }) }, { children: statistics.positiveResponseCount })), _jsx(SingleStatistic, Object.assign({ title: "Neutral", icon: _jsx(Circle, { m: "1.5", size: "13px", bg: getResponseColor(CampaignResponseStatuses.Neutral) }) }, { children: statistics.neutralResponseCount }))] })), _jsxs(Stack, Object.assign({ direction: "column", flex: "1", divider: _jsx(StackDivider, { borderColor: "gray.100" }) }, { children: [_jsx(SingleStatistic, Object.assign({ title: "Negative", icon: _jsx(Circle, { m: "1.5", size: "13px", bg: getResponseColor(CampaignResponseStatuses.Negative) }) }, { children: statistics.negativeResponseCount })), _jsx(SingleStatistic, Object.assign({ title: "Bounce", icon: _jsx(Circle, { m: "1.5", size: "13px", bg: getResponseColor(CampaignResponseStatuses.Bounced) }) }, { children: statistics.bounceResponseCount }))] })), " ", _jsxs(Stack, Object.assign({ direction: "column", flex: "1", divider: _jsx(StackDivider, { borderColor: "gray.100" }) }, { children: [_jsx(SingleStatistic, Object.assign({ title: "Unsubscribe", icon: _jsx(Circle, { m: "1.5", size: "13px", bg: getResponseColor(CampaignResponseStatuses.Unsubscribed) }) }, { children: statistics.unsubscribeResponseCount })), _jsx(SingleStatistic, Object.assign({ title: "Other", icon: _jsx(Circle, { m: "1.5", size: "13px", bg: getResponseColor(CampaignResponseStatuses.Other) }) }, { children: statistics.otherResponseCount }))] }))] })));
};
export default StatisticsGrid;
