import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider, DrawerBody, DrawerFooter, FormControl, FormErrorMessage, HStack, Select, Stack, } from "@chakra-ui/react";
import { DATE_TIME_FORMAT } from "@packages/helpers";
import { Field, Form } from "formik";
import { personalizationOptions } from "../../../../../common/helpers/personalization-options";
import { DateTimePickerField } from "../../../../shared/components/form/date-time-picker-new/date-time-picker";
import InputField from "../../../../shared/components/form/input-field";
import SwitchField from "../../../../shared/components/form/switch-field";
import MailEditor from "../../../../shared/components/mail-editor";
import SaveAsTemplate from "../../../../templates/save-as-template";
import UseTemplateModal from "../../../../templates/use-template-modal";
import imageUploadHandler from "../../../helpers/image-upload-handler";
const EmailCreatorFormContent = ({ isSubmitting, isValid, submitCount, onClose, setFieldValue, values, endsAt, errors, setFieldError, initialValues, }) => {
    const isEditMode = initialValues.subject !== "" || initialValues.content !== "<p></p>";
    return (_jsxs(Form, { children: [_jsxs(DrawerBody, Object.assign({ maxH: "calc(100vh - 167px)" }, { children: [_jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsxs(Stack, Object.assign({ direction: "row", width: "full", spacing: "1", alignItems: "end" }, { children: [_jsx(InputField, { placeholder: "Please enter subject", label: "Subject", type: "text", name: "subject", width: "80%" }), _jsxs(Select, Object.assign({ mb: "3", w: "220px", rounded: "base", value: "", onChange: e => {
                                            setFieldValue("subject", values.subject + e.target.value);
                                        } }, { children: [_jsx("option", Object.assign({ value: "" }, { children: "Personalization field" }), "-1"), personalizationOptions.map((o, index) => (_jsx("option", Object.assign({ value: o.content }, { children: o.title }), index)))] }))] })), _jsx(Box, Object.assign({ pl: "3" }, { children: _jsx(UseTemplateModal, { handleSetValues: (subject, content) => {
                                        setFieldValue("subject", subject);
                                        setFieldValue("content", content);
                                        setFieldError("subject", "");
                                        setFieldError("content", "");
                                    } }) }))] })), _jsx(Stack, Object.assign({ direction: "row", width: "full", spacing: "0" }, { children: _jsx(SwitchField, { label: "Send now", name: "sendsNow", onChange: () => {
                                if (!values.sendsNow) {
                                    setFieldValue("sendsAt", new Date());
                                }
                                setFieldValue("sendsNow", !values.sendsNow);
                            } }) })), _jsx(Stack, Object.assign({ direction: "row", width: "full", spacing: "0" }, { children: _jsx(DateTimePickerField, { placeholder: "Select date", label: "Sending date", name: "sendsAt", formatString: DATE_TIME_FORMAT, minDate: new Date(), maxDate: endsAt ? new Date(endsAt) : undefined, isDisabled: values.sendsNow ? true : false }) })), _jsx(Divider, {}), _jsx(Box, Object.assign({ px: "3", py: "6" }, { children: _jsx(Field, Object.assign({ name: "content" }, { children: ({ form }) => (_jsxs(FormControl, Object.assign({ position: "relative", isInvalid: form.errors.content && form.touched.content ? true : false }, { children: [_jsx(Box, Object.assign({ border: form.errors.content && form.touched.content
                                            ? "1px solid #E53E3E"
                                            : "none" }, { children: _jsx(MailEditor, { content: values.content, setFieldValue: setFieldValue, imageUploadHandler: imageUploadHandler }) })), _jsx(FormErrorMessage, {})] }))) })) }))] })), _jsxs(Box, Object.assign({ position: "absolute", bottom: "0", width: "100%" }, { children: [_jsx(Divider, {}), _jsxs(DrawerFooter, Object.assign({ justifyContent: "space-between" }, { children: [_jsx(Button, Object.assign({ variant: "outline", mr: 3, onClick: onClose }, { children: "Cancel" })), _jsxs(HStack, Object.assign({ spacing: "3" }, { children: [!errors.content && !errors.subject && (_jsx(SaveAsTemplate, { subject: values.subject, content: values.content })), _jsx(Button, Object.assign({ type: "submit", isLoading: isSubmitting, disabled: submitCount > 0 && !isValid, colorScheme: "green" }, { children: isEditMode ? "Save changes" : "Create e-mail" }))] }))] }))] }))] }));
};
export default EmailCreatorFormContent;
