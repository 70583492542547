import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDisclosure } from "@chakra-ui/hooks";
import { Button, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, } from "@chakra-ui/react";
import React, { useState } from "react";
import { IoFolderOpenOutline, IoHeartOutline, IoLayersOutline, IoTimeOutline, } from "react-icons/io5";
import FoldersTabPanel from "./folders-tab-panel";
import RecentFavoritesTabPanel from "./recent-favorites-tab-panel";
const UseTemplateModal = ({ handleSetValues }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const handleSelectTemplate = (template) => {
        setSelectedTemplate(template);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: onOpen, variant: "outline", leftIcon: _jsx(IoLayersOutline, {}) }, { children: "Use Template" })), _jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, size: "md" }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Use Template" }), _jsx(ModalCloseButton, {}), _jsx(ModalBody, { children: _jsxs(Tabs, Object.assign({ isFitted: true, variant: "unstyled", height: "355px" }, { children: [_jsxs(TabList, Object.assign({ mb: "1em", backgroundColor: "gray.100", p: "1", borderRadius: "6px" }, { children: [_jsxs(Tab, Object.assign({ fontSize: "sm", py: "2", _selected: {
                                                        color: "gray.700",
                                                        backgroundColor: "#E2E8F0",
                                                        borderRadius: "6px",
                                                        fontWeight: "medium",
                                                    } }, { children: [_jsx(Icon, { size: "md", mr: "3", as: IoTimeOutline }), "Recent"] })), _jsxs(Tab, Object.assign({ fontSize: "sm", py: "2", _selected: {
                                                        color: "gray.700",
                                                        backgroundColor: "#E2E8F0",
                                                        borderRadius: "6px",
                                                        fontWeight: "medium",
                                                    } }, { children: [_jsx(Icon, { size: "md", mr: "3", as: IoHeartOutline }), "Favorites"] })), _jsxs(Tab, Object.assign({ fontSize: "sm", py: "2", _selected: {
                                                        color: "gray.700",
                                                        backgroundColor: "#E2E8F0",
                                                        borderRadius: "6px",
                                                        fontWeight: "medium",
                                                    } }, { children: [_jsx(Icon, { size: "md", mr: "3", as: IoFolderOpenOutline }), "Folders"] }))] })), _jsxs(TabPanels, Object.assign({ maxHeight: "300px", overflow: "hidden", overflowY: "scroll" }, { children: [_jsx(TabPanel, Object.assign({ p: "0" }, { children: _jsx(RecentFavoritesTabPanel, { selectedTemplate: selectedTemplate, handleSelectTemplate: handleSelectTemplate }) })), _jsx(TabPanel, Object.assign({ p: "0" }, { children: _jsx(RecentFavoritesTabPanel, { selectedTemplate: selectedTemplate, handleSelectTemplate: handleSelectTemplate, favorite: true }) })), _jsx(TabPanel, Object.assign({ p: "0" }, { children: _jsx(FoldersTabPanel, { selectedTemplate: selectedTemplate, handleSelectTemplate: handleSelectTemplate }) }))] }))] })) }), _jsxs(ModalFooter, { children: [_jsx(Button, Object.assign({ colorScheme: "gray", variant: "outline", mr: 3, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ disabled: !selectedTemplate, size: "md", onClick: () => {
                                            if (selectedTemplate) {
                                                handleSetValues(selectedTemplate.subject, selectedTemplate.body);
                                                onClose();
                                            }
                                        } }, { children: "Insert Template" }))] })] })] }))] }));
};
export default UseTemplateModal;
