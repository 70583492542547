import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ChakraProvider } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import useLogoutRequestHandler from "../../common/event-handlers/use-logout-request-handler";
import useTokenChangeHandler from "../../common/event-handlers/use-token-change-request-handler";
import theme from "../../common/theme";
import Fonts from "../../common/theme/fonts";
import AppContext from "./app-context";
import AppSkeleton from "./app-skeleton";
import AuthedRoute from "../routing/authed-route";
import PublicRoute from "../routing/public-route";
import routes from "../routing/routes";
import ScreenLoading from "../shared/components/screen-loading";
const Layout = ({ type, children }) => {
    if (type === "authed") {
        return _jsx(AuthedRoute, { children: children });
    }
    if (type === "public") {
        return _jsx(PublicRoute, { children: children });
    }
    return _jsx(_Fragment, { children: children });
};
const App = () => {
    const [token] = useTokenChangeHandler();
    useLogoutRequestHandler();
    const context = {
        userToken: token,
    };
    const routeComponents = useRoutes(routes.map(r => {
        return {
            path: r.path,
            element: (_jsx(Layout, Object.assign({ type: r.type }, { children: _jsx(r.element, {}) }))),
            exact: r.exact,
            children: r.children && r.children.length > 0
                ? r.children.map(child => {
                    return {
                        path: child.path,
                        element: (
                        // <Layout type={child.type}>
                        _jsx(child.element, {})
                        // </Layout>
                        ),
                        // exact: child.exact,
                    };
                })
                : [],
        };
    }));
    return (_jsxs(ChakraProvider, Object.assign({ theme: theme }, { children: [_jsx(Fonts, {}), _jsx(AppContext.Provider, Object.assign({ value: context }, { children: _jsx(AppSkeleton, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(ScreenLoading, {}) }, { children: routeComponents })) }) }))] })));
};
export default App;
