import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, IconButton } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import useIsAdmin from "../../../common/helpers/use-is-admin";
import routesPaths from "../../routing/route-paths";
import Loader from "../../shared/components/loader";
import PageContent from "../../shared/components/page/page-content";
import PageHeader from "../../shared/components/page/page-header";
import PageTitle from "../../shared/components/page/page-header/page-title";
import EditContactListName from "./edit-contact-list-name";
import useContactListQuery from "./hooks/use-contact-list-query";
import SingleContactListTable from "./single-contact-list-table";
import SingleContactTopActions from "./single-contact-top-actions";
const SingleContactList = () => {
    const { id } = useParams();
    const isAdmin = useIsAdmin();
    const [listName, setListName] = useState("");
    const initialName = useRef(undefined);
    const navigate = useNavigate();
    const { data, loading, error } = useContactListQuery(id);
    useEffect(() => {
        if (!loading && data && data.getContactList) {
            setListName(data.getContactList.name);
            if (!initialName.current) {
                initialName.current = data.getContactList.name;
            }
        }
        else {
            setListName("");
        }
    }, [data, loading]);
    const handleBack = () => {
        navigate(routesPaths.CONTACT_LISTS);
    };
    if (loading || !data || !data.getContactList)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(PageHeader, { children: [isAdmin ? (_jsxs(HStack, Object.assign({ mb: { base: "4", md: "0" }, mr: "4" }, { children: [_jsx(IconButton, { variant: "ghost", colorScheme: "gray", fontSize: "2xl", mr: "2", icon: _jsx(IoArrowBackOutline, {}), "aria-label": "Go back", onClick: handleBack }), _jsx(PageTitle, { title: "listName" })] }))) : (_jsx(EditContactListName, { id: id, title: listName, initialName: initialName.current, handleBackIconClick: handleBack, handleChangeListName: setListName })), id && !isAdmin && _jsx(SingleContactTopActions, { contactListId: id })] }), _jsx(PageContent, { children: id && _jsx(SingleContactListTable, { contactListId: id }) })] }));
};
export default SingleContactList;
