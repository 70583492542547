import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { Box, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, } from "@chakra-ui/react";
import { GetCampaignDetailsDocument } from "@packages/apollo-hooks";
import { useParams } from "react-router-dom";
import Loader from "../../../../shared/components/loader";
import EmptyState from "../../../../shared/components/table/empty-state";
import EmailSentIcon from "../../../campaign-badge-new/icons/email-sent-icon";
import EmailDetailsView from "./email-details-view";
import EmailResponses from "./email-responses";
const MessagesTab = () => {
    const { id, emailId } = useParams();
    const { data, loading, error } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId: id,
        },
    });
    if (loading || !data || !data.getCampaignDetails)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    const emails = data.getCampaignDetails.campaignEmails;
    const selectedEmailIndex = emailId
        ? emails.findIndex(email => email.id === emailId)
        : null;
    return (_jsxs(Tabs, Object.assign({ defaultIndex: selectedEmailIndex !== null && selectedEmailIndex !== void 0 ? selectedEmailIndex : 0 }, { children: [_jsx(Box, Object.assign({ mb: "2", mt: "8" }, { children: _jsx(Heading, Object.assign({ size: "lg", fontSize: "2xl" }, { children: "Messages" })) })), _jsx(Flex, Object.assign({ justify: "space-between", align: "center" }, { children: _jsx(TabList, Object.assign({ position: "relative", zIndex: 1 }, { children: emails.length > 0 ? (emails.map((email, index) => (_jsxs(Tab, { children: [email.isSent && _jsx(EmailSentIcon, { width: "24px", height: "24px" }), " ", "E-mail ", index + 1, " "] }, index)))) : (_jsx(Tab, { children: "E-mail 1" })) })) })), _jsx(Box, Object.assign({ flex: "1" }, { children: _jsx(Box, Object.assign({ maxW: "7xl", mx: "auto", mt: "6" }, { children: _jsx(TabPanels, { children: emails.length > 0 ? (emails.map((email, index) => (_jsxs(TabPanel, { children: [_jsx(EmailDetailsView, { email: email }), _jsx(EmailResponses, { campaignEmailId: email.id })] }, index)))) : (_jsx(TabPanel, { children: _jsx(EmptyState, { type: "messages", isTable: false }) })) }) })) }))] })));
};
export default MessagesTab;
