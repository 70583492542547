const num = {
    parts: ["field"],
    variants: {
        outline: {
            field: {
                bg: "white",
                borderRadius: "base",
                fontSize: "xs",
                padding: "2",
                width: "32px",
                _invalid: {
                    boxShadow: "none",
                },
                _focus: {
                    borderColor: "cyan.400",
                },
            },
        },
    },
};
export default num;
