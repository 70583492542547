var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { CopyFromListDocument } from "@packages/apollo-hooks";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import routesPaths from "../../../routing/route-paths";
import CopyContactsFromListFormContent from "./copy-contacts-from-list-form-content";
import { copyContactsFromListInitialValues, copyContactsFromListValidationSchema, } from "./form";
const CopyContactsFromListForm = ({ onClose, contactListId, }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [copyFromList] = useMutation(CopyFromListDocument, {
        refetchQueries: ["getContacts", "getContactLists"],
    });
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield copyFromList({
                variables: {
                    input: {
                        targetContactListId: contactListId,
                        sourceContactListId: values.list,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.copyFromList.__typename) {
                case "CopyFromListSuccessfulDeletedResponse":
                    onClose();
                    return;
                case "CopyFromListSourceListNotFoundResponse":
                    onClose();
                    toast({
                        title: "Something went wrong",
                        description: "Source list not found",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "CopyFromListTargetListNotFoundResponse":
                    onClose();
                    toast({
                        title: "Something went wrong",
                        description: "Target list not found",
                        status: "error",
                        isClosable: false,
                    });
                    navigate(routesPaths.CONTACT_LISTS);
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [contactListId, copyFromList, navigate, onClose, toast]);
    return (_jsx(Formik, Object.assign({ initialValues: copyContactsFromListInitialValues, validationSchema: copyContactsFromListValidationSchema, onSubmit: handleSubmit }, { children: formProps => (_jsx(CopyContactsFromListFormContent, Object.assign({ contactListId: contactListId, onClose: onClose }, formProps))) })));
};
export default CopyContactsFromListForm;
