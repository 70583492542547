import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex } from "@chakra-ui/react";
import useUserSubscription from "../../shared/hooks/use-user-subscription";
import SubscriptionBanner from "../../shared/subscription-plan/subscription-banner";
import TrialEndedModal from "../../shared/subscription-plan/trial-ended-modal";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
const AuthedLayout = ({ children }) => {
    const { remainingDays, showSubscriptionBanner, showSubscriptionModal, hasDirectPayment, } = useUserSubscription();
    return (_jsxs(_Fragment, { children: [_jsx(TrialEndedModal, { isOpen: showSubscriptionModal && !hasDirectPayment, onClose: () => { } }), _jsxs(Flex, Object.assign({ height: "100vh", flexDirection: "column" }, { children: [_jsx(Topbar, {}), _jsxs(Flex, Object.assign({ flex: "1", overflow: "hidden" }, { children: [_jsx(Sidebar, { display: { base: "none", md: "flex" } }), _jsx(Box, Object.assign({ bg: "gray.50", flex: "1", overflowY: "scroll" }, { children: _jsxs(Box, Object.assign({ position: "relative" }, { children: [showSubscriptionBanner && !hasDirectPayment && (_jsx(SubscriptionBanner, { trialRemainingDays: remainingDays })), _jsx(Flex, Object.assign({ direction: "column", align: "stretch", minH: "100vh" }, { children: children }))] })) }))] }))] }))] }));
};
export default AuthedLayout;
