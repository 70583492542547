var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, useToast, } from "@chakra-ui/react";
import { AddTemplateDocument, EditTemplateDocument, } from "@packages/apollo-hooks";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { templateCreatorInitialValues, templateCreatorLastStepValidationSchema, } from "../template-creator/form";
import SaveTemplateFormContent from "./save-template-form-content";
const SaveTemplateModal = ({ isOpen, onClose, cancelRef, subject, content, templateFolderId, onDrawerClose, templateId, templateName, }) => {
    const toast = useToast();
    const [addTemplate] = useMutation(AddTemplateDocument, {
        refetchQueries: ["getTemplates"],
    });
    const [editTemplate] = useMutation(EditTemplateDocument, {
        refetchQueries: ["getTemplates"],
    });
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!templateId) {
                const { data } = yield addTemplate({
                    variables: {
                        input: {
                            body: values.content,
                            name: values.name,
                            subject: values.subject,
                            templateFolderId: values.templateFolder.length > 0
                                ? values.templateFolder
                                : null,
                        },
                    },
                });
                switch (data === null || data === void 0 ? void 0 : data.addTemplate.__typename) {
                    case "AddTemplateSuccessfulResponse":
                        toast({
                            title: "Template saved successfully",
                            status: "success",
                            isClosable: true,
                        });
                        onClose();
                        if (onDrawerClose)
                            onDrawerClose();
                        return;
                    default:
                        toast({
                            title: "Something went wrong",
                            status: "error",
                            isClosable: false,
                        });
                        return;
                }
            }
            else {
                const { data: editTemplateData } = yield editTemplate({
                    variables: {
                        input: {
                            templateId,
                            body: values.content,
                            name: values.name,
                            subject: values.subject,
                            templateFolderId: values.templateFolder.length > 0
                                ? values.templateFolder
                                : null,
                        },
                    },
                });
                switch (editTemplateData === null || editTemplateData === void 0 ? void 0 : editTemplateData.editTemplate.__typename) {
                    case "EditTemplateSuccessfulResponse":
                        onClose();
                        if (onDrawerClose)
                            onDrawerClose();
                        return;
                    case "TemplateNotExistResponse":
                        toast({
                            title: "Something went wrong",
                            description: "Template doesn't exist",
                            status: "error",
                            isClosable: false,
                        });
                        break;
                    default:
                        toast({
                            title: "Something went wrong",
                            status: "error",
                            isClosable: false,
                        });
                        return;
                }
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
        }
    }), [addTemplate, editTemplate, onClose, onDrawerClose, templateId, toast]);
    return (_jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Save Template" })), _jsx(Formik, Object.assign({ initialValues: templateCreatorInitialValues(subject, content, templateFolderId, templateName), validationSchema: templateCreatorLastStepValidationSchema, onSubmit: handleSubmit }, { children: formProps => (_jsx(SaveTemplateFormContent, Object.assign({ onClose: onClose, cancelRef: cancelRef }, formProps))) }))] }) }) })));
};
export default SaveTemplateModal;
