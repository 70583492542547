var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { SetPasswordDocument } from "@packages/apollo-hooks";
import routesPaths from "../../routing/route-paths";
import { setNewPasswordInitialValues, setNewPasswordValidationSchema, } from "./form";
import SetNewPasswordFormContent from "./set-new-password-form-content";
const SetNewPasswordForm = ({ resetToken }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [setPassword] = useMutation(SetPasswordDocument);
    const handleSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield setPassword({
                variables: {
                    input: {
                        password: values.password,
                        token: resetToken,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.setPassword.__typename) {
                case "SetPasswordOkResponse":
                    navigate(routesPaths.LOGIN, {
                        state: {
                            passwordChanged: true,
                        },
                    });
                    return;
                case "SetPasswordInvalidResponse":
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    return;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: true,
            });
            console.error(e);
        }
    }), [navigate, resetToken, setPassword, toast]);
    return (_jsx(Formik, Object.assign({ initialValues: setNewPasswordInitialValues, validationSchema: setNewPasswordValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(SetNewPasswordFormContent, Object.assign({}, formProps)) })));
};
export default SetNewPasswordForm;
