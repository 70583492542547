var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { setUserToken, userTokenFromResponse, } from "../../../common/helpers/user-token";
import { loginInitialValues, loginValidationSchema, } from "./form";
import LoginFormContent from "./login-form-content";
import { LoginDocument } from "@packages/apollo-hooks";
import { useMutation } from "@apollo/client";
import routesPaths from "../../routing/route-paths";
const LoginForm = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [login] = useMutation(LoginDocument);
    const handleSubmit = useCallback((values, { setFieldError }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield login({
                variables: {
                    input: values,
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.login.__typename) {
                case "TokenResponse":
                    setUserToken(userTokenFromResponse(data.login));
                    navigate(routesPaths.HOME);
                    return;
                case "LoginInvalidResponse":
                    setFieldError("email", "Invalid login credentials");
                    setFieldError("password", "Invalid login credentials");
                    break;
                case "LoginNotVerifiedResponse":
                    setFieldError("email", "Invalid login credentials");
                    setFieldError("password", "Invalid login credentials");
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: true,
                    });
                    console.error("err");
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: true,
            });
        }
    }), [login, navigate, toast]);
    return (_jsx(Formik, Object.assign({ initialValues: loginInitialValues, validationSchema: loginValidationSchema, onSubmit: handleSubmit }, { children: formProps => _jsx(LoginFormContent, Object.assign({}, formProps)) })));
};
export default LoginForm;
