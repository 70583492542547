import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@chakra-ui/react";
import { addYears, endOfDay, endOfDecade, format, startOfDay, startOfDecade, subYears, } from "date-fns";
import React, { useCallback, useMemo, } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { Grid, Header, Title, TitleWrapper, Wrapper, } from "./calendar.components";
import Days from "./days";
import Months from "./months";
import Years from "./years";
const Calendar = ({ className, state: { selection, displayDate, displayLevel, minDate, maxDate }, dispatch, }) => {
    const todayDate = useMemo(() => new Date(), []);
    const selectionInterval = useMemo(() => !selection
        ? null
        : selection instanceof Date
            ? {
                start: startOfDay(selection),
                end: endOfDay(selection),
            }
            : {
                start: startOfDay(selection.start),
                end: endOfDay(selection.end),
            }, [selection]);
    const title = useMemo(() => {
        switch (displayLevel) {
            case "days":
                return format(displayDate, "MMMM y");
            case "months":
                return format(displayDate, "y");
            case "years":
                const startDate = format(subYears(startOfDecade(displayDate), 1), "y");
                const endDate = format(addYears(endOfDecade(displayDate), 1), "y");
                return `${startDate} — ${endDate}`;
        }
    }, [displayDate, displayLevel]);
    const isTitleClickable = useMemo(() => displayLevel !== "years", [displayLevel]);
    const onTitleClick = useCallback(() => {
        dispatch({ type: "goUp" });
    }, [dispatch]);
    const onArrowLeft = useCallback(() => {
        dispatch({ type: "backward" });
    }, [dispatch]);
    const onArrowRight = useCallback(() => {
        dispatch({ type: "forward" });
    }, [dispatch]);
    return (_jsxs(Wrapper, Object.assign({ className: className, "data-cy": "calendar" }, { children: [_jsxs(Header, { children: [_jsxs(TitleWrapper, { children: [_jsx(Title, Object.assign({ clickable: isTitleClickable, onClick: onTitleClick, "data-cy-display-date": format(displayDate, "y-M-d"), "data-cy-display-level": displayLevel, "data-cy-selection-start": (selectionInterval === null || selectionInterval === void 0 ? void 0 : selectionInterval.start)
                                    ? format(selectionInterval.start, "y-M-d")
                                    : "none", "data-cy-selection-end": (selectionInterval === null || selectionInterval === void 0 ? void 0 : selectionInterval.end)
                                    ? format(selectionInterval.start, "y-M-d")
                                    : "none" }, { children: title })), _jsx(Box, Object.assign({ ml: "2" }, { children: _jsx(IoChevronForward, { fontSize: "20px" }) }))] }), _jsx(IconButton, { variant: "ghost", colorScheme: "gray", "aria-label": "backward", fontSize: "3xl", icon: _jsx(IoChevronBack, {}), onClick: onArrowLeft, "data-cy": "backward" }), _jsx(IconButton, { variant: "ghost", colorScheme: "gray", "aria-label": "forward", fontSize: "3xl", icon: _jsx(IoChevronForward, {}), onClick: onArrowRight, "data-cy": "forward" })] }), _jsxs(Grid, Object.assign({ gridWidth: displayLevel === "days" ? 7 : 4 }, { children: [displayLevel === "days" && (_jsx(Days, { dispatch: dispatch, minDate: minDate, maxDate: maxDate, todayDate: todayDate, displayDate: displayDate, selectionInterval: selectionInterval })), displayLevel === "months" && (_jsx(Months, { dispatch: dispatch, todayDate: todayDate, displayDate: displayDate })), displayLevel === "years" && (_jsx(Years, { dispatch: dispatch, todayDate: todayDate, displayDate: displayDate }))] }))] })));
};
export default Calendar;
