import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import { DATE_FORMAT, TIME_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import { IoCalendarOutline, IoMailOutline, IoTimeOutline, } from "react-icons/io5";
import EmailCreator from "../email-creator";
import DeleteMessage from "./delete-message";
import EmailCardDetail from "./email-card-detail";
const EmailDetails = ({ emailData, index, campaignId, emailsDates, }) => {
    const { subject, sendsAt, isSent, id } = emailData;
    return (_jsxs(Box, Object.assign({ rounded: { lg: "lg" }, bg: "white", borderWidth: "1px" }, { children: [_jsx(Box, Object.assign({ px: "6", py: "2" }, { children: _jsx(Text, Object.assign({ as: "h3", fontWeight: "bold", fontSize: "sm" }, { children: "E-mail details" })) })), _jsx(Divider, {}), _jsxs(Flex, Object.assign({ justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", px: "8", py: "5" }, { children: [_jsxs(Stack, Object.assign({ spacing: "14", direction: "row" }, { children: [_jsx(EmailCardDetail, Object.assign({ icon: _jsx(IoMailOutline, {}), title: "Subject", isFirst: true }, { children: subject })), _jsx(EmailCardDetail, Object.assign({ icon: _jsx(IoCalendarOutline, {}), title: "Date" }, { children: format(new Date(sendsAt), DATE_FORMAT) })), _jsx(EmailCardDetail, Object.assign({ icon: _jsx(IoTimeOutline, {}), title: "Time" }, { children: format(new Date(sendsAt), TIME_FORMAT) }))] })), !isSent && (_jsxs(Box, { children: [_jsx(DeleteMessage, { index: index, campaignEmailId: id }), _jsx(EmailCreator, { isEditMode: true, emailData: emailData, campaignId: campaignId, emailsDates: emailsDates })] }))] }))] })));
};
export default EmailDetails;
