import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ModalHeader, Stack, Step, StepIcon, StepIndicator, StepSeparator, StepStatus, StepTitle, Stepper, Text, useSteps, } from "@chakra-ui/react";
import { useRsi } from "../hooks/useRsi";
import { useRsiInitialStep } from "../hooks/useRsiInitialStep";
import { UploadFlow } from "./UploadFlow";
export const Steps = () => {
    const { initialStepState, translations } = useRsi();
    const { steps, initialStep } = useRsiInitialStep(initialStepState === null || initialStepState === void 0 ? void 0 : initialStepState.type);
    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: initialStep,
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, Object.assign({ display: ["none", "none", "block"], borderBottom: "1px solid #E2E8F0" }, { children: _jsx(Stepper, Object.assign({ index: activeStep, size: "sm", colorScheme: "green", px: "8" }, { children: steps.map((key, index) => (_jsxs(Step, { children: [_jsx(Stack, Object.assign({ flexShrink: "0", align: "center" }, { children: _jsx(StepIndicator, Object.assign({ gap: 0 }, { children: _jsx(StepStatus, { complete: _jsx(StepIcon, {}) }) })) })), _jsx(StepTitle, { children: _jsx(Text, Object.assign({ fontSize: "sm", fontWeight: index === activeStep ? "semibold" : "normal", color: index > activeStep ? "grey" : "black" }, { children: translations[key].title })) }), _jsx(StepSeparator, {})] }, key))) })) })), _jsx(UploadFlow, { nextStep: goToNext, previousStep: goToPrevious })] }));
};
