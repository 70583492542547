import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDisclosure } from "@chakra-ui/hooks";
import { Button, Divider, Drawer, DrawerContent, DrawerHeader, DrawerOverlay, MenuItem, } from "@chakra-ui/react";
import React from "react";
import { IoAddOutline, IoPencilSharp } from "react-icons/io5";
import useIsAdmin from "../../../../common/helpers/use-is-admin";
import CustomDrawerCloseButton from "../../../shared/components/custom-drawer-close-button";
import AddContactForm from "./add-contact-form";
import EditContactForm from "./edit-contact-form";
const AddContact = ({ isEditMode = false, contactId, contactListId, }) => {
    const isAdmin = useIsAdmin();
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [!isEditMode ? (!isAdmin && (_jsx(Button, Object.assign({ colorScheme: "green", variant: "outline", leftIcon: _jsx(IoAddOutline, {}), fontSize: "sm", onClick: onOpen }, { children: "Add Contact" })))) : (_jsx(MenuItem, Object.assign({ icon: _jsx(IoPencilSharp, {}), onClick: onOpen }, { children: "Edit" }))), _jsxs(Drawer, Object.assign({ isOpen: isOpen, onClose: onClose, size: "xs" }, { children: [_jsx(DrawerOverlay, {}), _jsxs(DrawerContent, Object.assign({ maxWidth: "520px" }, { children: [_jsx(CustomDrawerCloseButton, {}), _jsx(DrawerHeader, { children: !isEditMode ? "Add Contact" : "Edit Contact" }), _jsx(Divider, {}), !isEditMode ? (contactListId && (_jsx(AddContactForm, { onClose: onClose, contactListId: contactListId }))) : (_jsx(EditContactForm, { onClose: onClose, contactId: contactId }))] }))] }))] }));
};
export default AddContact;
