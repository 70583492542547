var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery, useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/toast";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../shared/components/loader";
import { EditCampaignEmailDocument, } from "@packages/apollo-hooks";
import { GetCampaignDetailsDocument } from "@packages/apollo-hooks";
import EmailCreatorFormContent from "./email-creator-form-content";
import { emailCreatorValidationSchema, emailEditorInitialValues, } from "./form";
import routesPaths from "../../../../routing/route-paths";
import { differenceInMinutes } from "date-fns";
import { MIN_DIFF_BETWEEN_EMAILS_IN_MINUTES } from "./email-creator-form";
const EmailEditorForm = ({ onClose, emailData, campaignId, emailsDates, }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { data, loading } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId,
        },
    });
    const [editCampaignEmail] = useMutation(EditCampaignEmailDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getCampaignDetails"],
    });
    const handleSubmit = useCallback((values, { setFieldError }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (emailsDates.length > 0) {
                const founded = emailsDates.find(emailDate => {
                    const diff = Math.abs(differenceInMinutes(new Date(emailDate), new Date(values.sendsAt)));
                    return diff < MIN_DIFF_BETWEEN_EMAILS_IN_MINUTES;
                });
                if (founded) {
                    toast({
                        title: `Minimum time span between two emails is ${MIN_DIFF_BETWEEN_EMAILS_IN_MINUTES} minutes`,
                        status: "error",
                        isClosable: true,
                    });
                    setFieldError("sendsAt", "Invalid date");
                    return;
                }
            }
            const { data } = yield editCampaignEmail({
                variables: {
                    input: {
                        body: values.content,
                        id: emailData.id,
                        sendsAt: values.sendsAt.toString(),
                        subject: values.subject,
                    },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.editCampaignEmail.__typename) {
                case "EditCampaignEmailSuccessfulResponse":
                    onClose();
                    return;
                case "CampaignEmailAlreadySentResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign email already sent",
                        status: "error",
                        isClosable: true,
                    });
                    onClose();
                    return;
                case "CampaignEmailInvalidDateResponse":
                    setFieldError("sendsAt", "Invalid date");
                    break;
                case "CampaignEmailNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign email doesn't exist",
                        status: "error",
                        isClosable: true,
                    });
                    onClose();
                    break;
                case "CampaignAlreadyActiveResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign already active",
                        status: "error",
                        isClosable: true,
                    });
                    onClose();
                    navigate(routesPaths.CAMPAIGN.replace(":id", campaignId));
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [
        campaignId,
        editCampaignEmail,
        emailData.id,
        emailsDates,
        navigate,
        onClose,
        toast,
    ]);
    if (loading || !data || !data.getCampaignDetails)
        return _jsx(Loader, {});
    return (_jsx(Formik, Object.assign({ initialValues: emailEditorInitialValues(emailData), validationSchema: emailCreatorValidationSchema, onSubmit: handleSubmit }, { children: formProps => (_jsx(EmailCreatorFormContent, Object.assign({ onClose: onClose, endsAt: data.getCampaignDetails && data.getCampaignDetails.endsAt
                ? data.getCampaignDetails.endsAt
                : undefined }, formProps))) })));
};
export default EmailEditorForm;
