import { useQuery } from "@apollo/client";
import { GetCampaignDetailsDocument, CampaignStatuses, } from "@packages/apollo-hooks";
const useIsCampaignEditable = (campaignId) => {
    var _a;
    const { data } = useQuery(GetCampaignDetailsDocument, {
        variables: {
            campaignId,
        },
    });
    const status = (_a = data === null || data === void 0 ? void 0 : data.getCampaignDetails) === null || _a === void 0 ? void 0 : _a.status;
    const isEditable = status === CampaignStatuses.Draft || status === CampaignStatuses.Paused;
    return isEditable;
};
export default useIsCampaignEditable;
