import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge, Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { IntegrationProviders } from "@packages/apollo-hooks";
import { getUserToken } from "../../../common/helpers/user-token";
import getProviderName from "../../campaigns/helpers/get-provider-name";
import DisableIntegrationModal from "./disable-integration-modal";
import IntegrationButton from "./integration-button";
import MicrosoftIntegrationModal from "./microsoft-integration-modal";
import SesIntegrationModal from "./ses-integration-modal";
import { integrationSettings } from "./utils/integration-settings";
const IntegrationCard = ({ type, email, isActive, }) => {
    var _a;
    const token = getUserToken();
    return (_jsx(Box, Object.assign({ p: "6" }, { children: _jsxs(Flex, Object.assign({ justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Stack, Object.assign({ spacing: "4", direction: "row" }, { children: [_jsx(Box, Object.assign({ flexShrink: 0 }, { children: _jsx(Image, { src: (_a = integrationSettings.find(setting => setting.type === type)) === null || _a === void 0 ? void 0 : _a.iconSrc }) })), _jsxs(Stack, Object.assign({ spacing: "2" }, { children: [_jsxs(Stack, Object.assign({ direction: "row", spacing: "2", alignItems: "center" }, { children: [_jsx(Text, Object.assign({ fontSize: "md", fontWeight: "bold" }, { children: getProviderName(type) })), isActive ? (_jsxs(_Fragment, { children: [_jsx(Badge, Object.assign({ colorScheme: "green", color: "green.900" }, { children: "active" })), email && (_jsxs(Stack, Object.assign({ direction: "row", spacing: "1" }, { children: [_jsx(Text, Object.assign({ color: "gray.500", fontSize: "sm" }, { children: "with" })), _jsx(Text, Object.assign({ color: "gray.800", fontSize: "sm" }, { children: email }))] })))] })) : (_jsx(Badge, Object.assign({ color: "gray.600" }, { children: "inactive" })))] })), _jsxs(Text, Object.assign({ color: "gray.500", fontSize: "sm", fontFamily: "Arial" }, { children: ["Enable ", getProviderName(type), " account integration"] }))] }))] })), isActive ? (_jsx(DisableIntegrationModal, { type: type })) : (_jsxs(_Fragment, { children: [type === IntegrationProviders.Google && (_jsx("a", Object.assign({ href: `${process.env.REACT_APP_API_URL}/integration/google?token=${token === null || token === void 0 ? void 0 : token.token}` }, { children: _jsx(IntegrationButton, { type: IntegrationProviders.Google }) }))), type === IntegrationProviders.Microsoft && (_jsx(MicrosoftIntegrationModal, {})), type === IntegrationProviders.Ses && _jsx(SesIntegrationModal, {})] }))] })) })));
};
export default IntegrationCard;
