const drawer = {
    parts: ["overlay", "header", "footer", "body"],
    defaultProps: {
        size: "md",
    },
    baseStyle: {
        overlay: {
            bg: "rgba(0, 0, 0, 0.2);",
        },
        header: {
            py: 6,
        },
        footer: {
            py: 6,
        },
        body: {
            py: 0,
            px: 3,
        },
    },
    sizes: {
        own: { dialog: { maxW: "calc(100vw - 320px)" } },
    },
};
export default drawer;
