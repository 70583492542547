var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { Box, Flex, HStack, Icon, Text, useDisclosure, useToast, } from "@chakra-ui/react";
import { HandleTemplateFavoriteDocument, } from "@packages/apollo-hooks";
import React, { useCallback, useRef } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import TemplatePreview from "../template-preview";
import FolderBadge from "./folder-badge";
const HTML_TAGS_REGEX = /(<([^>]+)>)/gi;
const TemplateCard = (_a) => {
    var { showPreview = true, template, showFolderBadge, modal = false } = _a, props = __rest(_a, ["showPreview", "template", "showFolderBadge", "modal"]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [handleTemplateFavorite] = useMutation(HandleTemplateFavoriteDocument, {
        refetchQueries: ["getTemplates"],
    });
    const toast = useToast();
    const favoriteRef = useRef(null);
    const handleFavorite = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield handleTemplateFavorite({
                variables: {
                    input: { templateId: template.id },
                },
            });
            switch (data === null || data === void 0 ? void 0 : data.handleTemplateFavorite.__typename) {
                case "HandleTemplateFavoriteSuccessfulResponse":
                    return;
                case "TemplateNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Template doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    break;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong",
                status: "error",
                isClosable: false,
            });
            console.error(e);
        }
    }), [handleTemplateFavorite, template.id, toast]);
    return (_jsxs(HStack, Object.assign({ className: "data-cy-template-card", p: modal ? "2" : "4", w: "full", borderRadius: "8px", borderWidth: "1px", borderColor: "gray.200", spacing: "6", cursor: showPreview ? "pointer" : "auto", onClick: (e) => {
            if (showPreview) {
                if (!favoriteRef.current || favoriteRef.current.contains(e.target)) {
                }
                else {
                    onOpen();
                }
            }
        } }, props, { children: [showPreview && (_jsx(Box, Object.assign({ ref: favoriteRef, className: `data-cy-favorite-icon${template.isFavorite ? "-checked" : ""}`, height: "100%", cursor: "pointer", onClick: handleFavorite }, { children: _jsx(Icon, { as: template.isFavorite ? IoHeart : IoHeartOutline, boxSize: "18px" }) }))), _jsxs(Box, Object.assign({ width: "100%" }, { children: [_jsxs(Flex, Object.assign({ justifyContent: "space-between", alignItems: "center", mb: "2" }, { children: [_jsx(Text, Object.assign({ fontSize: modal ? "sm" : "md", color: "gray.700", noOfLines: 1 }, { children: template.name })), showFolderBadge && template.templateFolder && (_jsxs(FolderBadge, { children: ["in ", template.templateFolder.name] }))] })), _jsx(Text, Object.assign({ color: "gray.500", fontWeight: "bold", fontSize: modal ? "xs" : "sm", mb: modal ? "0" : "1", noOfLines: 1 }, { children: template.subject })), _jsx(Text, Object.assign({ color: "gray.500", fontSize: "sm", noOfLines: 1 }, { children: template.body
                            .replace(HTML_TAGS_REGEX, "")
                            .replace(new RegExp("&nbsp;", "g"), "") }))] })), showPreview && (_jsx(Box, { children: _jsx(TemplatePreview, { template: template, isOpen: isOpen, onClose: onClose }) }))] })));
};
export default TemplateCard;
