var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Stack, Text, useToast } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx-ugnis";
import { useState } from "react";
import { useRsi } from "../../../hooks/useRsi";
import { readFileAsync } from "../utils/readFilesAsync";
export const DropZone = ({ onContinue, isLoading }) => {
    const { translations, maxFileSize, dateFormat, parseRaw } = useRsi();
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        maxSize: maxFileSize,
        accept: {
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                ".xlsx",
            ],
            "text/csv": [".csv"],
        },
        onDropRejected: fileRejections => {
            setLoading(false);
            fileRejections.forEach(fileRejection => {
                toast({
                    status: "error",
                    variant: "left-accent",
                    position: "bottom-left",
                    title: `${fileRejection.file.name} ${translations.uploadStep.dropzone.errorToastDescription}`,
                    description: fileRejection.errors[0].message,
                    isClosable: true,
                });
            });
        },
        onDropAccepted: ([file]) => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            const arrayBuffer = yield readFileAsync(file);
            const workbook = XLSX.read(arrayBuffer, {
                cellDates: true,
                dateNF: dateFormat,
                raw: parseRaw,
                dense: true,
                codepage: 65001,
            });
            setLoading(false);
            onContinue(workbook, file);
        }),
    });
    return (_jsxs(Box, Object.assign({}, getRootProps(), { height: "263px", borderRadius: "4px", border: "2px dashed  #2BA530", background: "rgba(43, 165, 48, 0.05)", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", flex: 1 }, { children: [_jsx("input", Object.assign({}, getInputProps(), { "data-testid": "rsi-dropzone" })), isDragActive ? (_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "semibold" }, { children: translations.uploadStep.dropzone.activeDropzoneTitle }))) : loading || isLoading ? (_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "semibold" }, { children: translations.uploadStep.dropzone.loadingTitle }))) : (_jsxs(Stack, Object.assign({ spacing: "4" }, { children: [_jsx(Text, Object.assign({ fontSize: "lg", fontWeight: "semibold" }, { children: translations.uploadStep.dropzone.title })), _jsx(Button, Object.assign({ size: "lg", onClick: open }, { children: translations.uploadStep.dropzone.buttonTitle }))] })))] })));
};
