import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider, DrawerBody, DrawerFooter, FormControl, FormErrorMessage, Select, Stack, } from "@chakra-ui/react";
import { Field, Form } from "formik";
import { personalizationOptions } from "../../../common/helpers/personalization-options";
import imageUploadHandler from "../../campaigns/helpers/image-upload-handler";
import InputField from "../../shared/components/form/input-field";
import MailEditor from "../../shared/components/mail-editor";
const TemplateCreatorFormContent = ({ isSubmitting, isValid, submitCount, onClose, setFieldValue, values, }) => {
    return (_jsxs(Form, { children: [_jsxs(DrawerBody, Object.assign({ maxH: "calc(100vh - 167px)" }, { children: [_jsxs(Stack, Object.assign({ direction: "row", width: "full", spacing: "1", alignItems: "end" }, { children: [_jsx(InputField, { placeholder: "Please enter subject", label: "Subject", type: "text", name: "subject", width: "70%" }), _jsxs(Select, Object.assign({ mb: "3", w: "220px", rounded: "base", value: "", onChange: e => {
                                    setFieldValue("subject", values.subject + e.target.value);
                                } }, { children: [_jsx("option", Object.assign({ value: "" }, { children: "Personalization field" }), "-1"), personalizationOptions.map((o, index) => (_jsx("option", Object.assign({ value: o.content }, { children: o.title }), index)))] }))] })), _jsx(Divider, {}), _jsx(Box, Object.assign({ px: "3", py: "6" }, { children: _jsx(Field, Object.assign({ name: "content" }, { children: ({ form }) => (_jsxs(FormControl, Object.assign({ position: "relative", isInvalid: form.errors.content && form.touched.content ? true : false }, { children: [_jsx(Box, Object.assign({ border: form.errors.content && form.touched.content
                                            ? "1px solid #E53E3E"
                                            : "none" }, { children: _jsx(MailEditor, { content: values.content, setFieldValue: setFieldValue, imageUploadHandler: imageUploadHandler }) })), _jsx(FormErrorMessage, { children: form.errors.content ? form.errors.content.toString() : "" })] }))) })) }))] })), _jsxs(Box, Object.assign({ position: "absolute", bottom: "0", width: "100%" }, { children: [_jsx(Divider, {}), _jsxs(DrawerFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", mr: 3, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "submit", isLoading: isSubmitting, disabled: submitCount > 0 && !isValid, colorScheme: "green" }, { children: "Save template" }))] })] }))] }));
};
export default TemplateCreatorFormContent;
