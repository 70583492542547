import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal, ModalContent, ModalOverlay, useDisclosure, } from "@chakra-ui/react";
import { IntegrationProviders } from "@packages/apollo-hooks";
import IntegrationButton from "../integration-button";
import EnableSesIntegrationForm from "./ses-integration-form";
const SesIntegrationModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(IntegrationButton, { type: IntegrationProviders.Ses, onClick: onOpen }), _jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, size: "md" }, { children: [_jsx(ModalOverlay, {}), _jsx(ModalContent, { children: _jsx(EnableSesIntegrationForm, { closeModal: onClose }) })] }))] }));
};
export default SesIntegrationModal;
