import { IntegrationProviders } from "@packages/apollo-hooks";
import awsLogoSrc from "../aws-logo.svg";
import gmailLogoSrc from "../google-logo.svg";
import microsoftLogoSrc from "../microsoft-logo.svg";
export const integrationSettings = [
    {
        type: IntegrationProviders.Google,
        iconSrc: gmailLogoSrc,
    },
    {
        type: IntegrationProviders.Microsoft,
        iconSrc: microsoftLogoSrc,
    },
    {
        type: IntegrationProviders.Ses,
        iconSrc: awsLogoSrc,
    },
];
