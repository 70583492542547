import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { GetCampaignStatisticsDocument, } from "@packages/apollo-hooks";
import { useParams } from "react-router-dom";
import Loader from "../../../../shared/components/loader";
import Statistics from "../../statistics";
const CampaignStatistics = () => {
    const { id } = useParams();
    const { data, loading, error } = useQuery(GetCampaignStatisticsDocument, {
        variables: {
            campaignId: id,
        },
    });
    const hasData = data && data.getCampaignDetails;
    if (loading || !hasData)
        return _jsx(Loader, {});
    if (error) {
        return _jsxs("div", { children: ["Error: ", error.message] });
    }
    return _jsx(Statistics, { statistics: data.getCampaignDetails });
};
export default CampaignStatistics;
