var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Flex, SimpleGrid, Stack, Text } from "@chakra-ui/layout";
import { Button, useToast } from "@chakra-ui/react";
import { CampaignStatuses, } from "@packages/apollo-hooks";
import { DATE_FORMAT, TIME_FORMAT } from "@packages/helpers";
import { format } from "date-fns";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { IoBusinessOutline, IoCalendarOutline, IoDownloadOutline, IoMailOutline, } from "react-icons/io5";
import { getUserToken } from "../../../../common/helpers/user-token";
import DetailWithIcon from "../../../shared/components/detail-with-icon";
import CampaignBadge from "../../campaign-badge-new";
import getProviderName from "../../helpers/get-provider-name";
import CampaignStatistics from "./campaign-statistics";
const CampaignDetails = ({ campaignDetails }) => {
    const toast = useToast();
    const token = getUserToken();
    const triggerDownload = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const params = new URLSearchParams();
            params.append("offset", new Date().getTimezoneOffset().toString());
            const response = yield fetch(`${process.env.REACT_APP_API_URL}/campaign/report/${campaignDetails.id}?${params.toString()}`, {
                method: "GET",
                headers: new Headers({
                    Authorization: `Bearer ${token === null || token === void 0 ? void 0 : token.token}`,
                }),
            });
            if (response.status === 200) {
                FileSaver.saveAs(yield response.blob(), `${campaignDetails.name}_${format(new Date(campaignDetails.endsAt), DATE_FORMAT)}.xlsx`);
            }
        }
        catch (err) {
            console.error(err);
            toast({
                title: "Something went wrong",
                description: err.message,
                status: "error",
                isClosable: false,
            });
        }
    }), [
        campaignDetails.endsAt,
        campaignDetails.id,
        campaignDetails.name,
        toast,
        token === null || token === void 0 ? void 0 : token.token,
    ]);
    return (_jsxs(Box, Object.assign({ rounded: { lg: "lg" }, bg: "white", borderWidth: "1px" }, { children: [_jsxs(Flex, Object.assign({ px: "6", py: "2", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Text, Object.assign({ as: "h3", fontWeight: "bold", fontSize: "sm" }, { children: "Campaign details" })), _jsx(CampaignBadge, { status: campaignDetails.status })] })), _jsx(Divider, {}), _jsxs(Stack, Object.assign({ direction: "column", py: "5", px: "8", spacing: "8" }, { children: [_jsxs(Flex, Object.assign({ justifyContent: "space-between" }, { children: [_jsx(DetailWithIcon, { icon: _jsx(IoMailOutline, {}), title: campaignDetails.name }), campaignDetails.status === CampaignStatuses.Completed && (_jsx(Button, Object.assign({ variant: "outline", leftIcon: _jsx(IoDownloadOutline, {}), onClick: triggerDownload }, { children: "Download Full Report" })))] })), _jsxs(SimpleGrid, Object.assign({ columns: 3 }, { children: [_jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoBusinessOutline, {}), subtitle: "Provider" }, { children: getProviderName(campaignDetails.provider) })), _jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoCalendarOutline, {}), subtitle: "Start date" }, { children: campaignDetails
                                    ? format(new Date(campaignDetails.startsAt), DATE_FORMAT) +
                                        ", " +
                                        format(new Date(campaignDetails.startsAt), TIME_FORMAT)
                                    : "" })), _jsx(DetailWithIcon, Object.assign({ icon: _jsx(IoCalendarOutline, {}), subtitle: "End date" }, { children: campaignDetails.endsAt
                                    ? format(new Date(campaignDetails.endsAt), DATE_FORMAT) +
                                        ", " +
                                        format(new Date(campaignDetails.endsAt), TIME_FORMAT)
                                    : "" }))] }))] })), campaignDetails.campaignEmails.length > 0 && _jsx(CampaignStatistics, {})] })));
};
export default CampaignDetails;
