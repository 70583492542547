import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, IconButton, } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { IoPencilSharp } from "react-icons/io5";
import EditTemplateFolderNameForm from "./edit-template-folder-name-form";
const EditTemplateFolderName = ({ templateFolderId, templateFolderName, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const cancelRef = useRef();
    const onClose = () => setIsOpen(false);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { size: "xs", variant: "outline_color", icon: _jsx(IoPencilSharp, {}), "aria-label": "Edit folder name", onClick: () => setIsOpen(true) }), _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Edit Template Folder" })), _jsx(EditTemplateFolderNameForm, { onClose: onClose, templateFolderId: templateFolderId, templateFolderName: templateFolderName, cancelRef: cancelRef })] }) }) }))] }));
};
export default EditTemplateFolderName;
