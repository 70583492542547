import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@chakra-ui/react";
import React from "react";
import { IoDownloadOutline } from "react-icons/io5";
import useIsAdmin from "../../../common/helpers/use-is-admin";
import PageHeaderButtonsWrapper from "../../shared/components/page/page-header/page-header-buttons-wrapper";
import { SpreadSheetImport } from "../../spreadsheet-import";
import CreateNewContactList from "./create-new-contact-list";
// eslint-disable-next-line
const EMAIL_REGEX = /^[\w-\.]+[+\w]*@([\w-]+\.)+[\w-]{2,63}$/g;
const fields = [
    {
        label: "First Name *",
        key: "firstName",
        alternateMatches: ["first name", "first_name", "first", "name"],
        fieldType: {
            type: "input",
        },
        example: "John",
        validations: [
            {
                rule: "required",
                errorMessage: "Name is required",
            },
        ],
    },
    {
        label: "Last Name *",
        key: "lastName",
        alternateMatches: ["last name", "last_name", "last", "name"],
        fieldType: {
            type: "input",
        },
        example: "Doe",
        validations: [
            {
                rule: "required",
                errorMessage: "Name is required",
            },
        ],
    },
    {
        label: "Email *",
        key: "email",
        alternateMatches: ["email", "e-mail", "mail", "EMAIL", "MAIL"],
        fieldType: {
            type: "input",
        },
        example: "johndoe@motar.me",
        validations: [
            {
                rule: "required",
                errorMessage: "Email is required",
            },
            {
                rule: "unique",
                errorMessage: "Email has to be unique",
            },
            {
                rule: "regex",
                errorMessage: "Invalid email",
                value: EMAIL_REGEX,
            },
        ],
    },
    {
        label: "Company *",
        key: "leadCompany",
        alternateMatches: ["company", "lead_company"],
        fieldType: {
            type: "input",
        },
        example: "motar.me",
        validations: [
            {
                rule: "required",
                errorMessage: "Company is required",
            },
        ],
    },
    {
        label: "Job title",
        key: "jobTitle",
        alternateMatches: ["job title", "role"],
        fieldType: {
            type: "input",
        },
        example: "CEO",
    },
    {
        label: "Country",
        key: "country",
        alternateMatches: ["country"],
        fieldType: {
            type: "input",
        },
        example: "Ireland",
    },
];
const ContactsTopActions = ({ isSingleContact = false, contactListId, }) => {
    const isAdmin = useIsAdmin();
    const [isOpen, setIsOpen] = React.useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (_jsxs(PageHeaderButtonsWrapper, { children: [!isSingleContact && _jsx(CreateNewContactList, {}), !isAdmin && (_jsx(Button, Object.assign({ colorScheme: "green", leftIcon: _jsx(IoDownloadOutline, {}), fontSize: "sm", onClick: handleOpen }, { children: "Import Contacts" }))), _jsx(SpreadSheetImport, { contactListId: contactListId, isOpen: isOpen, onClose: handleClose, onSubmit: data => {
                    console.log({ values: data.validData, invalid: data.invalidData });
                }, fields: fields })] }));
};
export default ContactsTopActions;
