import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, } from "@chakra-ui/modal";
import React from "react";
import { IoCopyOutline } from "react-icons/io5";
import CopyContactsFromListForm from "./copy-contacts-from-list-form";
const CopyContactsFromList = ({ contactListId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ colorScheme: "green", variant: "outline", leftIcon: _jsx(IoCopyOutline, {}), fontSize: "sm", onClick: onOpen }, { children: "Copy from List" })), _jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, { children: [_jsx(ModalHeader, { children: "Copy Contacts from List" }), _jsx(ModalCloseButton, {}), _jsx(CopyContactsFromListForm, { contactListId: contactListId, onClose: onClose })] })] }))] }));
};
export default CopyContactsFromList;
