import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Drawer, DrawerContent, DrawerHeader, DrawerOverlay, IconButton, useDisclosure, } from "@chakra-ui/react";
import React from "react";
import { IoAddOutline, IoPencilSharp } from "react-icons/io5";
import CustomDrawerCloseButton from "../../../../shared/components/custom-drawer-close-button";
import EmailCreatorForm from "./email-creator-form";
import EmailEditorForm from "./email-editor-form";
const EmailCreator = ({ campaignId, isEditMode = false, emailData, handleSaveAsDraft, emailsDates, }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (_jsxs(_Fragment, { children: [!isEditMode ? (_jsx(Button, Object.assign({ variant: "outline", leftIcon: _jsx(IoAddOutline, {}), onClick: () => {
                    if (handleSaveAsDraft) {
                        handleSaveAsDraft();
                    }
                    onOpen();
                } }, { children: "Create E-mail" }))) : (_jsx(IconButton, { fontSize: "md", variant: "outline_color", size: "sm", "aria-label": "Edit", icon: _jsx(IoPencilSharp, {}), onClick: () => {
                    if (handleSaveAsDraft) {
                        handleSaveAsDraft();
                    }
                    onOpen();
                } })), _jsx(Drawer, Object.assign({ isOpen: isOpen, onClose: onClose, size: "own", trapFocus: false }, { children: _jsx(DrawerOverlay, { children: _jsxs(DrawerContent, Object.assign({ maxWidth: "calc(100vw - 320px)" }, { children: [_jsx(CustomDrawerCloseButton, {}), _jsx(DrawerHeader, { children: "Create E-mail " }), _jsx(Divider, {}), campaignId && !isEditMode && (_jsx(EmailCreatorForm, { onClose: onClose, campaignId: campaignId, emailsDates: emailsDates })), isEditMode && emailData && campaignId && (_jsx(EmailEditorForm, { onClose: onClose, emailData: emailData, campaignId: campaignId, emailsDates: emailsDates }))] })) }) }))] }));
};
export default EmailCreator;
