const select = {
    parts: ["field"],
    defaultProps: {
        size: "md",
    },
    variants: {
        outline: {
            field: {
                fontSize: "sm",
                color: "gray.600",
                _hover: {
                    borderColor: "cyan.200",
                },
                _invalid: {
                    boxShadow: "none",
                },
                _focus: {
                    borderColor: "cyan.400",
                },
            },
        },
    },
};
export default select;
