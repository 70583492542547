var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { eachMonthOfInterval, endOfMonth, endOfYear, format, getMonth, isAfter, isBefore, isSameMonth, startOfMonth, startOfYear, } from "date-fns";
import React, { useCallback, useMemo, } from "react";
import { Month } from "./months.components";
const Months = ({ todayDate, displayDate, dispatch, }) => {
    const currentCalendarMonthsInfo = useMemo(() => {
        return eachMonthOfInterval({
            start: startOfMonth(startOfYear(displayDate)),
            end: endOfMonth(endOfYear(displayDate)),
        }).map(monthStartDate => ({
            value: monthStartDate,
            label: format(monthStartDate, "LLL"),
            isPastMonth: isBefore(monthStartDate, startOfMonth(todayDate)),
            isCurrentMonth: isSameMonth(monthStartDate, todayDate),
            isFutureMonth: isAfter(monthStartDate, endOfMonth(todayDate)),
        }));
    }, [displayDate, todayDate]);
    const onMonthClick = useCallback((value) => () => {
        dispatch({ type: "setDisplayMonth", month: getMonth(value) });
    }, [dispatch]);
    const months = useMemo(() => {
        return currentCalendarMonthsInfo.map((_a) => {
            var { value, label } = _a, monthInfo = __rest(_a, ["value", "label"]);
            return (_jsx(Month, Object.assign({ onClick: onMonthClick(value), monthInfo: monthInfo, "data-cy-value": format(value, "y-M") }, { children: label }), value.toISOString()));
        });
    }, [currentCalendarMonthsInfo, onMonthClick]);
    return _jsx(_Fragment, { children: months });
};
export default Months;
