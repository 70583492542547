import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { IoListOutline } from "react-icons/io5";
import useContactListsOptions from "../../../../contacts/single-contact-list/add-contact/use-contact-lists-options";
import SelectField from "../../../../shared/components/form/select-field";
const SelectContactList = ({ handleCancel }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();
    const { contactListsOptions, loading } = useContactListsOptions(true);
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "outline", leftIcon: _jsx(IoListOutline, {}), onClick: () => setIsOpen(true) }, { children: "Select Contact List" })), _jsx(AlertDialog, Object.assign({ isOpen: isOpen, leastDestructiveRef: cancelRef, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, Object.assign({ fontSize: "lg", fontWeight: "bold" }, { children: "Add to List" })), _jsx(AlertDialogBody, { children: !loading && (_jsx(SelectField, { hasPadding: false, name: "contactListId", options: contactListsOptions })) }), _jsxs(AlertDialogFooter, { children: [_jsx(Button, Object.assign({ variant: "outline", mr: 3, onClick: () => {
                                            handleCancel();
                                            onClose();
                                        } }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "submit", onClick: onClose }, { children: "Ok" }))] })] }) }) }))] }));
};
export default SelectContactList;
