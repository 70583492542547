var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { StopCampaignDocument } from "@packages/apollo-hooks";
import { useCallback } from "react";
const useStopCampaign = (campaignId) => {
    const toast = useToast();
    const [stopCampaign, { loading }] = useMutation(StopCampaignDocument, {
        fetchPolicy: "no-cache",
        refetchQueries: ["getCampaignDetails"],
    });
    const handleStopCampaign = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data: stopData } = yield stopCampaign({
                variables: {
                    input: {
                        campaignId,
                    },
                },
            });
            switch (stopData === null || stopData === void 0 ? void 0 : stopData.stopCampaign.__typename) {
                case "StopCampaignSuccessfulResponse":
                    toast({
                        title: "Campaign stopped",
                        status: "success",
                        isClosable: false,
                    });
                    return;
                case "CampaignNotExistResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign doesn't exist",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                case "CampaignNotActiveResponse":
                    toast({
                        title: "Something went wrong",
                        description: "Campaign is not active",
                        status: "error",
                        isClosable: false,
                    });
                    break;
                default:
                    toast({
                        title: "Something went wrong",
                        status: "error",
                        isClosable: false,
                    });
                    return;
            }
        }
        catch (e) {
            toast({
                title: "Something went wrong.",
                status: "error",
                isClosable: false,
            });
        }
    }), [campaignId, stopCampaign, toast]);
    return { handleStopCampaign, stopCampaignLoading: loading };
};
export default useStopCampaign;
