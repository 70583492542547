var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Text, Icon } from "@chakra-ui/react";
import React from "react";
import { IoChevronBackOutline } from "react-icons/io5";
const FolderBreadcrumb = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsxs(HStack, Object.assign({ mb: "2" }, { children: [_jsxs(HStack, Object.assign({ cursor: "pointer" }, props, { children: [_jsx(Icon, { as: IoChevronBackOutline, boxSize: "14px" }), _jsx(Text, Object.assign({ fontSize: "sm" }, { children: "Folders /" }))] })), _jsx(Text, Object.assign({ fontSize: "sm", fontWeight: "bold" }, { children: children }))] })));
};
export default FolderBreadcrumb;
