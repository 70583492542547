import { useEffect, useState } from "react";
import AppEventTarget, { appEventTypes } from "../events/app-event-target";
import { getUserToken } from "../helpers/user-token";
const useTokenChangeHandler = () => {
    const [token, setToken] = useState(getUserToken());
    useEffect(() => {
        const handler = ({ detail: { userToken } }) => {
            setToken(userToken);
        };
        AppEventTarget.addEventListener(appEventTypes.TOKEN_CHANGE, {
            handleEvent: handler,
        });
        return () => {
            AppEventTarget.removeEventListener(appEventTypes.TOKEN_CHANGE, {
                handleEvent: handler,
            });
        };
    }, []);
    return [token];
};
export default useTokenChangeHandler;
