var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, Input, InputGroup, InputRightElement, } from "@chakra-ui/react";
import { format, startOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState, } from "react";
import { IoCloseOutline } from "react-icons/io5";
import calendarReducer from "../shared/calendar/calendar-reducer";
import { validateInitialValue } from "../shared/helpers";
import { ReactComponent as CalendarIcon } from "../shared/icons/calendar.svg";
import { StyledCalendar, Wrapper, } from "./date-picker.components";
const DatePicker = (_a) => {
    var { formatString, value, minDate, onChange, onBlur, showClear, placement = "bottom-right" } = _a, inputProps = __rest(_a, ["formatString", "value", "minDate", "onChange", "onBlur", "showClear", "placement"]);
    const wrapperRef = useRef(null);
    const [opened, setOpened] = useState(false);
    const [state, dispatch] = useReducer(calendarReducer(false), {
        displayDate: validateInitialValue(value),
        displayLevel: "days",
        selection: undefined,
    });
    const selectionRef = useRef(state.selection);
    useEffect(() => {
        if (selectionRef.current === state.selection) {
            return;
        }
        selectionRef.current = state.selection;
        onChange === null || onChange === void 0 ? void 0 : onChange(state.selection);
    }, [state.selection, onChange]);
    useEffect(() => {
        dispatch({ type: "setSelection", selection: value });
    }, [value]);
    useEffect(() => {
        const newValue = minDate ? startOfDay(minDate) : undefined;
        dispatch({ type: "setMinDate", minDate: newValue });
    }, [minDate]);
    const showPicker = useCallback(() => {
        setOpened(true);
    }, []);
    const hidePicker = useCallback(() => {
        setOpened(false);
        dispatch({ type: "resetView" });
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    }, [dispatch, onBlur]);
    const clear = useCallback(() => {
        dispatch({ type: "setSelection", selection: undefined });
    }, []);
    const globalEventHandler = useCallback((event) => {
        var _a;
        if ((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
            return;
        }
        hidePicker();
    }, [hidePicker]);
    useEffect(() => {
        if (!opened) {
            return;
        }
        document.addEventListener("click", globalEventHandler, { capture: true });
        // document.addEventListener("focusin", globalEventHandler, { capture: true });
        return () => {
            document.removeEventListener("click", globalEventHandler, {
                capture: true,
            });
            // document.removeEventListener("focusin", globalEventHandler, {
            //   capture: true,
            // });
        };
    }, [opened, globalEventHandler]);
    const formattedValue = useMemo(() => {
        if (!(state.selection instanceof Date)) {
            return "";
        }
        if (formatString) {
            return format(state.selection, formatString);
        }
        else {
            return state.selection.toLocaleDateString();
        }
    }, [state.selection, formatString]);
    return (_jsxs(Wrapper, Object.assign({ ref: wrapperRef }, { children: [_jsxs(InputGroup, { children: [_jsx(Input, Object.assign({}, inputProps, { onFocus: showPicker, readOnly: true, value: formattedValue })), _jsx(InputRightElement, Object.assign({ onClick: () => {
                            if (!inputProps.isDisabled) {
                                showPicker();
                            }
                        }, mt: inputProps.mt ? inputProps.mt : 0, justifyContent: "flex-end", height: "100%", padding: "8px", color: "gray.500" }, { children: _jsxs(HStack, Object.assign({ spacing: "1" }, { children: [showClear && value && (_jsx(Box, Object.assign({ color: "red.500", onClick: clear }, { children: _jsx(IoCloseOutline, { fontSize: "24px" }) }))), _jsx(CalendarIcon, {})] })) }))] }), opened && (_jsx(StyledCalendar, { state: state, dispatch: dispatch, placement: placement }))] })));
};
export default DatePicker;
