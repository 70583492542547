import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useLottie } from "lottie-react";
import { default as loaderAnimationData } from "./loader-animation-data.json";
const props = {
    options: {
        animationData: loaderAnimationData,
        loop: true,
        autoplay: true,
    },
};
const Loader = ({ height = 250 }) => {
    const { View } = useLottie(props.options, { height });
    return _jsx(_Fragment, { children: View });
};
export default Loader;
