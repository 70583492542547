import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Image, Stack, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import LeftSide from "../../app/public-layout/left-side";
import routesPaths from "../../routing/route-paths";
import RegisterForm from "./register-form";
import verifyAccountImage from "./verify-account.svg";
const Register = () => {
    const [submitted, setSubmitted] = useState(false);
    const setFormSubmitted = useCallback(() => {
        setSubmitted(true);
    }, []);
    return (_jsx(LeftSide, { children: !submitted ? (_jsxs(_Fragment, { children: [_jsxs(Text, Object.assign({ mx: "3", my: "5", fontSize: "sm", color: "gray.600" }, { children: ["Create campaigns and send e-mails as a Company using", " ", _jsx("b", { children: "Motar.me account." })] })), _jsx(RegisterForm, { setFormSubmitted: setFormSubmitted })] })) : (_jsxs(Stack, Object.assign({ spacing: "6", alignItems: "center", textAlign: "center" }, { children: [_jsx(Image, { src: verifyAccountImage, height: "128px" }), _jsx(Text, Object.assign({ fontSize: "28px", fontWeight: "bold", color: "gray.600" }, { children: "Verify your account" })), _jsx(Text, Object.assign({ fontSize: "md", color: "gray.600" }, { children: "We've sent a confirmation message, please click the link in the email to verify your account." })), _jsx(Button, Object.assign({ as: "a", href: routesPaths.LOGIN, variant: "outline" }, { children: "Login" }))] }))) }));
};
export default Register;
