import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, GridItem, Icon, Tab, TabList, TabPanels, Tabs, } from "@chakra-ui/react";
import { IoCard, IoLinkOutline, IoPersonCircleOutline } from "react-icons/io5";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import routesPaths from "../routing/route-paths";
import useUserSubscription from "../shared/hooks/use-user-subscription";
import AccountPanel from "./account-panel";
import IntegrationsPanel from "./integrations-panel";
import BillingDataPanel from "./payments-panel/billing-data-panel";
import InvoicesPanel from "./payments-panel/invoices-panel/invoices-panel";
import PaymentsPanel from "./payments-panel/payments-panel";
import SubscriptionPanel from "./payments-panel/subscription-panel";
export const PAYMENT_TAB_NAME = "payments";
export const getPaymentTabNames = (hasDirectPayment) => {
    if (hasDirectPayment) {
        return ["payments-subscription"];
    }
    else {
        return [
            "payments-billing-data",
            "payments-subscription",
            "payments-invoices",
        ];
    }
};
export const getTabNames = (hasDirectPayment) => [
    "integrations",
    "account",
    PAYMENT_TAB_NAME,
    ...getPaymentTabNames(hasDirectPayment),
];
const SettingsContent = () => {
    const { tab } = useParams();
    const navigate = useNavigate();
    const { hasDirectPayment } = useUserSubscription();
    const navigateToTab = (index) => {
        navigate(generatePath(routesPaths.SETTINGS_BY_TAB, {
            tab: getTabNames(hasDirectPayment)[index],
        }));
    };
    const currentIndex = getTabNames(hasDirectPayment).findIndex(x => x === tab);
    return (_jsx(_Fragment, { children: _jsx(Tabs, Object.assign({ index: currentIndex < 0 ? 0 : currentIndex, onChange: index => navigateToTab(index), variant: "button" }, { children: _jsxs(Grid, Object.assign({ templateColumns: "256px 1fr", borderRadius: "lg", borderWidth: "1px", borderColor: "gray.200", height: "calc(100vh - 163px)" }, { children: [_jsx(GridItem, Object.assign({ borderLeftRadius: "lg", bg: "gray.100", p: "4" }, { children: _jsxs(TabList, { children: [_jsxs(Tab, { children: [_jsx(Icon, { as: IoLinkOutline, w: 4, h: 4 }), "Integrations"] }), _jsxs(Tab, Object.assign({ mt: "1" }, { children: [_jsx(Icon, { as: IoPersonCircleOutline, w: 4, h: 4 }), "Account"] })), _jsxs(Tab, Object.assign({ mt: "1" }, { children: [_jsx(Icon, { as: IoCard, w: 4, h: 4 }), "Payments"] })), !hasDirectPayment && (_jsx(Tab, Object.assign({ ml: "10", mt: "1" }, { children: "Billing data" }))), !hasDirectPayment && (_jsx(Tab, Object.assign({ ml: "10", mt: "1" }, { children: "Subscription" }))), _jsx(Tab, Object.assign({ ml: "10", mt: "1", as: "button" }, { children: "Invoices" }))] }) })), _jsx(GridItem, Object.assign({ borderRightRadius: "lg", bg: "white", p: "4" }, { children: _jsxs(TabPanels, Object.assign({ width: "100%" }, { children: [_jsx(IntegrationsPanel, {}), _jsx(AccountPanel, {}), _jsx(PaymentsPanel, {}), !hasDirectPayment && _jsx(BillingDataPanel, {}), !hasDirectPayment && _jsx(SubscriptionPanel, {}), _jsx(InvoicesPanel, {})] })) }))] })) })) }));
};
export default SettingsContent;
