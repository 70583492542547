export const generateColumns = (fields) => {
    return [
        ...fields.map(field => {
            return {
                key: field.key,
                name: field.label,
                minWidth: 150,
                resizable: true,
                editable: false,
            };
        }),
    ];
};
