import styled from "@emotion/styled";
const ResponsiveSquare = styled.div `
  position: relative;
  :before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;
export default ResponsiveSquare;
