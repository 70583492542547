const data = {
    v: "5.7.4",
    fr: 30,
    ip: 0,
    op: 120,
    w: 1920,
    h: 1080,
    nm: "Comp 1",
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "Shape Layer 4",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1008.547, 583.323, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: "n",
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [-415.047, -298.323],
                                [-48.547, 24.677],
                                [293.703, -276.073],
                                [293.203, 190.677],
                                [-390.547, 191.177],
                                [-389.547, -276.573],
                                [326.453, -278.323],
                            ],
                            c: false,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    f: { a: 0, k: [0, 0], ix: 2 },
                    nm: "Mask 1",
                },
            ],
            ef: [
                {
                    ty: 22,
                    nm: "Stroke",
                    np: 13,
                    mn: "ADBE Stroke",
                    ix: 1,
                    en: 1,
                    ef: [
                        {
                            ty: 10,
                            nm: "Path",
                            mn: "ADBE Stroke-0001",
                            ix: 1,
                            v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                            ty: 7,
                            nm: "All Masks",
                            mn: "ADBE Stroke-0010",
                            ix: 2,
                            v: { a: 0, k: 1, ix: 2 },
                        },
                        {
                            ty: 7,
                            nm: "Stroke Sequentially",
                            mn: "ADBE Stroke-0011",
                            ix: 3,
                            v: { a: 0, k: 1, ix: 3 },
                        },
                        {
                            ty: 2,
                            nm: "Color",
                            mn: "ADBE Stroke-0002",
                            ix: 4,
                            v: { a: 0, k: [0, 1, 0.275857299566, 1], ix: 4 },
                        },
                        {
                            ty: 0,
                            nm: "Brush Size",
                            mn: "ADBE Stroke-0003",
                            ix: 5,
                            v: { a: 0, k: 7, ix: 5 },
                        },
                        {
                            ty: 0,
                            nm: "Brush Hardness",
                            mn: "ADBE Stroke-0004",
                            ix: 6,
                            v: { a: 0, k: 0.565, ix: 6 },
                        },
                        {
                            ty: 0,
                            nm: "Opacity",
                            mn: "ADBE Stroke-0005",
                            ix: 7,
                            v: { a: 0, k: 1, ix: 7 },
                        },
                        {
                            ty: 0,
                            nm: "Start",
                            mn: "ADBE Stroke-0008",
                            ix: 8,
                            v: { a: 0, k: 0, ix: 8 },
                        },
                        {
                            ty: 0,
                            nm: "End",
                            mn: "ADBE Stroke-0009",
                            ix: 9,
                            v: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 80,
                                        s: [0],
                                    },
                                    { t: 119, s: [100] },
                                ],
                                ix: 9,
                            },
                        },
                        {
                            ty: 7,
                            nm: "Spacing",
                            mn: "ADBE Stroke-0006",
                            ix: 10,
                            v: { a: 0, k: 1, ix: 10 },
                        },
                        {
                            ty: 7,
                            nm: "Paint Style",
                            mn: "ADBE Stroke-0007",
                            ix: 11,
                            v: { a: 0, k: 3, ix: 11 },
                        },
                    ],
                },
            ],
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-389.365, -275.823],
                                        [-48.365, 24.677],
                                        [293.385, -276.073],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.77410846785, 0.965352974686, 0.996823538986, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Shape 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [683.271, 469.353], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.77410846785, 0.965352974686, 0.996823538986, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 11, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [-48.365, -43.323], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Rectangle 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 0,
            op: 120,
            st: 0,
            cp: true,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: "Shape Layer 3",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1008.547, 583.323, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: "n",
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [329.453, -307.823],
                                [-48.047, 24.677],
                                [-391.047, -277.823],
                                [-390.797, 192.427],
                                [293.453, 191.177],
                                [292.703, -277.573],
                                [-408.047, -277.323],
                            ],
                            c: false,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    f: { a: 0, k: [0, 0], ix: 2 },
                    nm: "Mask 1",
                },
            ],
            ef: [
                {
                    ty: 22,
                    nm: "Stroke",
                    np: 13,
                    mn: "ADBE Stroke",
                    ix: 1,
                    en: 1,
                    ef: [
                        {
                            ty: 10,
                            nm: "Path",
                            mn: "ADBE Stroke-0001",
                            ix: 1,
                            v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                            ty: 7,
                            nm: "All Masks",
                            mn: "ADBE Stroke-0010",
                            ix: 2,
                            v: { a: 0, k: 1, ix: 2 },
                        },
                        {
                            ty: 7,
                            nm: "Stroke Sequentially",
                            mn: "ADBE Stroke-0011",
                            ix: 3,
                            v: { a: 0, k: 1, ix: 3 },
                        },
                        {
                            ty: 2,
                            nm: "Color",
                            mn: "ADBE Stroke-0002",
                            ix: 4,
                            v: { a: 0, k: [0, 1, 0.275857299566, 1], ix: 4 },
                        },
                        {
                            ty: 0,
                            nm: "Brush Size",
                            mn: "ADBE Stroke-0003",
                            ix: 5,
                            v: { a: 0, k: 6, ix: 5 },
                        },
                        {
                            ty: 0,
                            nm: "Brush Hardness",
                            mn: "ADBE Stroke-0004",
                            ix: 6,
                            v: { a: 0, k: 0.571, ix: 6 },
                        },
                        {
                            ty: 0,
                            nm: "Opacity",
                            mn: "ADBE Stroke-0005",
                            ix: 7,
                            v: { a: 0, k: 1, ix: 7 },
                        },
                        {
                            ty: 0,
                            nm: "Start",
                            mn: "ADBE Stroke-0008",
                            ix: 8,
                            v: { a: 0, k: 0, ix: 8 },
                        },
                        {
                            ty: 0,
                            nm: "End",
                            mn: "ADBE Stroke-0009",
                            ix: 9,
                            v: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 40,
                                        s: [0],
                                    },
                                    { t: 80, s: [100] },
                                ],
                                ix: 9,
                            },
                        },
                        {
                            ty: 7,
                            nm: "Spacing",
                            mn: "ADBE Stroke-0006",
                            ix: 10,
                            v: { a: 0, k: 1, ix: 10 },
                        },
                        {
                            ty: 7,
                            nm: "Paint Style",
                            mn: "ADBE Stroke-0007",
                            ix: 11,
                            v: { a: 0, k: 3, ix: 11 },
                        },
                    ],
                },
            ],
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-391.865, -277.323],
                                        [-48.365, 24.677],
                                        [291.635, -273.823],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.611764729023, 0.847058832645, 0.309803932905, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Shape 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [683.271, 469.353], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.611764729023, 0.847058832645, 0.309803932905, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [-48.365, -43.323], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Rectangle 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 0,
            op: 120,
            st: 0,
            cp: true,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: "Shape Layer 1",
            sr: 1,
            ks: {
                o: { a: 0, k: 60, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1008.547, 583.323, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: "n",
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [-427.547, -309.823],
                                [-48.547, 25.677],
                                [293.453, -275.323],
                                [293.453, 192.177],
                                [-390.547, 192.427],
                                [-389.547, -278.323],
                                [300.953, -278.823],
                            ],
                            c: false,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    f: { a: 0, k: [0, 0], ix: 2 },
                    nm: "Mask 1",
                },
            ],
            ef: [
                {
                    ty: 22,
                    nm: "Stroke",
                    np: 13,
                    mn: "ADBE Stroke",
                    ix: 1,
                    en: 1,
                    ef: [
                        {
                            ty: 10,
                            nm: "Path",
                            mn: "ADBE Stroke-0001",
                            ix: 1,
                            v: { a: 0, k: 0, ix: 1 },
                        },
                        {
                            ty: 7,
                            nm: "All Masks",
                            mn: "ADBE Stroke-0010",
                            ix: 2,
                            v: { a: 0, k: 1, ix: 2 },
                        },
                        {
                            ty: 7,
                            nm: "Stroke Sequentially",
                            mn: "ADBE Stroke-0011",
                            ix: 3,
                            v: { a: 0, k: 1, ix: 3 },
                        },
                        {
                            ty: 2,
                            nm: "Color",
                            mn: "ADBE Stroke-0002",
                            ix: 4,
                            v: { a: 0, k: [0, 1, 0.275857299566, 1], ix: 4 },
                        },
                        {
                            ty: 0,
                            nm: "Brush Size",
                            mn: "ADBE Stroke-0003",
                            ix: 5,
                            v: { a: 0, k: 6, ix: 5 },
                        },
                        {
                            ty: 0,
                            nm: "Brush Hardness",
                            mn: "ADBE Stroke-0004",
                            ix: 6,
                            v: { a: 0, k: 0.569, ix: 6 },
                        },
                        {
                            ty: 0,
                            nm: "Opacity",
                            mn: "ADBE Stroke-0005",
                            ix: 7,
                            v: { a: 0, k: 1, ix: 7 },
                        },
                        {
                            ty: 0,
                            nm: "Start",
                            mn: "ADBE Stroke-0008",
                            ix: 8,
                            v: { a: 0, k: 0, ix: 8 },
                        },
                        {
                            ty: 0,
                            nm: "End",
                            mn: "ADBE Stroke-0009",
                            ix: 9,
                            v: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 0,
                                        s: [0],
                                    },
                                    { t: 40, s: [100] },
                                ],
                                ix: 9,
                            },
                        },
                        {
                            ty: 7,
                            nm: "Spacing",
                            mn: "ADBE Stroke-0006",
                            ix: 10,
                            v: { a: 0, k: 1, ix: 10 },
                        },
                        {
                            ty: 7,
                            nm: "Paint Style",
                            mn: "ADBE Stroke-0007",
                            ix: 11,
                            v: { a: 0, k: 3, ix: 11 },
                        },
                    ],
                },
            ],
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-389.865, -276.323],
                                        [-48.365, 24.677],
                                        [291.635, -274.323],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.101960785687, 0.551521718502, 0.831372559071, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Shape 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [683.271, 469.353], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.101960785687, 0.552941203117, 0.831372559071, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [-48.365, -43.323], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Rectangle 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 0,
            op: 120,
            st: 0,
            cp: true,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: "Shape Layer 2",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [960, 540, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-340.5, 243.997],
                                        [-1, -27.649],
                                        [339.5, 245.156],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.772549019608, 0.964705882353, 0.996078431373, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 1,
                            lj: 2,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "fl",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [-0.434, 37.941], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 109.629], ix: 3 },
                            r: { a: 0, k: -180, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Shape 2",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-340, 231],
                                        [0, -24],
                                        [341, 234],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.772549019608, 0.964705882353, 0.996078431373, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Shape 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ty: "rc",
                            d: 1,
                            s: { a: 0, k: [683.271, 469.353], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            r: { a: 0, k: 0, ix: 4 },
                            nm: "Rectangle Path 1",
                            mn: "ADBE Vector Shape - Rect",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: {
                                a: 0,
                                k: [0.772549019608, 0.964705882353, 0.996078431373, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "fl",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0.191, 0.335], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100.145, 99.841], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Rectangle 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 0,
            op: 120,
            st: 0,
            cp: true,
            bm: 0,
        },
    ],
    markers: [],
};
export default data;
