import AppEventTarget from "../events/app-event-target";
import TokenChangeEvent from "../events/token-change-event";
import { localStorageKeys } from "./local-storage-keys";
export const userTokenFromResponse = (response) => {
    return {
        expiresAt: new Date(response.expiresAt),
        token: response.token,
    };
};
export const setUserToken = (userToken) => {
    localStorage.setItem(localStorageKeys.USER_TOKEN, JSON.stringify(userToken));
    AppEventTarget.dispatchEvent(new TokenChangeEvent(userToken));
};
export const deleteUserToken = () => {
    setUserToken(null);
};
export const getUserToken = () => {
    var _a;
    try {
        const userToken = JSON.parse((_a = localStorage.getItem(localStorageKeys.USER_TOKEN)) !== null && _a !== void 0 ? _a : "null");
        if (userToken === null) {
            return null;
        }
        ["token"].forEach(key => {
            if (!(key in userToken)) {
                throw new Error(`Missing key: ${key}`);
            }
        });
        return userToken;
    }
    catch (err) {
        console.error(`Error parsing user token ${err.message}`);
        deleteUserToken();
        return null;
    }
};
