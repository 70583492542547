const table = {
    parts: ["table", "thead", "tbody", "tr", "td", "th"],
    baseStyle: {
        table: {
            my: "8",
            fontSize: "sm",
            borderRadius: "lg",
            borderCollapse: "separate",
            borderSpacing: "0",
        },
        thead: {
            bg: "gray.200",
            tr: {
                th: {
                    color: "gray.500",
                    borderTopWidth: "1px",
                    borderColor: "gray.200",
                    p: "3",
                },
                "&:first-of-type": {
                    th: {
                        "&:first-of-type": {
                            borderTopLeftRadius: "lg",
                        },
                        "&:last-child": {
                            borderTopRightRadius: "lg",
                        },
                    },
                },
            },
        },
        tbody: {
            bg: "white",
            tr: {
                _hover: {
                    background: "gray.100",
                },
                td: {
                    p: "3",
                    color: "gray.700",
                    borderColor: "gray.200",
                    "&:first-of-type": {
                        borderLeftWidth: "1px",
                    },
                    "&:last-child": {
                        borderRightWidth: "1px",
                    },
                },
                "&:last-child": {
                    td: {
                        "&:first-of-type": {
                            borderBottomLeftRadius: "lg",
                        },
                        "&:last-child": {
                            borderBottomRightRadius: "lg",
                        },
                    },
                },
            },
        },
    },
};
export default table;
