import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import AuthedLayout from "../app/authed-layout/authed-layout";
import useAppContext from "../app/use-app-context";
import routesPaths from "./route-paths";
import ScreenLoading from "../shared/components/screen-loading";
export const AuthedRoute = ({ children }) => {
    const hasToken = !!useAppContext().userToken;
    if (!hasToken) {
        return (_jsx(Navigate, { to: {
                pathname: routesPaths.LOGIN,
            }, replace: true }));
    }
    return (_jsx(AuthedLayout, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(ScreenLoading, {}) }, { children: children })) }));
};
export default AuthedRoute;
